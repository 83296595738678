.cs {
    padding-top: var(--size-pdl);
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pdl-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pdl-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    &_title {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        margin-bottom: 3.2rem;

        @media screen and (max-width:1200px) {
            row-gap: 1.4rem;
            margin-bottom: 2.4rem;
        }

        @media screen and (max-width:860px) {
            row-gap: 1.2rem;
            margin-bottom: 2rem;
        }

        @media screen and (max-width:600px) {
            row-gap: 0.8rem;
            margin-bottom: 1.6rem;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        @media screen and (max-width:1200px) {
            row-gap: 1.4rem;
        }

        @media screen and (max-width:600px) {
            row-gap: 1.2rem;
        }
    }

    &_text {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        // @media screen and (max-width:1200px) {
        //     row-gap: 4rem;
        // }

        // @media screen and (max-width:860px) {
        //     row-gap: 3rem;
        // }

        // @media screen and (max-width:600px) {
        //     row-gap: 2rem;
        // }


        &.mona-content{
            h2,h3{
                margin: unset !important;
                @media only screen and (max-width: 1200px) {
                    font-size: 2rem !important;
                }
    
                @media only screen and (max-width: 860px) {
                    font-size: 2rem !important;
                }

                @media only screen and (max-width: 600px) {
                    font-size: 1.6rem !important;
                }
    
                // @media only screen and (max-width: 860px) {
                //     font-size: 2rem;
                // }
            }

            ul{
                margin: unset !important;
                padding-left: 2rem;
                li{
                    font-size: 1.6rem;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        
    }
}
