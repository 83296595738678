.layout {
    margin-top: -4rem;

    @media screen and (max-width: 1200px) {
        margin-top: 0;
    }

    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;

        @media screen and (max-width: 1200px) {
            row-gap: 2.4rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.8rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.4rem;
        }

        @media screen and (max-width: 460px) {
            row-gap: 1.2rem;
        }
    }

    &_title {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        @media screen and (max-width: 1200px) {
            row-gap: 1.4rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1rem;
        }

        .title-sm {
            max-width: 70rem;
            width: 100%;
        }
    }

    &_date {
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            row-gap: 0.6rem;
            align-items: start;
        }

        &.page-content {
            max-width: 64rem;
            width: 100%;

            .note-text {
                padding-right: 0;
                border-right: none;
            }
        }

        .note-text {
            padding-right: 1.6rem;
            border-right: 0.1rem solid $color-gray2;

            @media screen and (max-width: 1200px) {
                border-right: none;
                padding-right: 0;
            }
        }
    }
}

.follow {
    padding-bottom: var(--size-pdl);
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    @media screen and (max-width: 1200px) {
        row-gap: 2.4rem;
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 860px) {
        row-gap: 1.8rem;
    }

    @media screen and (max-width: 600px) {
        row-gap: 1.4rem;
        padding-bottom: var(--size-pdl-mb);
    }

    &_content {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        @media screen and (max-width: 1200px) {
            row-gap: 1.4rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.3rem;
        }
    }

    &_list {
        display: flex;
        column-gap: 1.4rem;
        flex-wrap: wrap;
        row-gap: 0.6rem;
    }

    &_item {
        flex-shrink: 0;
    }

    &_link {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        transition: all 0.3s linear;
        @media screen and (max-width: 600px) {
            height: 3.2rem;
        }

        @include hover {
            box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.5);

            // img{
            //     transform: scale(1.1);
            // }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.site_map {
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-bottom: var(--size-pdl-mb);
    }

    &--list {
        row-gap: 3.2rem;

        @media screen and (max-width: 1200px) {
            row-gap: 2.4rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.8rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.4rem;
        }

        @media screen and (max-width: 460px) {
            row-gap: 1.2rem;
        }
    }

    &--item {
        &-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 1.6rem;

            @media screen and (max-width: 1200px) {
                row-gap: 1.4rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 1.2rem;
            }

            @media screen and (max-width: 460px) {
                row-gap: 1rem;
            }

            ul {
                display: flex;
                flex-direction: column;
                row-gap: 1.6rem;
                list-style: disc;
                margin-left: 2rem;

                @media screen and (max-width: 1200px) {
                    row-gap: 1.4rem;
                }

                @media screen and (max-width: 600px) {
                    row-gap: 1.3rem;
                }

                @media screen and (max-width: 460px) {
                    row-gap: 1.2rem;
                }

                @media screen and (max-width: 360px) {
                    row-gap: 1rem;
                }
            }
        }
    }
}

.contact {
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-bottom: var(--size-pdl-mb);
    }

    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;

        @media screen and (max-width: 1200px) {
            row-gap: 2rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.6rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.2rem;
        }
    }

    &_list {
        row-gap: 2.4rem;

        @media screen and (max-width: 1200px) {
            row-gap: 2rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.6rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 460px) {
            row-gap: 0.8rem;
        }
    }

    &_item {
        @media screen and (max-width: 600px) {
            &:last-child {
                width: 100%;
            }
        }

        @media screen and (max-width: 360px) {
            width: 100% !important;
        }
    }
}
