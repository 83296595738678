.form-item {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    &-lb {
        @include fz-18;

        strong {
            color: $color-red;
        }
    }

    .select2-container {
        height: 5.9rem;
        padding: 0rem 2.4rem;
        background: transparent !important;
        border: 0.1rem solid $color-gray !important;
        border-radius: 0.6rem;

        @media screen and (max-width: 1200px) {
            padding: 0rem 2rem;
            height: 4.9rem;
          

        }

        @media screen and (max-width: 800px) {
            height: 3.9rem;
            padding: 0rem 1.4rem;
        }

        @media screen and (max-width: 600px) {
           
            padding: 0rem 1.2rem;
        }

    
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        font-size: 1.8rem;
        color: $color-second !important;
        font-weight: 400;

        @media screen and (max-width: 1200px) {
            font-size: 1.6rem !important;
        }

        @media screen and (max-width: 800px) {
            font-size: 1.4rem !important;
        }

        @media screen and (max-width: 460px) {
            
            font-size: 1.2rem !important;
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 50%;
        transform: translateY(-50%);
        right: 2.4rem;

        @media screen and (max-width: 1200px) {
            right: 2rem;
        }

        @media screen and (max-width: 800px) {
            right: 1.4rem;
        }
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow::before {
        top: 55%;

        @media screen and (max-width:800px) {
            top: 58%;
        }
    }

    // .select2-results__option{
    //     padding: 1.6rem 2.4rem !important;
    // }

    &-ip {
        width: 100%;
        height: 6rem;
        background: transparent !important;
        @include fz-18;
        display: flex;
        align-items: center;
        padding: 1.6rem 2.4rem;
        color: $color-second;
        border: 0.1rem solid $color-gray !important;
        transition: all 0.3s linear;
        border-radius: 0.6rem;
        &:focus {
            border-color: $color-pri !important;
        }

        @media screen and (max-width: 1200px) {
            padding: 1.4rem 2rem;
            font-size: 1.6rem;
            height: 5rem;
        }

        @media screen and (max-width: 800px) {
            height: 4rem;
            padding: 1.2rem 1.4rem;
            font-size: 1.4rem;
        }

        
        @media screen and (max-width: 600px) {
           
            padding: 0rem 1.2rem;
        }

        
        @media screen and (max-width: 460px) {
            
            font-size: 1.2rem;
        }

    
        &.cus {
            background: transparent !important;
            color: $color-white;
            border: none !important;
        }

        &::placeholder {
            color: $color-second;
        }
    }

    &-tx {
        width: 100%;
        min-height: 13rem;
        background: transparent !important;
        @include fz-18;
        display: flex;
        align-items: center;
        padding: 1.6rem 2.4rem;
        color: $color-second;
        border: 0.1rem solid $color-gray !important;
        transition: all 0.3s linear;
        border-radius: 0.6rem;
        &:focus {
            border-color: $color-pri !important;
        }

        @media screen and (max-width: 1200px) {
            padding: 1.4rem 2rem;
            font-size: 1.6rem;
            min-height: 10rem;
        }

        @media screen and (max-width: 800px) {
            min-height: 8rem;
            padding: 1.2rem 1.4rem;
            font-size: 1.4rem;
        }

        
        @media screen and (max-width: 600px) {
           
            padding: 1.2rem 1.2rem;
        }

        
        @media screen and (max-width: 460px) {
            
            font-size: 1.2rem;
        }

        &::placeholder {
            color: $color-second;
        }
    }
}
