.page-numbers {
  @include flex;
  align-items: center;
  justify-content: start;

  &.m-top {
    margin-top: 3rem;
  }

  .dot {
    width: 5.3rem;
    height: 0.1rem;
    background: $color-text;
  }

  > li {
    &:not(:first-child) {
      margin-left: 1.6rem;

      @include max(460px) {
        margin-left: 0.6rem;
      }
    }

    & > .page-numbers {
      line-height: 1.5;
      font-weight: 700;
      color: $color-pri;
      @include mid-flex;
      cursor: pointer;

      transition: 0.3s ease-in-out;
      width: 4rem;
      height: 4rem;
      border-radius: 0;
      border: 0.1rem solid $color-gray;
      @include fz-16;
      font-family: "Roboto";

      @include hover {
        // background-color: $color-pri;
        color: $color-pri;
        color: $color-white;
        background: $color-pri;
        border-color: $color-pri;

        i {
          color: white;
        }
      }

      @include max(600px) {
        width: 3.2rem;
        height: 3.2rem;
        font-size: 1.4rem;
      }

      // &:hover {
      //   @include min(1201px) {}
      // }

      &.current {
        background: $color-pri;
        color: $color-white;
      }

      &.dot {
        pointer-events: none;
      }

      .icon {
        font-weight: 400;
      }
    }
  }

  .page-numbers.next,
  .page-numbers.prev {
    cursor: pointer;

    i {
      @include fz-16;
      font-weight: 400;
      color: $color-pri;
    }

    > .page-numbers {
    }

    @include hover {
      color: $color-white;

      background: $color-second;
      border-color: $color-second;
    }

    .icon {
      font-weight: 900 !important;

      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
  }
}

.pagination {
  &.center {
    .page-numbers {
      justify-content: center;
    }
  }
}
