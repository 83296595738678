.video {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        padding-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        padding-bottom: var(--size-pd-mb);
    }

    &_audio {
        margin-bottom: 2.4rem;
        padding-top: calc(100% * 540 / 960);
        width: 100%;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 1200px) {
            margin-bottom: 2rem;
        }

        @media screen and (max-width: 460px) {
            padding-top: 100%;
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        @media screen and (max-width: 1200px) {
            row-gap: 1.2rem;
        }

        // @media screen and (max-width: 1200px) {
        //     row-gap: 1.6rem;
        // }

        &-wrap {
            // .mb-20 {
            //     @media screen and (max-width: 1200px) {
            //         margin-bottom: 1.2rem;
            //     }
            // }
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;
            // margin-bottom: 2.4rem;

            @media screen and (max-width: 1400px) {
                row-gap: 2rem;
                // margin-bottom: 2rem;
            }

            @media screen and (max-width: 1200px) {
                row-gap: 1.6rem;
                // margin-bottom: 1.6rem;
            }

            @media screen and (max-width: 860px) {
                row-gap: 1.4rem;
                // margin-bottom: 1.4rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 1.2rem;
                // margin-bottom: 1.2rem;
            }
        }

        .note-text {
        }
    }

    &_game {
        padding-top: var(--size-pd);
        padding-bottom: var(--size-pd);

        @media screen and (max-width: 1200px) {
            padding-top: var(--size-pd-tl);
            padding-bottom: var(--size-pd-tl);
        }

        @media screen and (max-width: 600px) {
            padding-top: var(--size-pd-mb);
            padding-bottom: var(--size-pd-mb);
        }

        &--wrap {
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;

            @media screen and (max-width: 1400px) {
                row-gap: 1.6rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 1.2rem;
            }
        }

        &--box {
            padding: 2.4rem 3.2rem;
            border: 0.1rem solid $color-gray;
            border-radius: 0.6rem;

            @media screen and (max-width: 1400px) {
                padding: 2rem 1.2rem;
            }

            @media screen and (max-width: 1200px) {
                padding: 1.2rem 1.2rem;
            }

            &-wrap {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 2rem;

                @media screen and (max-width: 1200px) {
                    column-gap: 1rem;
                }

                @media screen and (max-width: 460px) {
                    flex-direction: column;
                    row-gap: 1.2rem;
                    align-items: start;
                }
            }
        }

        &--lf {
            width: 100%;
            &-wrap {
                display: flex;
                column-gap: 3rem;
                width: 100%;

                @media screen and (max-width: 1400px) {
                    column-gap: 2rem;
                }

                @media screen and (max-width: 1200px) {
                    column-gap: 1.2rem;
                }

                @media screen and (max-width: 360px) {
                    row-gap: 1.2rem;
                    flex-direction: column;
                }

                @include hover {
                    .video_game--lf-img {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }

                    .video_game--lf-info {
                        .title-mn {
                            color: $color-five;
                        }
                    }
                }
            }

            &-info {
                display: flex;
                flex-direction: column;
                row-gap: 0.4rem;

                .title-mn {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    transition: all 0.3s linear;
                }

                .note-text {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            &-thumbs {
                max-width: 19rem;

                width: 100%;
                display: block;
                flex-shrink: 0;

                @media screen and (max-width: 1200px) {
                    max-width: 12rem;
                }

                @media screen and (max-width: 600px) {
                    max-width: 10rem;
                }

                @media screen and (max-width: 360px) {
                    max-width: none;
                }
            }

            &-img {
                padding-top: calc(100% * 119 / 190);
                width: 100%;
                display: block;
                position: relative;
                overflow: hidden;
                height: 100%;
                border-radius: 0.6rem;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s linear;
                }

                @media screen and (max-width: 460px) {
                    padding-top: 100%;
                }

                @media screen and (max-width: 360px) {
                    padding-top: calc(100% * 119 / 190);
                }
            }
        }

        &--rt {
            width: fit-content;

            @media screen and (max-width: 460px) {
                width: 100%;
            }

            .btn {
                @media screen and (max-width: 460px) {
                    width: 100%;
                }
            }
        }
    }

    &_lq {
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;

        @media screen and (max-width: 1400px) {
            row-gap: 2rem;
        }

        @media screen and (max-width: 1200px) {
            row-gap: 1.6rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.2rem;
        }

        &--list {
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;

            @media screen and (max-width: 1400px) {
                row-gap: 1.2rem;
            }

            @media screen and (max-width: 860px) {
                gap: 1.2rem;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        &--item {
            &:not(:last-child) {
                padding-bottom: 2.4rem;
                border-bottom: 0.1rem solid $color-gray2;

                @media screen and (max-width: 1400px) {
                    padding-bottom: 1.2rem;
                }

                @media screen and (max-width: 860px) {
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }

            @media screen and (max-width: 860px) {
                width: calc(50% - 0.6rem);
            }

            @media screen and (max-width: 460px) {
                width: 100%;
            }
        }

        &--link {
            display: flex;
            column-gap: 2.4rem;
            align-items: center;

            @media screen and (max-width: 1600px) {
                column-gap: 1.6rem;
            }

            @media screen and (max-width: 1400px) {
                column-gap: 1.2rem;
            }

            // @media screen and (max-width:800px) {
            //     flex-direction: column;
            //     row-gap: 1.2rem;
            // }

            @include hover {
                .video_lq--img {
                    img {
                        transform: translate(-50%, -50%) scale(1.2);
                    }
                }
            }
        }

        &--lf {
            max-width: 11.2rem;
            width: 100%;
            flex-shrink: 0;

            @media screen and (max-width: 1200px) {
                max-width: 9.2rem;
            }
        }

        &--img {
            padding-top: calc(100% * 74 / 112);
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 0.4rem;
            display: block;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s linear;
            }
        }

        &--rt {
            .note-md {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }

    &_pb {
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;

        &--list {
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;

            @media screen and (max-width: 1200px) {
                row-gap: 2rem;
            }

            @media screen and (max-width: 860px) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 1.2rem;
            }
        }

        &--item {
            @media screen and (max-width: 860px) {
                width: calc(50% - 0.6rem);
            }
        }

        &--link {
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
            height: 100%;
            justify-content: space-between;

            @media screen and (max-width: 1200px) {
                row-gap: 0.8rem;
            }

            .note-md {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            &-wrap {
                display: flex;
                column-gap: 0.8rem;
                align-items: center;

                .note-sm {
                }
            }

            &-ic {
                width: 1.6rem;
                height: 1.6rem;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.comment {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    --commentRow: 1.6rem;
    --widthImg: 4.8rem;
    --commentCol: 1.6rem;
    --commentPd: 6.4rem;

    @media screen and (max-width: 1200px) {
        --widthImg: 3.6rem;
        --commentRow: 1.2rem;
        --commentCol: 1.2rem;
        --commentPd: 4.8rem;

        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pd-tl);

        row-gap: 1.2rem;
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pd-mb);
    }

    @media screen and (max-width: 460px) {
        --widthImg: 3.2rem;
        --commentRow: 0.6rem;
        --commentCol: 0.6rem;
        --commentPd: 3.8rem;
        row-gap: 0.8rem;
    }

    &-navigation{
        display: flex;
        align-items: center;
        justify-content:space-between;
        column-gap: 1.2rem;
    }

    &-nav-prev{
        @include fz-16;

        @media screen and (max-width:320px) {
            max-width: 10rem;
        }
    }

    
    &-nav-next{
        @include fz-16;

        @media screen and (max-width:320px) {
            max-width: 10rem;
        }
    }

    &_title {
        .title-mn {
        }
        padding-bottom: 1.6rem;
        border-bottom: 0.1rem solid $color-gray2;

        @media screen and (max-width: 1200px) {
            padding-bottom: 1.2rem;
        }

        @media screen and (max-width: 460px) {
            padding-bottom: 0.8rem;
        }
    }

    &_box {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        // .comment-form {
        //     display: flex;
        //     flex-direction: column;
        //     row-gap: 1.6rem;

        //     @media screen and (max-width: 1200px) {
        //         row-gap: 1.2rem;
        //     }

        //     @media screen and (max-width: 460px) {
        //         row-gap: 0.8rem;
        //     }
        // }

        @media screen and (max-width: 1200px) {
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 460px) {
            row-gap: 0.8rem;
        }

        .btn {
            margin-left: auto;
            width: fit-content;
            min-width: 6rem;

            @media screen and (max-width: 1200px) {
                min-width: 4rem;
                height: 3rem;
            }
        }

        &--title {
            .note-text {
                a {
                    color: $color-red;
                    text-decoration: underline;
                }
            }
        }

        &--input {
            width: 100%;
            &-wrap {
                width: 100%;
                padding: 1.4rem 1.6rem;
                border-radius: 0.6rem;
                border: 0.1rem solid $color-gray4;
                box-shadow: 0.1rem 0.2rem 0.3rem 0px rgba(0, 0, 0, 0.08) inset;
                display: flex;
                flex-direction: column;
                position: relative;
                // row-gap: 2rem;

                @media screen and (max-width: 1200px) {
                    padding: 1.2rem 1.2rem;
                }

                @media screen and (max-width: 600px) {
                    padding: 0.8rem 1rem;
                }
            }

            &-ex {
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                background: $color-red;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -0.6rem;
                right: -0.2rem;
                z-index: 1;
                cursor: pointer;
                i {
                    font-size: 1rem;
                    color: $color-white;
                }

                @media screen and (max-width: 1200px) {
                    width: 1.6rem;
                    height: 1.6rem;
                    right: 0rem;
                }

                @media screen and (max-width: 600px) {
                    width: 1.2rem;
                    height: 1.2rem;
                    right: 0.3rem;
                    top: -0.3rem;
                }
            }

            &-ip {
                width: 100%;
                // transform: translateY(0.2rem);

                .emojionearea .emojionearea-button {
                    position: absolute;
                    top: 50%;
                    right: 0rem;
                    transform: translateY(-50%);
                    width: 2.4rem;
                    height: 2.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 1200px) {
                        opacity: 1;
                    }

                    @media screen and (max-width: 460px) {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                    @media screen and (max-width: 460px) {
                        top: 50%;
                    }

                    & > div {
                        width: 2.4rem;
                        height: 2.4rem;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-image: url(../assets/images/smile.svg) !important;
                        background-position: center center;

                        @media screen and (max-width: 460px) {
                            width: 1.6rem;
                            height: 1.6rem;
                        }
                    }
                }

                .emojionearea .emojionearea-editor {
                    min-height: 2.2rem;
                    padding: 0;
                    color: $color-text;
                    @include fz-16;
                    width: 95%;
                    padding-right: 2rem;
                    overflow-y: auto;
                    max-height: 6rem;
                    // display: flex;
                    // align-items: center;

                    @media screen and (max-width: 860px) {
                        width: 90%;
                        min-height: 2rem;
                    }

                    @media screen and (max-width: 460px) {
                        @include fz-12;
                        max-height: 3.2rem;
                        min-height: 1.6rem;
                    }
                }

                .emojionearea {
                    box-shadow: unset !important;
                    border: none !important;
                    position: relative;
                    z-index: 100;
                }

                .emojionearea .emojionearea-editor:empty:before {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }

                textarea {
                    font-family: var(--font-pri);
                    @include fz-16;
                    color: $color-text;
                    width: 100%;
                    line-height: 100%;
                    padding: 0;
                    min-height: 5.2rem !important;
                    height: 100%;

                    &::placeholder {
                        color: $color-gray4;
                        line-height: 100%;
                    }
                }
            }

            &-btn {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                column-gap: 0.8rem;
            }

            &-ic {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.4rem;
                height: 2.4rem;
                flex-shrink: 0;
                cursor: pointer;
                opacity: 0.6;
                transition: all 0.3s ease-in-out;

                input {
                    display: none;
                }

                @include hover {
                    opacity: 1;
                }

                @media screen and (max-width: 1200px) {
                    opacity: 1;
                }

                @media screen and (max-width: 460px) {
                    width: 1.6rem;
                    height: 1.6rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 1rem;
                position: relative;
                z-index: 10;
                width: 100%;

                @media screen and (max-width: 600px) {
                    column-gap: 0.5rem;
                }
            }

            &-bottom {
                position: relative;
                z-index: 1;
            }

            &-list {
                display: flex;
                align-items: center;
                // column-gap: 1rem;
                row-gap: 1rem;

                &.d-wrap {
                    margin-left: -0.8rem;
                    margin-right: -0.8rem;

                    @media screen and (max-width: 600px) {
                        margin-left: -0.6rem;
                        margin-right: -0.6rem;
                    }
                }

                .d-item {
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;

                    @media screen and (max-width: 600px) {
                        padding-left: 0.6rem;
                        padding-right: 0.6rem;
                    }
                }
            }

            &-item {
                width: calc(100% / 5);
                position: relative;
                margin-top: 2rem;
            }

            &-img {
                padding-top: 100%;
                position: relative;
                overflow: hidden;
                display: block;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &_cmt {
        display: flex;
        flex-direction: column;
        row-gap: 2.2rem;

        @media screen and (max-width: 1400px) {
            row-gap: 1.8rem;
        }

        @media screen and (max-width: 1200px) {
            row-gap: 1.4rem;
        }

        &-filter {
            &-wrap {
                max-width: 20rem;
                width: 100%;
                .note-text {
                    flex-shrink: 0;
                }
                display: flex;
                column-gap: 0.6rem;
                align-items: end;
            }
        }

        &--img {
            &-list {
                display: none;

                &.showed {
                    display: flex;
                }

                &.d-wrap {
                    margin-left: -0.6rem;
                    margin-right: -0.6rem;
                }

                .d-item {
                    padding-left: 0.6rem;
                    padding-right: 0.6rem;
                }
            }

            &-item {
                // display: ;
                // @media screen and (max-width: 1200px) {
                //     width: calc(100% / 4);

                //     &:nth-child(4) {
                //         display: none !important;
                //     }
                // }

                // @media screen and (max-width: 460px) {
                //     width: calc(100% / 3);

                //     &:nth-child(4) {
                //         display: none !important;
                //     }

                //     &:nth-child(3) {
                //         display: none !important;
                //     }
                // }

                // &:first-child {
                //     display: block;
                // }

                // &:nth-child(2) {
                //     display: block;
                // }

                // &:nth-child(3) {
                //     display: block;
                // }

                // &:nth-child(4) {
                //     display: block;
                // }

                // &:last-child {
                //     display: block;
                //     .comment_cmt--img-sl {
                //         display: flex;
                //     }

                //     .comment_cmt--img-bg {
                //         &::after {
                //             display: block;
                //         }
                //     }
                // }
            }

            &-wrap {
                // max-width: 20rem;
                width: 100%;
                position: relative;
            }

            &-sl {
                display: flex;
                align-items: center;
                column-gap: 0.1rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                display: none;
                user-select: none;
                pointer-events: none;
                .note-text {
                }
            }

            &-bg {
                padding-top: 100%;
                position: relative;
                overflow: hidden;
                display: block;

                &::after {
                    content: "";
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color: #000000, $alpha: 0.5);
                    position: absolute;
                    display: none;
                    z-index: 2;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &--list {
            display: flex;
            flex-direction: column;
            row-gap: 3.2rem;

            @media screen and (max-width: 1200px) {
                row-gap: 2.4rem;
            }

            @media screen and (max-width: 860px) {
                row-gap: 2rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 1.6rem;
            }
        }

        &--item {
            display: flex;
            flex-direction: column;
            row-gap: var(--commentRow);

            &.end {
                .comment_cmt--item-wrap {
                    &::before {
                        display: none;
                    }
                }
            }

            &-wrap {
                display: flex;
                flex-direction: column;
                row-gap: var(--commentRow);

                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: calc(var(--widthImg) + 2rem);
                    left: calc(var(--widthImg) / 2);
                    width: 0.1rem;
                    height: calc(100% + var(--commentRow) - (var(--widthImg) - 1rem));
                    background: $color-gray;

                    @media screen and (max-width: 460px) {
                        height: calc(100% + var(--commentRow) - (var(--widthImg) - 1rem));
                    }
                }
            }

            & > .comment_cmt--list {
                padding-left: var(--commentPd);

                .comment_cmt--item {
                    border-bottom: none !important;

                    // &::before{
                    //     height: calc(100% - (var(--widthImg) + 2rem));
                    // }
                    .comment_cmt--item-ava {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            left: -4rem;
                            top: 50%;
                            width: calc(var(--widthImg) - 1.8rem);
                            height: 0.1rem;
                            background: $color-gray;

                            @media screen and (max-width: 1200px) {
                                left: -3rem;
                            }

                            @media screen and (max-width: 460px) {
                                left: -2.2rem;
                            }
                        }
                    }

                    & > .comment_cmt--list {
                        .comment_cmt--item {
                            margin-bottom: 1.6rem;
                            &:first-child{
                                .comment_cmt--item-ava {
                                    &::before {
                                        display: block;
                                    }
                                }
                            }

                            &:last-child{
                                margin-bottom: 0;
                            }
                            
                               
                            
                            .comment_cmt--item-ava {
                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            &-title {
                display: flex;
                align-items: center;
                column-gap: var(--commentCol);
            }

            &-ava {
                position: relative;
                flex-shrink: 0;
            }

            &-info {
                @media screen and (max-width: 1200px) {
                    .note-mn {
                        &:first-child {
                            transform: translateY(0.3rem);
                        }
                    }

                    .note-mn {
                        &:last-child {
                            transform: translateY(-0.3rem);
                        }
                    }
                }
            }

            &-img {
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 50%;
                overflow: hidden;

                @media screen and (max-width: 1200px) {
                    width: 3.6rem;
                    height: 3.6rem;
                }

                @media screen and (max-width: 460px) {
                    width: 3.2rem;
                    height: 3.2rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-ic {
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                overflow: hidden;
                position: absolute;
                top: -0.5rem;
                right: -0.5rem;

                @media screen and (max-width: 1200px) {
                    width: 1.6rem;
                    height: 1.6rem;
                }

                @media screen and (max-width: 460px) {
                    width: 1.5rem;
                    height: 1.5rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-box {
                display: flex;
                flex-direction: column;
                row-gap: 1.6rem;
                padding-left: var(--commentPd);
                padding-right: 1.2rem;

                @media screen and (max-width: 1200px) {
                    padding-left: var(--commentPd);
                    padding-right: 1.2rem;
                }

                @media screen and (max-width: 600px) {
                    padding-left: var(--commentPd);
                    padding-right: 0rem;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                row-gap: 2rem;

                .note-text {
                    &.rg {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 7;
                    }

                    @media screen and (max-width: 460px) {
                        @include fz-12;
                    }

                    transition: all 0.3s linear;
                    // &.showed {
                    //     display: block;
                    // }
                }

                .btn-see {
                    width: fit-content;
                    transition: all 0.3s linear;
                    display: none;

                    &.showed {
                        display: block;
                    }

                    @include hover {
                        color: $color-gray;
                    }
                }
            }

            &-control {
                display: flex;
                flex-direction: column;
                row-gap: 1.6rem;

                &.actived {
                    z-index: 1100;

                    .comment_cmt--item-control-bottom {
                        position: relative;
                        z-index: 2100;
                    }
                }

                &-top {
                    display: flex;
                    align-items: center;
                    column-gap: 3.2rem;

                    @media screen and (max-width: 1200px) {
                        row-gap: 2.4rem;
                    }

                    .note-text {
                        cursor: pointer;
                        transition: all 0.3s linear;

                        @include hover {
                            color: $color-gray;
                        }

                        &.showed {
                            display: block !important;
                        }

                        &.btnControlRep {
                            display: none;
                        }

                        &.btnControlClose {
                            display: none;
                        }
                    }
                }

                &-bottom {
                    display: none;
                    opacity: 0;
                    @keyframes anHienBt {
                        0% {
                            opacity: 0;
                        }
                        50% {
                            opacity: 0.5;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                    &.showed {
                        display: block;
                        animation: anHienBt 0.8s linear forwards;
                    }
                }

                &-modal {
                    position: fixed;
                    inset: 0;
                    z-index: -1;
                    background: transparent;
                    visibility: hidden;
                    pointer-events: none;
                    width: 100%;
                    height: 100%;
                    &.actived {
                        visibility: visible;
                        pointer-events: visible;
                    }
                }
            }
        }
    }
}

.like {
    input {
        display: none;

        &:checked {
            & ~ .like-ic {
                i {
                    font-weight: 900;
                }
            }
        }
    }
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    &-ic {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
            @include fz-20;
            line-height: 100%;
            transform: translateY(-0.2rem);
            color: $color-pri;
        }
    }

    &-sl {
        color: $color-black;
        @include fz-16;
    }
}

.post {
    // padding-bottom: var(--size-pd);
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;

    @media screen and (max-width: 1400px) {
        row-gap: 2rem;
    }

    @media screen and (max-width: 1200px) {
        row-gap: 1.6rem;
    }

    @media screen and (max-width: 860px) {
        row-gap: 1.4rem;
    }

    @media screen and (max-width: 600px) {
        row-gap: 1.2rem;
    }

    @media screen and (max-width: 460px) {
        row-gap: 1rem;
    }

    .title-main {
        margin-bottom: 0;
    }

    &_share {
        display: flex;
        align-items: center;
        column-gap: 1.2rem;
        margin-top: 2rem;

        @media screen and (max-width: 1200px) {
            margin-top: 1.6rem;
        }

        @media screen and (max-width: 600px) {
            margin-top: 1.2rem;
        }
        .note-text {
            flex-shrink: 0;
        }

        &--list {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;
        }

        &--item {
            width: 1.8rem;
            height: 1.8rem;
        }

        &--link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            @include hover {
                img {
                    transform: scale(1.2);
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s linear;
            }
        }
    }

    &_ct {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        margin-bottom: 2.4rem;

        .wp-caption {
            max-width: unset;
            width: 100% !important;
            padding: 0;
            margin: 0 !important;
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        .wp-caption p.wp-caption-text {
            margin-top: 1rem;
        }
        i {
            font-style: normal !important;
        }

        h2 {
            margin: unset !important;
            @media only screen and (max-width: 1200px) {
                font-size: 2.4rem !important;
            }

            @media only screen and (max-width: 860px) {
                font-size: 2rem !important;
            }

            // @media only screen and (max-width: 860px) {
            //     font-size: 2rem;
            // }
        }

        @media screen and (max-width: 1400px) {
            row-gap: 2rem;
            margin-bottom: 2rem;
        }

        @media screen and (max-width: 1200px) {
            row-gap: 1.6rem;
            margin-bottom: 1.6rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.4rem;
            margin-bottom: 1.4rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.2rem;
            margin-bottom: 1.2rem;
        }

        &--img {
            display: flex;
            flex-direction: column;
            row-gap: 1.6rem;

            img {
                width: 100%;
                height: 100%;
            }

            .note-sm {
                text-align: center;
                font-style: italic;
                display: block;
                width: 100%;
            }
        }

        iframe {
            width: 100%;
            height: calc(100vw * 600 / 1728);
            margin-bottom: 2rem;
        }
    }

    &_tab {
        &.ogran {
            .post_tab--item {
                &.actived {
                    .post_tab--link {
                        background: $color-organ;
                        color: $color-white2;
                    }

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }

            .post_tab--link {
                @include hover {
                    background: $color-organ;
                    color: $color-white2;

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }
        }

        &.green {
            .post_tab--item {
                &.actived {
                    .post_tab--link {
                        background: $color-green;
                        color: $color-white2;
                    }

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }

            .post_tab--link {
                @include hover {
                    background: $color-green;
                    color: $color-white2;

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }
        }

        &.green2 {
            .post_tab--item {
                &.actived {
                    .post_tab--link {
                        background: $color-green2;
                        color: $color-white2;
                    }

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }

            .post_tab--link {
                @include hover {
                    background: $color-green2;
                    color: $color-white2;

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }
        }

        &.blue {
            .post_tab--item {
                &.actived {
                    .post_tab--link {
                        background: $color-blue;
                        color: $color-white2;
                    }

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }

            .post_tab--link {
                @include hover {
                    background: $color-blue;
                    color: $color-white2;

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }
        }

        &.red {
            .post_tab--item {
                &.actived {
                    .post_tab--link {
                        background: $color-red;
                        color: $color-white2;
                    }

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }

            .post_tab--link {
                @include hover {
                    background: $color-red;
                    color: $color-white2;

                    .post_tab--ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%)
                                hue-rotate(87deg) brightness(109%) contrast(111%);
                        }
                    }
                }
            }
        }

        &--list {
            display: flex;
            // column-gap: 1.6rem;

            &.d-wrap {
                margin-left: -0.6rem;
                margin-right: -0.6rem;
            }

            .d-item {
                padding-left: 0.6rem;
                padding-right: 0.6rem;
            }

            .swiper {
            }

            .swiper-slide {
                height: auto;
                width: fit-content;
            }
        }

        &--item {
            height: 100%;
            &.actived {
                .post_tab--link {
                    background: $color-pri;
                    color: $color-white2;
                }

                .post_tab--ic {
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg)
                            brightness(109%) contrast(111%);
                    }
                }
            }
        }

        &--ic {
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: brightness(0) saturate(100%) invert(21%) sepia(7%) saturate(1268%) hue-rotate(186deg)
                    brightness(97%) contrast(88%);
            }

            @media screen and (max-width: 600px) {
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        &--link {
            transition: none;
            padding: 1.2rem 1.6rem;
            border-radius: 0.6rem;
            background: $color-gray2;
            font-weight: 600;
            display: flex;
            align-items: center;
            column-gap: 0.8rem;
            user-select: none;
            @include fz-14;
            @include hover {
                background: $color-pri;
                color: $color-white2;

                .post_tab--ic {
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg)
                            brightness(109%) contrast(111%);
                    }
                }
            }

            @media screen and (max-width: 1200px) {
                padding: 1rem 1.4rem;
                column-gap: 0.6rem;
            }

            @media screen and (max-width: 600px) {
                padding: 0.8rem 1.2rem;
                // column-gap: 0.4rem;
            }

            @media screen and (max-width: 460px) {
                padding: 0.6rem 1rem;

                @include fz-12;
            }
        }
    }

    &-wrap {
        width: 100%;
    }

    &_list {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;

        @media screen and (max-width: 1200px) {
            row-gap: 2rem;
        }

        @media screen and (max-width: 800px) {
            row-gap: 1.6rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.2rem;
        }
    }

    &_item {
    }

    &_link {
        height: 100%;
        width: 100%;
        @include hover {
            .post_link--lf-img {
                img {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }

        &--wrap {
            width: 100%;
            height: 100%;
            display: flex;
            column-gap: 2rem;
            @media screen and (max-width: 1400px) {
                column-gap: 1.4rem;
            }

            @media screen and (max-width: 1200px) {
                column-gap: 1.2rem;
            }

            @media screen and (max-width: 600px) {
                column-gap: 0.6rem;
            }

            @media screen and (max-width: 360px) {
                flex-direction: column;
                row-gap: 0.6rem;
            }
        }

        &--lf {
            flex-shrink: 0;
            width: calc(100% / 3);

            @media screen and (max-width: 360px) {
                width: 100%;
            }

            &-wrap {
                height: 100%;
                // max-width: 30rem;
                width: 100%;
            }

            &-img {
                padding-top: calc(100% * 198 / 300);
                width: 100%;
                height: 100%;
                display: block;
                position: relative;
                overflow: hidden;
                border-radius: 0.4rem;
                flex-shrink: 0;
                @include hover {
                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s linear;
                }
            }
        }

        &--rt {
            flex: 1;
            width: 100%;
            overflow: hidden;
            &-wrap {
                // height: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 1.2rem;
                // justify-content: space-between;

                @media screen and (max-width: 1200px) {
                    row-gap: 0.8rem;
                }

                @media screen and (max-width: 600px) {
                    row-gap: 0.6rem;
                }

                .title-mn {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .note-text {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .box-info {
                }
            }
        }

        &--list {
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
        }

        &--item {
        }

        &--link {
            display: flex;
            align-items: start;
            column-gap: 1rem;

            .note-sm {
                transition: all 0.3s linear;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            @include hover {
                .note-sm {
                    color: $color-six;
                }

                .post_link--ic {
                    background: $color-six;

                    i {
                        color: $color-white;
                    }
                }
            }
        }

        &--ic {
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background: #e6e6e6;
            border-radius: 50%;
            overflow: hidden;
            transition: all 0.3s linear;
            transform: translateY(0.1rem);
            i {
                font-size: 1.2rem;
                color: #777777;
                transition: all 0.3s linear;
            }
        }
    }
}

.news {
    &_post {
        width: 100%;
        height: 100%;

        &--link {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            @include hover {
                .news_post--img {
                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }

                .news_post--title {
                    .note-md {
                        color: $color-white;
                    }
                }
            }
        }

        &--img {
            display: block;
            padding-top: 101%;
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            // min-height: 28.8rem;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                transition: all 0.3s linear;
                object-position: top;
            }
        }

        &--title {
            padding: 1rem 1.6rem;
            background: $color-black-4;
            min-height: 10rem;
            display: block;
            width: 100%;
            overflow: hidden;
            flex-shrink: 0;
            .note-md {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            @media screen and (max-width: 1200px) {
                padding: 1rem 1.2rem;
            }
        }
    }

    &_list {
        row-gap: 3.2rem;

        @media screen and (max-width: 1400px) {
            row-gap: 2rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.6rem;
        }
    }

    &_lq {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;

        @media screen and (max-width: 1400px) {
            row-gap: 2rem;
        }

        @media screen and (max-width: 1200px) {
            row-gap: 1.6rem;
        }

        &--list {
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;

            @media screen and (max-width: 1400px) {
                row-gap: 1.2rem;
            }

            @media screen and (max-width: 860px) {
                flex-direction: row;
                flex-wrap: wrap;
                column-gap: 0.8rem;
                justify-content: space-between;
            }
        }

        &--alert {
            position: absolute;
            top: 2.4rem;
            left: 2.4rem;
            background: $color-pri;
            z-index: 2;
            color: $color-white2;
            // font-size: 1.4rem;
            @include fz-14;
            padding: 0.8rem 1rem;
            border-radius: 0.6rem;
            overflow: hidden;

            @media screen and (max-width: 1400px) {
                top: 2rem;
                left: 2rem;
                padding: 0.4rem 0.8rem;
            }

            @media screen and (max-width: 1200px) {
                top: 1.6rem;
                left: 1.6rem;
                // padding: 0.2rem 0.6rem;
            }

            @media screen and (max-width: 600px) {
                top: 1.6rem;
                left: 1.6rem;
                padding: 0.4rem 0.6rem;
                @include fz-12;
            }
        }

        &--item {
            overflow: hidden;
            @media screen and (max-width: 860px) {
                width: 100%;
                &:not(:first-child) {
                    width: calc(50% - 0.8rem);
                }

                &:first-child {
                    .news_lq--title {
                        padding-top: 20rem;
                    }
                }
            }

            @media screen and (max-width: 600px) {
                width: 100% !important;
                .news_lq--title {
                    padding-top: 18rem !important;
                }
            }
        }

        &--link {
            position: relative;
            display: block;
            overflow: hidden;
            border-radius: 0.6rem;
            height: 100%;
            @include hover {
                .news_lq--bg {
                    transform: scale(1.1);
                }
            }
        }

        &--bg {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            transition: all 0.3s linear;

            &::before {
                content: "";
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, #242424 49.77%, rgba(36, 36, 36, 0) 100%);
                opacity: 0.5;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &--title {
            display: block;
            padding: 14rem 2.4rem 2.4rem 2.4rem;
            position: relative;
            z-index: 2;

            @media screen and (max-width: 1400px) {
                padding: 12rem 2rem 2rem 2rem;
            }

            @media screen and (max-width: 1200px) {
                padding: 12rem 1.6rem 1.6rem 1.6rem;
            }

            .note-text {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                transition: all 0.3s linear;
            }
        }
    }
}

.author {
    &_bg {
        padding-top: calc(100% * 416 / 1290);
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_wrap {
        max-width: 95%;
        width: 100%;
        margin: auto;

        position: relative;
        z-index: 2;
        // align-items: end;

        @media screen and (max-width: 800px) {
            flex-direction: column;
            row-gap: 0.6rem;
            align-items: center;
            max-width: unset;
        }
    }

    &_bio {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        width: fit-content;
        &--ic {
            width: 1.6rem;
            height: 1.6rem;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .note-text {
            transform: translateY(0.2rem);
        }
    }

    &_avatar {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &--img {
            width: 23rem;
            height: 23rem;
            border-radius: 50%;
            overflow: hidden;
            border: 0.4rem solid $color-white2;
            flex-shrink: 0;

            @media screen and (max-width: 1200px) {
                width: 20rem;
                height: 20rem;
            }

            @media screen and (max-width: 600px) {
                width: 12rem;
                height: 12rem;
            }

            @media screen and (max-width: 460px) {
                width: 10rem;
                height: 10rem;
            }

            @media screen and (max-width: 360px) {
                width: 8rem;
                height: 8rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &--ic {
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            position: absolute;
            bottom: 0rem;
            right: 1rem;

            @media screen and (max-width: 1200px) {
                width: 4rem;
                height: 4rem;
                right: 2.4rem;
            }

            @media screen and (max-width: 600px) {
                width: 3rem;
                height: 3rem;
                right: 2.4rem;
            }

            @media screen and (max-width: 460px) {
                width: 2.4rem;
                height: 2.4rem;
                right: 2rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &_lf {
        // width: calc(100% * 3 / 12);\
        width: 26rem;

        @media screen and (max-width: 1200px) {
            width: 24rem;
        }

        @media screen and (max-width: 800px) {
            width: 22rem;
        }

        @media screen and (max-width: 600px) {
            width: 15rem;
        }

        @media screen and (max-width: 460px) {
            width: 12rem;
        }

        &--wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 1.6rem;
            width: 100%;
            margin-top: -50%;
            @media screen and (max-width: 800px) {
                row-gap: 1.2rem;
            }

            @media screen and (max-width: 360px) {
                margin-top: -40%;
            }
        }
    }

    &_rt {
        flex: 1;
        width: 100%;

        &--wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;
            margin-top: -8rem;
            @media screen and (max-width: 1200px) {
                row-gap: 2rem;
                margin-top: -6rem;
            }

            @media screen and (max-width: 800px) {
                row-gap: 1rem;
                margin-top: 0rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 0.6rem;
                margin-top: 0rem;
            }
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        @media screen and (max-width: 800px) {
            align-items: center;
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 600px) {
            align-items: center;
            row-gap: 1rem;
        }

        @media screen and (max-width: 460px) {
            align-items: center;
            row-gap: 0.8rem;
        }
    }

    &_info {
        display: flex;
        align-items: center;
        column-gap: 2.4rem;
        @media screen and (max-width: 800px) {
            flex-wrap: wrap;
            row-gap: 0.6rem;
            justify-content: center;
        }
    }

    &_location {
        display: flex;
        align-items: center;
        column-gap: 0.2rem;

        &--ic {
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            i {
                @include fz-20;
            }
        }
    }

    &_name {
        @media screen and (max-width: 800px) {
            .title-sm4 {
                color: $color-text;
                text-align: center;
            }
        }
    }
}
