@charset "UTF-8";
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/themify-icons/themify-icons.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/themifi/themify-icons.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/magnific/magnific-popup.css");
@import url("../assets/library/plyr/plyr.css");
@import url("../assets/library/emoji/emoji.min.css");
@import url("../assets/font/reddit-sans-cufonfonts/stylesheet.css");
@import url("../assets/font/Playfair_Display/stylesheet.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.mona-content strong,
.mona-content b {
  font-weight: bold; }

.mona-content em,
.mona-content i {
  font-style: italic; }

.mona-content h1,
.mona-content h2,
.mona-content h3,
.mona-content h4,
.mona-content h5,
.mona-content h6 {
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0.6em; }

.mona-content h1 {
  font-size: 1.7em; }

.mona-content h2 {
  font-size: 1.6em; }

.mona-content h3 {
  font-size: 1.25em; }

.mona-content h4 {
  font-size: 1.125em; }

.mona-content h5 {
  font-size: 1em; }

.mona-content h6 {
  font-size: 0.85em; }

.mona-content p {
  margin: 1em 0; }

.mona-content ul,
.mona-content ol {
  margin: 1em 0;
  list-style-position: inside; }

.mona-content ul ul,
.mona-content ul ol,
.mona-content ol ul,
.mona-content ol ol {
  margin-left: 1em; }

.mona-content ul {
  list-style-type: disc; }

.mona-content ol {
  list-style-type: decimal; }

.mona-content ul ul,
.mona-content ol ul {
  list-style-type: circle; }

.mona-content ol ol,
.mona-content ul ol {
  list-style-type: lower-latin; }

.mona-content img,
.mona-content iframe {
  max-width: 100% !important;
  height: auto; }

.mona-content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1em 0;
  padding: 1em;
  quotes: "“" "”" "‘" "’"; }
  .mona-content blockquote::before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }
  .mona-content blockquote p {
    display: inline; }

.mona-content table {
  border-collapse: collapse;
  max-width: 100%;
  margin: 1em 0;
  border: 1px solid #e1e1e1; }
  .mona-content table th,
  .mona-content table td {
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 10px;
    vertical-align: middle; }

.mona-content .mona-youtube-wrap {
  position: relative;
  height: 0;
  padding-top: 56.25%; }
  .mona-content .mona-youtube-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.mona-content .mona-table-wrap {
  overflow: auto; }

:root {
  --size-hd: 10rem;
  --size-pdl: 6rem;
  --size-pdl-tl: 4rem;
  --size-pdl-tlv: 3rem;
  --size-pdl-mb: 2rem;
  --size-pd: 1.6rem;
  --size-pd-tl: 1.2rem;
  --size-pd-mb: 0.8rem;
  --font-pri: "Reddit Sans Fudge";
  --font-sec: "'Playfair Display', serif"; }

* {
  box-sizing: border-box; }

html {
  font-size: 10px;
  scroll-behavior: smooth; }
  @media screen and (min-width: 2000px) {
    html {
      font-size: 100%; } }

body {
  font-family: "Reddit Sans Fudge";
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  color: #393d48;
  background-color: #fff; }
  @media screen and (min-width: 2000px) {
    body {
      font-size: 100%; } }
  body.actived {
    overflow: hidden !important; }

a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  @media screen and (min-width: 1200px) {
    a:hover {
      color: #9E1C1A; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: transparent !important;
  color: #393d48 !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  -webkit-text-fill-color: black !important; }

input[type="search"]::-webkit-search-cancel-button {
  display: none; }

button,
select {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  cursor: pointer; }

button {
  padding: 0;
  background-color: transparent;
  cursor: pointer; }

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: var(--font-pri); }

button,
select,
input,
textarea {
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  color: black;
  background: transparent; }
  button:focus,
  select:focus,
  input:focus,
  textarea:focus {
    border: none;
    outline: none;
    box-shadow: none; }
  button:focus-visible,
  select:focus-visible,
  input:focus-visible,
  textarea:focus-visible {
    border: none;
    outline: none;
    box-shadow: none; }

::-webkit-scrollbar {
  width: 0.3rem; }

::-webkit-scrollbar-thumb {
  background: #8146A3; }

::-webkit-scrollbar-track {
  background: white !important;
  border-radius: 0.4rem !important; }

@supports (-webkit-touch-action: none) {
  input,
  select,
  textarea {
    font-size: 16px; } }

.main.pt {
  padding-top: 0rem; }
  @media screen and (max-width: 800px) {
    .main.pt {
      padding-top: 7rem; } }

.main.pt-2 {
  padding-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .main.pt-2 {
      padding-top: 11rem; } }
  @media screen and (max-width: 600px) {
    .main.pt-2 {
      padding-top: 10rem; } }

.wrap {
  display: flex;
  width: 100%; }
  .wrap.no-pd .content {
    padding-top: 0; }
  .wrap-lf {
    width: 9.6rem;
    flex-shrink: 0;
    transition: width 1s ease; }
    @media screen and (max-width: 1400px) {
      .wrap-lf {
        width: 7.2rem; } }
    @media screen and (max-width: 800px) {
      .wrap-lf {
        width: 0rem; } }
    .wrap-lf.actived {
      width: 30rem; }
      @media screen and (max-width: 1400px) {
        .wrap-lf.actived {
          width: 26rem; } }
      @media screen and (max-width: 1200px) {
        .wrap-lf.actived {
          width: 7.2rem; } }
      @media screen and (max-width: 800px) {
        .wrap-lf.actived {
          width: 0rem; } }
  .wrap-rt {
    flex: 1;
    width: 100%;
    transition: width 1s ease; }
  .wrap-content {
    width: 95%;
    margin: auto; }
    @media screen and (max-width: 1400px) {
      .wrap-content {
        width: 100%; } }
    @media screen and (max-width: 1200px) {
      .wrap-content {
        width: 100%; } }
    @media screen and (max-width: 800px) {
      .wrap-content {
        width: 100%; } }

.container {
  width: 100%;
  max-width: 132rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.5rem; }

.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none; }

iframe {
  vertical-align: middle; }

img {
  max-width: 100%;
  height: auto; }

.t-center {
  text-align: center; }

.fw-b {
  font-weight: bold; }

.fsi {
  font-style: italic; }

.t-up {
  text-transform: uppercase; }

.tabBtn {
  cursor: pointer; }

.gItem {
  cursor: pointer; }

.global-title {
  max-width: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden; }

.title-lg {
  font-size: 9.6rem;
  line-height: 1.4; }
  @media only screen and (max-width: 1200px) {
    .title-lg {
      font-size: 8.6rem; } }
  @media only screen and (max-width: 800px) {
    .title-lg {
      font-size: 7.6rem; } }
  @media only screen and (max-width: 600px) {
    .title-lg {
      font-size: 6.6rem; } }
  @media only screen and (max-width: 500px) {
    .title-lg {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 400px) {
    .title-lg {
      font-size: 3.6rem; } }
  @media screen and (max-width: 800px) {
    .title-lg {
      line-height: 1.5; } }

.title-md {
  font-size: 6.4rem;
  line-height: 1.4; }
  @media only screen and (max-width: 1200px) {
    .title-md {
      font-size: 5.4rem; } }
  @media only screen and (max-width: 800px) {
    .title-md {
      font-size: 4.4rem; } }
  @media only screen and (max-width: 600px) {
    .title-md {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 460px) {
    .title-md {
      font-size: 3rem; } }
  @media screen and (max-width: 800px) {
    .title-md {
      line-height: 1.5; } }
  .title-md strong {
    color: #8146A3; }

.title-sm {
  font-size: 3.2rem; }
  @media only screen and (max-width: 1200px) {
    .title-sm {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 600px) {
    .title-sm {
      font-size: 2rem; } }
  @media only screen and (max-width: 460px) {
    .title-sm {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 360px) {
    .title-sm {
      font-size: 1.6rem; } }

.title-sm2 {
  font-size: 3.6rem; }
  @media only screen and (max-width: 1200px) {
    .title-sm2 {
      font-size: 2.6rem; } }

.title-sm3 {
  font-size: 4rem;
  line-height: 1.3; }
  @media only screen and (max-width: 1200px) {
    .title-sm3 {
      font-size: 3rem; } }
  @media only screen and (max-width: 800px) {
    .title-sm3 {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 600px) {
    .title-sm3 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 460px) {
    .title-sm3 {
      font-size: 2.2rem; } }
  @media screen and (max-width: 800px) {
    .title-sm3 {
      line-height: 1.5; } }

.title-sm4 {
  font-size: 4.8rem; }
  @media only screen and (max-width: 1200px) {
    .title-sm4 {
      font-size: 3.8rem; } }
  @media only screen and (max-width: 800px) {
    .title-sm4 {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 300px) {
    .title-sm4 {
      font-size: 2.4rem; } }

.title-mn {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .title-mn {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .title-mn {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) {
    .title-mn {
      font-size: 1.6rem; } }

.title-mn2 {
  font-size: 2.8rem; }
  @media only screen and (max-width: 1200px) {
    .title-mn2 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 800px) {
    .title-mn2 {
      font-size: 1.8rem; } }

.note-title {
  font-weight: 700; }

.note-text {
  font-size: 1.6rem; }
  @media only screen and (max-width: 1200px) {
    .note-text {
      font-size: 1.4rem; } }
  .note-text strong {
    font-weight: 700; }

.note-lg {
  font-size: 2rem;
  font-weight: 600; }
  @media only screen and (max-width: 1200px) {
    .note-lg {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) {
    .note-lg {
      font-size: 1.6rem; } }
  .note-lg strong {
    color: #8146A3; }

.note-md {
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .note-md {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .note-md {
      font-size: 1.5rem; } }
  @media only screen and (max-width: 600px) {
    .note-md {
      font-size: 1.4rem; } }

.note-sm {
  font-size: 1.4rem; }

.note-mn {
  font-size: 1.2rem; }

.note-ic {
  width: 1.2rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease; }
  .note-ic i {
    color: #8146A3;
    font-size: 1.4rem; }

.fl-Reddit {
  font-family: var(--font-pri); }

.fl-Play {
  font-family: var(--font-sec); }

.cl-pri {
  color: #8146A3; }

.cl-white {
  color: #ffffff; }

.cl-white2 {
  color: #fefefe; }

.cl-yellow {
  color: #f5d461; }

.cl-second {
  color: #393D48; }

.cl-text {
  color: #393d48; }

.cl-text2 {
  color: #5f626b; }

.cl-text3 {
  color: #222; }

.cl-gray {
  color: #999; }

.cl-gray2 {
  color: #D9D9D9; }

.cl-gray3 {
  color: #94a3b8; }

.cl-gray4 {
  color: #C7C9D9; }

.cl-black2 {
  color: #444; }

.cl-black3 {
  color: #696061; }

.cl-black {
  color: #000000; }

.link-text {
  color: #393D48;
  font-size: 2rem;
  font-weight: 600; }
  @media only screen and (max-width: 1200px) {
    .link-text {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) {
    .link-text {
      font-size: 1.6rem; } }
  @media (min-width: 1201px) {
    .link-text:hover {
      color: #8146A3; } }

.content {
  padding-top: var(--size-pdl);
  padding-bottom: var(--size-pdl);
  width: 100%; }
  @media screen and (max-width: 1200px) {
    .content {
      padding-top: var(--size-pdl-tl);
      padding-bottom: var(--size-pdl-tl); } }
  @media screen and (max-width: 860px) {
    .content {
      padding-top: var(--size-pdl-tlv);
      padding-bottom: var(--size-pdl-tlv); } }
  @media screen and (max-width: 600px) {
    .content {
      padding-top: var(--size-pdl-mb);
      padding-bottom: var(--size-pdl-mb); } }
  .content-title {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    margin-bottom: 3.2rem; }
    @media screen and (max-width: 1400px) {
      .content-title {
        row-gap: 1.4rem;
        margin-bottom: 2.4rem; } }
    @media screen and (max-width: 1200px) {
      .content-title {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 800px) {
      .content-title {
        margin-bottom: 2rem;
        row-gap: 0.8rem; } }
    @media screen and (max-width: 600px) {
      .content-title {
        margin-bottom: 1.2rem;
        row-gap: 0.8rem; } }
  .content-date-list {
    display: flex;
    align-items: center;
    column-gap: 2.5rem; }
  .content-date-item:not(:first-child) {
    position: relative; }
    .content-date-item:not(:first-child)::before {
      content: "|";
      position: absolute;
      color: #999;
      left: -1.5rem; }
  .content-date-item.actived .note-sm {
    color: #8146A3;
    font-weight: 600; }
  .content-wrap.cus {
    display: flex;
    flex-direction: column;
    row-gap: 6rem; }
    @media screen and (max-width: 1200px) {
      .content-wrap.cus {
        row-gap: 4rem; } }
    @media screen and (max-width: 860px) {
      .content-wrap.cus {
        row-gap: 3rem; } }
    @media screen and (max-width: 600px) {
      .content-wrap.cus {
        row-gap: 2rem; } }
    @media screen and (max-width: 460px) {
      .content-wrap.cus {
        row-gap: 1.4rem; } }
  @media screen and (max-width: 860px) {
    .content-wrap {
      flex-direction: column;
      row-gap: 2rem; } }
  @media screen and (max-width: 600px) {
    .content-wrap {
      flex-direction: column;
      row-gap: 1.6rem; } }
  .content-top.cus {
    width: 100%; }
    .content-top.cus .content-top-lf {
      width: calc(3 * 100% / 12); }
      @media screen and (max-width: 1400px) {
        .content-top.cus .content-top-lf {
          width: calc(3.5 * 100% / 12); } }
      @media screen and (max-width: 860px) {
        .content-top.cus .content-top-lf {
          width: calc(4 * 100% / 12); } }
      @media screen and (max-width: 800px) {
        .content-top.cus .content-top-lf {
          width: 100%; } }
    @media screen and (max-width: 860px) {
      .content-top.cus .content-top-wrap {
        flex-direction: row; } }
    @media screen and (max-width: 800px) {
      .content-top.cus .content-top-wrap {
        flex-direction: column;
        row-gap: 1.6rem; } }
    @media screen and (max-width: 800px) {
      .content-top.cus .content-top-wrap .news_post--img {
        padding-top: calc(100% * 600 / 800); } }
    @media screen and (max-width: 460px) {
      .content-top.cus .content-top-wrap .news_post--img {
        padding-top: 100%; } }
    .content-top.cus .content-top-rt-wrap {
      position: relative;
      top: unset; }
    .content-top.cus .content-top-lf-wrap .news_post--title {
      min-height: unset; }
    @media screen and (max-width: 800px) {
      .content-top.cus .news_item {
        width: calc(100% / 3); } }
    @media screen and (max-width: 460px) {
      .content-top.cus .news_item {
        width: calc(100% / 2); } }
  @media screen and (max-width: 860px) {
    .content-top-wrap {
      flex-direction: column;
      row-gap: 1.2rem; } }
  .content-top-lf {
    width: calc(9 * 100% / 12); }
    @media screen and (max-width: 1600px) {
      .content-top-lf {
        width: calc(100% * 8.5 / 12); } }
    @media screen and (max-width: 1400px) {
      .content-top-lf {
        width: calc(100% * 8.5 / 12); } }
    @media screen and (max-width: 860px) {
      .content-top-lf {
        width: 100%; } }
    .content-top-lf-wrap {
      height: 100%; }
  .content-top-rt {
    width: 100%;
    flex: 1; }
    .content-top-rt-wrap {
      position: sticky;
      top: 2rem; }
  @media screen and (max-width: 860px) {
    .content-bottom-wrap {
      flex-direction: column;
      row-gap: 4rem; } }
  .content-bottom-lf {
    width: calc(9 * 100% / 12); }
    @media screen and (max-width: 1600px) {
      .content-bottom-lf {
        width: calc(100% * 8.5 / 12); } }
    @media screen and (max-width: 1400px) {
      .content-bottom-lf {
        width: calc(100% * 8.5 / 12); } }
    @media screen and (max-width: 860px) {
      .content-bottom-lf {
        width: 100%; } }
    .content-bottom-lf-wrap {
      height: 100%; }
  .content-bottom-rt {
    width: 100%;
    flex: 1; }
    .content-bottom-rt-wrap {
      position: sticky;
      top: 2rem; }
  .content-lf {
    width: calc(100% * 9 / 12); }
    @media screen and (max-width: 1600px) {
      .content-lf {
        width: calc(100% * 8.5 / 12); } }
    @media screen and (max-width: 1400px) {
      .content-lf {
        width: calc(100% * 8 / 12); } }
    @media screen and (max-width: 860px) {
      .content-lf {
        width: 100%; } }
  .content-rt {
    flex: 1;
    width: 100%;
    position: relative; }
    .content-rt-wrap {
      height: 100%;
      width: 100%; }
    .content-rt-box {
      position: sticky;
      top: 2rem; }

.box-info {
  display: flex;
  column-gap: 0.8rem;
  align-items: center;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .box-info {
      column-gap: 0.4rem; } }
  .box-info-author {
    padding-right: 0.8rem;
    border-right: 0.1rem solid #D9D9D9; }
    @media screen and (max-width: 1200px) {
      .box-info-author {
        padding-right: 0.4rem; } }
    .box-info-author .note-sm {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: nowrap; }
      @media screen and (max-width: 860px) {
        .box-info-author .note-sm {
          font-size: 1.2rem; } }
  .box-info-date {
    display: flex;
    column-gap: 0.8rem;
    row-gap: 0.2rem;
    align-items: center; }
    .box-info-date .note-sm {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
      @media screen and (max-width: 860px) {
        .box-info-date .note-sm {
          font-size: 1.2rem; } }

.title-main {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  margin-bottom: 2.4rem; }
  @media screen and (max-width: 1200px) {
    .title-main {
      margin-bottom: 2rem;
      column-gap: 0.8rem; } }
  @media screen and (max-width: 800px) {
    .title-main {
      margin-bottom: 1.6rem;
      column-gap: 0.6rem; } }
  @media screen and (max-width: 600px) {
    .title-main {
      margin-bottom: 1.4rem; } }
  @media screen and (max-width: 460px) {
    .title-main {
      margin-bottom: 1.2rem; } }
  .title-main .title-sm {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .title-main.no-color .title-main-ic img {
    filter: brightness(0) saturate(100%) invert(18%) sepia(16%) saturate(693%) hue-rotate(186deg) brightness(93%) contrast(80%) !important; }
  .title-main-ic {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .title-main-ic {
        width: 2rem;
        height: 2rem; } }
    @media screen and (max-width: 800px) {
      .title-main-ic {
        width: 1.6rem;
        height: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .title-main-ic {
        width: 1.4rem;
        height: 1.4rem; } }
    @media screen and (max-width: 460px) {
      .title-main-ic {
        width: 1.2rem;
        height: 1.2rem; } }
    .title-main-ic img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.title-main2 {
  display: flex;
  align-items: center;
  column-gap: 1.2rem; }
  @media screen and (max-width: 1200px) {
    .title-main2 {
      column-gap: 0.8rem; } }
  @media screen and (max-width: 800px) {
    .title-main2 {
      column-gap: 0.6rem; } }
  .title-main2 .title-sm {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .title-main2-ic {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .title-main2-ic {
        width: 2rem;
        height: 2rem; } }
    @media screen and (max-width: 800px) {
      .title-main2-ic {
        width: 1.6rem;
        height: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .title-main2-ic {
        width: 1.4rem;
        height: 1.4rem; } }
    @media screen and (max-width: 460px) {
      .title-main2-ic {
        width: 1.2rem;
        height: 1.2rem; } }
    .title-main2-ic img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .title-main2.red strong {
    color: #ED1D24; }
  .title-main2.blue strong {
    color: #1d4ed8; }
  .title-main2.yellow strong {
    color: #F7CE46; }
  .title-main2.organ strong {
    color: #d97706; }
  .title-main2.green strong {
    color: #047857; }
  .title-main2.green2 strong {
    color: #0FB93C; }
  .title-main2.red strong {
    color: #ED1D24; }

.poster {
  max-width: 30rem;
  width: 100%; }
  @media screen and (max-width: 860px) {
    .poster {
      margin: auto; } }
  .poster-img {
    padding-top: calc(100% * 600 / 300);
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block; }
    .poster-img img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover; }

.row {
  --col-gap: 2.4rem;
  --row-gap: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--col-gap) / 2 * -1) calc(var(--row-gap) * -1); }

.col {
  width: 100%;
  padding: 0 calc(var(--col-gap) / 2);
  margin-bottom: var(--row-gap); }
  .col-11 {
    width: calc(11/12 * 100%); }
  .col-10 {
    width: calc(10/12 * 100%); }
  .col-9 {
    width: calc(9/12 * 100%); }
  .col-8 {
    width: calc(8/12 * 100%); }
  .col-7 {
    width: calc(7/12 * 100%); }
  .col-6 {
    width: 50%; }
  .col-5 {
    width: calc(5/12 * 100%); }
  .col-4 {
    width: calc(4/12 * 100%); }
  .col-3 {
    width: calc(3/12 * 100%); }
  .col-2 {
    width: calc(2/12 * 100%); }
  .col-1 {
    width: calc(1/12 * 100%); }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gap > .col,
.no-gap > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }
  .flex-center {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center; }
    .flex-center-between {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .flex-1 {
    flex: 1; }
  .flex-wrap {
    flex-wrap: wrap; }
  .flex-shrink {
    flex-shrink: 0; }
  .flex-ai-start {
    align-items: flex-start; }
  .flex-ai-center {
    align-items: center; }
  .flex-ai-end {
    align-items: flex-end; }
  .flex-jc-start {
    justify-content: flex-start; }
  .flex-jc-center {
    justify-content: center; }
  .flex-jc-end {
    justify-content: flex-end; }
  .flex-jc-between {
    justify-content: space-between; }
  .flex-jc-around {
    justify-content: space-around; }
  .flex-jc-evenly {
    justify-content: space-evenly; }
  .flex-row-reverse {
    flex-direction: row-reverse; }
  .flex-column {
    flex-direction: column; }
  .flex-column-reverse {
    flex-direction: column-reverse; }

.pos-relative {
  position: relative; }

.pos-abs {
  position: absolute; }

.pos-fixed {
  position: fixed; }

.pos-sticky {
  position: sticky; }

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; }

.overflow-hidden {
  overflow: hidden; }

.overflow-auto {
  overflow: auto; }

.overflow-scroll {
  overflow: scroll; }

.w-full {
  width: 100%; }

.w-50 {
  width: 50%; }

.w-screen {
  width: 100vw; }

.w-fit {
  width: fit-content; }

.w-max {
  width: max-content; }

.w-min {
  width: min-content; }

.h-full {
  height: 100%; }

.h-screen {
  height: 100vh; }

.h-50 {
  height: 50%; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-justify {
  text-align: justify; }

.upper {
  text-transform: uppercase; }

.lower {
  text-transform: lowercase; }

.capital {
  text-transform: capitalize; }

.rounded-full {
  border-radius: 100rem; }

.rounded-inherit {
  border-radius: inherit; }

.rounded-xl {
  border-radius: 20px; }

.rounded-lg {
  border-radius: 12px; }

.rounded-md {
  border-radius: 8px; }

.rounded-sm {
  border-radius: 4px; }

.border-none {
  border: none; }

.border-current {
  border-color: currentColor; }

.outline-none {
  outline: none; }

.outline-current {
  outline-color: currentColor; }

.bg-cover {
  background-size: cover; }

.bg-center {
  background-position: center; }

.bg-no-repeat {
  background-repeat: no-repeat; }

.bg-fixed {
  background-attachment: fixed; }

.pointer {
  cursor: pointer; }
  .pointer-none {
    pointer-events: none; }

.resize-none {
  resize: none; }

.block {
  display: block; }

.in-block {
  display: inline-block; }

.in-flex {
  display: inline-flex; }

.d-none {
  display: none; }

.opacity-1 {
  opacity: 0.1; }

.opacity-2 {
  opacity: 0.2; }

.opacity-3 {
  opacity: 0.3; }

.opacity-4 {
  opacity: 0.4; }

.opacity-5 {
  opacity: 0.5; }

.opacity-6 {
  opacity: 0.6; }

.opacity-7 {
  opacity: 0.7; }

.opacity-8 {
  opacity: 0.8; }

.opacity-9 {
  opacity: 0.9; }

.hidden {
  display: none; }

.show {
  display: block; }

@-moz-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-webkit-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-o-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-35deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(35deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-35deg) scale(1.1) skew(1deg); }
  40% {
    transform: rotate(35deg) scale(1.1) skew(1deg); }
  50% {
    transform: rotate(-35%) scale(1) skew(1deg); }
  60% {
    transform: rotate(35%) scale(1) skew(1deg); }
  70% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@-webkit-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes ZoomIn {
  50% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes animate2 {
  0% {
    opacity: 1;
    transform: scale(1.05); }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 0;
    transform: scale(0.2); } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@keyframes fadeCus {
  0% {
    opacity: 0;
    transform: scale(0.94, 0.9);
    filter: grayscale(1);
    box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5); }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: grayscale(0);
    box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5); } }

@keyframes changHeight {
  0% {
    height: 50%; }
  100% {
    height: 0; } }

.header {
  position: fixed;
  width: 100%;
  height: 7rem;
  top: 0;
  left: 0;
  z-index: 900;
  transition: all 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6);
  display: none; }
  @media screen and (max-width: 800px) {
    .header {
      display: block; } }
  .header-wrap {
    display: flex;
    align-items: center;
    column-gap: 1.4rem;
    height: 100%;
    justify-content: space-between;
    width: 100%;
    transition: all 0.3s ease; }
    .header-wrap-content {
      display: flex;
      align-items: center;
      column-gap: 4rem;
      height: 100%; }
      @media screen and (max-width: 1200px) {
        .header-wrap-content {
          column-gap: 1.6rem; } }
  .header-lang-list {
    display: flex;
    align-items: center;
    column-gap: 1.6rem; }
    @media screen and (max-width: 360px) {
      .header-lang-list {
        flex-direction: column;
        row-gap: 0.1rem; } }
  .header-lang-item {
    position: relative; }
    .header-lang-item.actived .header-lang-link {
      color: #8146A3; }
    .header-lang-item:not(:last-child)::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: -1rem;
      width: 0.2rem;
      height: 80%;
      background: #393D48; }
      @media screen and (max-width: 360px) {
        .header-lang-item:not(:last-child)::before {
          display: none; } }
  .header-nav {
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 1.2rem; }
    @media screen and (max-width: 1200px) {
      .header-nav .menu-list {
        display: none; } }
  .header-btn-search {
    display: flex;
    align-items: center;
    justify-content: center; }
  .header-logo {
    max-width: 5rem;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    overflow: hidden; }
    .header-logo::before {
      content: "";
      top: 50%;
      left: -120%;
      position: absolute;
      transform: translate(-50%, -50%) rotate(-55deg);
      width: 250%;
      height: 50%;
      background: rgba(255, 255, 255, 0.2);
      z-index: 1;
      opacity: 0.3; }
    @media (min-width: 1201px) {
      .header-logo:hover::before {
        left: 180%;
        opacity: 1;
        transition: left 0.5s linear, top 0.5s linear, opacity 0.3s ease; }
      .header-logo:hover img {
        transform: scale(1.02); } }
    .header-logo img {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      object-fit: cover; }
  .header-mobi {
    width: 3.6rem;
    height: 2.4rem;
    position: relative;
    display: none;
    cursor: pointer; }
    @media screen and (max-width: 1200px) {
      .header-mobi {
        display: block; } }
    .header-mobi .line {
      width: 100%;
      display: block;
      height: 0.3rem;
      background-color: #000000;
      transform: translateX(-50%);
      border-radius: 100rem;
      overflow: hidden;
      position: absolute; }
      .header-mobi .line:first-child {
        top: 0;
        left: 50%;
        width: 100%;
        transition: top 0.3s linear 0.5s, transform 0.3s linear 0s; }
      .header-mobi .line:nth-child(2) {
        top: 50%;
        left: 0;
        width: 70%;
        transform: translate(0%, -50%);
        transition: opacity 0.1s ease 0.3s; }
      .header-mobi .line:last-child {
        width: 100%;
        bottom: 0%;
        left: 50%;
        transition: bottom 0.3s linear 0.5s, transform 0.3s linear 0s; }
  .header-box {
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 6rem; }
    @media screen and (max-width: 1400px) {
      .header-box {
        column-gap: 3rem; } }
    @media screen and (max-width: 1200px) {
      .header-box {
        column-gap: 1.4rem;
        flex-direction: row-reverse; } }
    @media screen and (max-width: 460px) {
      .header-box {
        column-gap: 0.8rem; } }
    .header-box .btn {
      flex-shrink: 0;
      width: fit-content; }
      @media screen and (max-width: 1200px) {
        .header-box .btn {
          min-width: unset; } }
      .header-box .btn-ic {
        display: none; }
        @media screen and (max-width: 500px) {
          .header-box .btn-ic {
            display: flex; } }
      @media screen and (max-width: 500px) {
        .header-box .btn-text {
          display: none; } }
      @media screen and (max-width: 500px) {
        .header-box .btn {
          padding: 1.2rem 1.4rem; } }

.user.success {
  pointer-events: none; }

.user-link {
  width: 3.6rem;
  height: 3.6rem;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.user-img {
  width: 100%;
  height: 100%; }
  .user-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.modal-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: transparent;
  transition: all 0.3s linear;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  opacity: 0; }
  .modal-search.showed {
    visibility: visible;
    pointer-events: visible;
    opacity: 1; }

.footer {
  padding-top: var(--size-pdl);
  padding-bottom: var(--size-pdl);
  background: #ffffff;
  border-top: 0.1rem solid #D9D9D9;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .footer {
      padding-top: var(--size-pd-tl);
      padding-bottom: var(--size-pd-tl);
      padding-left: 10.4rem; } }
  @media screen and (max-width: 800px) {
    .footer {
      padding-left: 0; } }
  @media screen and (max-width: 600px) {
    .footer {
      padding-top: var(--size-pd-mb);
      padding-bottom: var(--size-pd-mb); } }
  @media screen and (max-width: 1200px) {
    .footer-top-wrap {
      flex-direction: column;
      row-gap: 1.2rem; } }
  .footer-top-lf {
    width: calc(100% * 5 / 12); }
    @media screen and (max-width: 1200px) {
      .footer-top-lf {
        width: 100%; } }
    .footer-top-lf-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      max-width: 42.6rem; }
      @media screen and (max-width: 1200px) {
        .footer-top-lf-wrap {
          width: 100%;
          max-width: none; } }
    .footer-top-lf-list {
      display: flex;
      flex-direction: column;
      row-gap: 2rem; }
      @media screen and (max-width: 1200px) {
        .footer-top-lf-list {
          row-gap: 1.6rem; } }
  .footer-top-rt {
    flex: 1;
    width: 100%; }
    .footer-top-rt-item {
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      width: 25%; }
      @media screen and (max-width: 1200px) {
        .footer-top-rt-item {
          width: 50%;
          row-gap: 0.6rem; } }
      .footer-top-rt-item .menu-list {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
        margin: 0; }
        @media screen and (max-width: 1200px) {
          .footer-top-rt-item .menu-list {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 1.2rem; } }
        @media screen and (max-width: 600px) {
          .footer-top-rt-item .menu-list {
            flex-direction: column;
            row-gap: 0.6rem; } }
      .footer-top-rt-item .menu-item {
        height: auto; }
      .footer-top-rt-item .menu-link {
        font-size: 1.4rem;
        color: #5f626b;
        border-left: none !important;
        height: auto;
        white-space: wrap; }
        @media (min-width: 1201px) {
          .footer-top-rt-item .menu-link:hover {
            color: #4D2A62; } }
  .footer-logo {
    max-width: 21rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .footer-logo {
        max-width: 18rem; } }
    .footer-logo img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .footer-bottom {
    margin-top: 2rem;
    padding-top: 2.4rem;
    border-top: 0.1rem solid rgba(255, 255, 255, 0.3); }
    @media screen and (max-width: 1200px) {
      .footer-bottom {
        margin-top: 1rem;
        padding-top: 1.2rem; } }
    .footer-bottom-wrap {
      display: flex;
      justify-content: space-between; }
  .footer-mona {
    display: flex;
    align-items: center;
    column-gap: 0.6rem;
    justify-content: space-between; }
    @media screen and (max-width: 360px) {
      .footer-mona {
        flex-direction: column;
        row-gap: 0.5rem; } }
    .footer-mona-img {
      max-width: 18rem;
      display: flex;
      align-items: center; }
      .footer-mona-img img {
        width: 100%;
        height: 100%; }
  .footer-mxh {
    margin: 0 auto 3.2rem;
    width: fit-content; }
    @media screen and (max-width: 1024px) {
      .footer-mxh {
        margin-left: 0;
        margin-bottom: 2rem; } }
    @media screen and (max-width: 500px) {
      .footer-mxh {
        margin: 0 auto 2.4rem; } }
    .footer-mxh-list {
      display: flex;
      column-gap: 1.6rem; }
    .footer-mxh-link {
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      transition: all 0.3s ease; }
      @media (min-width: 1201px) {
        .footer-mxh-link:hover {
          transform: scale(1.3); } }
      .footer-mxh-link img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

.gItem {
  position: relative;
  cursor: pointer; }
  .gItem .icon-expand {
    position: absolute;
    width: 6rem;
    height: 6rem;
    font-size: 3.6rem;
    color: #fff;
    z-index: 2;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 0.2rem solid white;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.3s 0.1s ease-in-out;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
  .gItem::before {
    position: absolute;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715); }
  @media screen and (min-width: 1201px) {
    .gItem:hover .icon-expand {
      transform: translate(-50%, -50%) scale(1); }
    .gItem:hover::before {
      opacity: 1; } }

.otherGl .gItem .icon-expand {
  border: none; }

.popup_main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 70rem;
  background: #fefefe;
  padding: 3.2rem;
  border-radius: 1.2rem;
  z-index: 4000;
  transition: all 0.5s linear;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }
  .popup_main.showed {
    opacity: 1;
    visibility: visible;
    pointer-events: visible; }
    .popup_main.showed ~ .popup_main--modal {
      opacity: 1;
      visibility: visible;
      pointer-events: visible; }
  @media screen and (max-width: 1200px) {
    .popup_main {
      max-width: 60rem; } }
  @media screen and (max-width: 800px) {
    .popup_main {
      max-width: 75%;
      padding: 2.8rem 2.4rem; } }
  @media screen and (max-width: 600px) {
    .popup_main {
      max-width: 85%;
      padding: 2.8rem 1.2rem; } }
  @media screen and (max-width: 460px) {
    .popup_main {
      max-width: 90%; } }
  .popup_main--exits {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .popup_main--exits i {
      font-size: 2rem; }
    @media screen and (max-width: 460px) {
      .popup_main--exits {
        top: 0.5rem;
        right: 0.5rem; } }
  .popup_main--wrap .title-mn2 {
    text-align: center;
    margin-bottom: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .popup_main--wrap .title-mn2 {
        margin-bottom: 1.2rem; } }
  .popup_main--content {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .popup_main--content {
        row-gap: 2rem; } }
    @media screen and (max-width: 800px) {
      .popup_main--content {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .popup_main--content {
        row-gap: 1.2rem; } }
    .popup_main--content .note-lg {
      text-align: center; }
    .popup_main--content .note-text {
      text-align: center; }
    .popup_main--content .btn {
      width: 100%;
      min-height: 6rem;
      justify-content: unset;
      position: relative;
      padding: 1.6rem; }
      @media screen and (max-width: 600px) {
        .popup_main--content .btn {
          min-height: 4.8rem;
          padding: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .popup_main--content .btn {
          justify-content: center; } }
      .popup_main--content .btn-text {
        max-width: 80%;
        font-size: 1.8rem;
        margin: auto; }
        @media screen and (max-width: 600px) {
          .popup_main--content .btn-text {
            font-size: 1.6rem;
            max-width: 70%; } }
        @media screen and (max-width: 360px) {
          .popup_main--content .btn-text {
            max-width: none;
            width: fit-content;
            font-size: 1.4rem;
            margin: unset; } }
      .popup_main--content .btn-ic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.6rem;
        flex-shrink: 0; }
        @media screen and (max-width: 360px) {
          .popup_main--content .btn-ic {
            position: static !important;
            transform: unset; } }
        @media screen and (max-width: 600px) {
          .popup_main--content .btn-ic img {
            width: 3.6rem;
            height: 3.6rem; } }
        @media screen and (max-width: 360px) {
          .popup_main--content .btn-ic img {
            width: 3rem;
            height: 3rem; } }
  .popup_main--modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear; }

.popup-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  max-width: 70rem;
  width: 100%;
  background: #fefefe;
  padding: 3.2rem;
  border-radius: 1.2rem;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.5s linear; }
  @media screen and (max-width: 1200px) {
    .popup-form {
      max-width: 60rem; } }
  @media screen and (max-width: 800px) {
    .popup-form {
      max-width: 75%;
      padding: 2.8rem 2.4rem; } }
  @media screen and (max-width: 600px) {
    .popup-form {
      max-width: 85%;
      padding: 2.8rem 1.2rem; } }
  @media screen and (max-width: 460px) {
    .popup-form {
      max-width: 90%; } }
  .popup-form.showed {
    opacity: 1;
    pointer-events: visible;
    visibility: visible; }
    .popup-form.showed ~ .popup-form-modal {
      visibility: visible;
      pointer-events: visible;
      opacity: 1; }
  .popup-form-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .popup-form-wrap {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 800px) {
      .popup-form-wrap {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .popup-form-wrap {
        row-gap: 1.2rem; } }
  .popup-form-title .title-mn2 {
    text-align: center; }
    @media screen and (max-width: 800px) {
      .popup-form-title .title-mn2 {
        font-size: 2rem; } }
    @media screen and (max-width: 460px) {
      .popup-form-title .title-mn2 {
        font-size: 1.8rem; } }
  .popup-form-list {
    display: flex;
    row-gap: 2rem;
    margin-bottom: 1.6rem;
    max-height: 60vh;
    overflow-y: auto; }
    @media screen and (max-width: 1200px) {
      .popup-form-list {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 800px) {
      .popup-form-list {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .popup-form-list {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 460px) {
      .popup-form-list {
        row-gap: 0.8rem; } }
  .popup-form-item {
    width: calc(100% / 2); }
    .popup-form-item:last-child {
      width: 100%; }
    @media screen and (max-width: 460px) {
      .popup-form-item {
        width: 100% !important; } }
  .popup-form-ex {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .popup-form-ex i {
      font-size: 2rem; }
    @media screen and (max-width: 460px) {
      .popup-form-ex {
        top: 0.5rem;
        right: 0.5rem; } }
  .popup-form-box .btn {
    margin: auto; }
  .popup-form-modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear; }

.popup-search {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  max-width: 70rem;
  width: 100%;
  background: #fefefe;
  padding: 3.2rem;
  border-radius: 1.2rem;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.5s linear; }
  .popup-search.showed {
    opacity: 1;
    pointer-events: visible;
    visibility: visible; }
    .popup-search.showed ~ .popup-search-modal {
      visibility: visible;
      pointer-events: visible;
      opacity: 1; }
  @media screen and (max-width: 1200px) {
    .popup-search {
      max-width: 60rem; } }
  @media screen and (max-width: 800px) {
    .popup-search {
      max-width: 75%;
      padding: 2.8rem 2.4rem; } }
  @media screen and (max-width: 600px) {
    .popup-search {
      max-width: 85%;
      padding: 2.8rem 1.2rem; } }
  @media screen and (max-width: 460px) {
    .popup-search {
      max-width: 90%; } }
  .popup-search-modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear; }
  .popup-search-tabs {
    position: relative; }
  .popup-search-ex {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .popup-search-ex i {
      font-size: 2rem; }
    @media screen and (max-width: 460px) {
      .popup-search-ex {
        top: 0.5rem;
        right: 0.5rem; } }
  .popup-search-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .popup-search-wrap {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 800px) {
      .popup-search-wrap {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .popup-search-wrap {
        row-gap: 1.2rem; } }
  .popup-search-title .title-mn2 {
    text-align: center; }
    @media screen and (max-width: 800px) {
      .popup-search-title .title-mn2 {
        font-size: 2rem; } }
    @media screen and (max-width: 460px) {
      .popup-search-title .title-mn2 {
        font-size: 1.8rem; } }
  .popup-search-content {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .popup-search-content {
        row-gap: 2.4rem; } }
    @media screen and (max-width: 860px) {
      .popup-search-content {
        row-gap: 2rem; } }
    @media screen and (max-width: 600px) {
      .popup-search-content {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 460px) {
      .popup-search-content {
        row-gap: 1.2rem; } }
  .popup-search-ip-wrap {
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    padding: 1.6rem 2.4rem;
    border-radius: 0.6rem;
    border: 0.1rem solid #999;
    transition: all 0.3s linear; }
    @media screen and (max-width: 1200px) {
      .popup-search-ip-wrap {
        padding: 1.2rem 2rem;
        column-gap: 0.8rem; } }
    @media screen and (max-width: 860px) {
      .popup-search-ip-wrap {
        padding: 1.2rem 1.6rem;
        column-gap: 0.6rem; } }
    @media screen and (max-width: 600px) {
      .popup-search-ip-wrap {
        padding: 1.2rem 1.2rem;
        column-gap: 0.4rem; } }
  .popup-search-ip-btn {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer; }
    @media (min-width: 1201px) {
      .popup-search-ip-btn:hover img {
        filter: brightness(0) saturate(100%) invert(32%) sepia(17%) saturate(2381%) hue-rotate(235deg) brightness(100%) contrast(89%); } }
    .popup-search-ip-btn img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .popup-search-ip-ip {
    width: 100%;
    flex: 1;
    cursor: pointer; }
    .popup-search-ip-ip input {
      width: 100%;
      height: 100%;
      transform: translateY(0.1rem);
      font-size: 1.8rem; }
      @media only screen and (max-width: 1200px) {
        .popup-search-ip-ip input {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 800px) {
        .popup-search-ip-ip input {
          font-size: 1.5rem; } }
      @media only screen and (max-width: 600px) {
        .popup-search-ip-ip input {
          font-size: 1.4rem; } }
      .popup-search-ip-ip input::placeholder {
        font-size: 1.8rem; }
        @media only screen and (max-width: 1200px) {
          .popup-search-ip-ip input::placeholder {
            font-size: 1.6rem; } }
        @media only screen and (max-width: 800px) {
          .popup-search-ip-ip input::placeholder {
            font-size: 1.5rem; } }
        @media only screen and (max-width: 600px) {
          .popup-search-ip-ip input::placeholder {
            font-size: 1.4rem; } }
  .popup-search-ip-clear {
    width: fit-content;
    flex-shrink: 0;
    cursor: pointer; }
    .popup-search-ip-clear.showed .note-md {
      opacity: 1;
      visibility: visible;
      pointer-events: visible; }
    .popup-search-ip-clear .note-md {
      transition: all 0.3s linear;
      opacity: 0;
      visibility: hidden;
      pointer-events: none; }
    @media (min-width: 1201px) {
      .popup-search-ip-clear:hover .note-md {
        color: #8146A3; } }
  .popup-search-result-list {
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .popup-search-result-list {
        row-gap: 2.4rem; } }
    @media screen and (max-width: 860px) {
      .popup-search-result-list {
        row-gap: 2rem; } }
    @media screen and (max-width: 600px) {
      .popup-search-result-list {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 460px) {
      .popup-search-result-list {
        row-gap: 1.2rem; } }
  .popup-search-result-item .post_link--rt-wrap .title-mn {
    font-size: 1.8rem; }
    @media only screen and (max-width: 1200px) {
      .popup-search-result-item .post_link--rt-wrap .title-mn {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .popup-search-result-item .post_link--rt-wrap .title-mn {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 600px) {
      .popup-search-result-item .post_link--rt-wrap .title-mn {
        font-size: 1.4rem; } }
  .popup-search-result-item .post_link--rt-wrap .note-text {
    font-size: 1.4rem; }
  .popup-search-result-item .post_link--lf-img {
    padding-top: calc(100% * 138 / 211); }

.select2-container {
  height: 2.4rem;
  background-color: transparent; }

.select2.select2-container {
  width: 100% !important; }

.selection {
  width: 100%;
  height: 100%;
  display: block; }

.select2-container--default .select2-selection--single {
  border: none;
  color: white;
  height: 2.4rem;
  background: #ffffff;
  border-radius: 0rem !important;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  position: absolute; }
  .select2-container--default .select2-selection--single .select2-selection__arrow::before {
    content: "";
    position: absolute;
    top: 60%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 1rem;
    height: 1rem;
    background-image: url(../assets/images/arrDownSl.svg);
    background-repeat: no-repeat;
    background-size: 100%; }

.select2.select2-container .select2-selection--multiple {
  height: auto;
  min-height: 34px; }

.select2-container .select2-selection--single .select2-selection__rendered {
  background-color: transparent;
  color: #393d48;
  opacity: 1;
  font-size: 1.6rem;
  font-weight: 700;
  display: inline !important;
  width: 100%;
  padding: 0 2.4rem 0 0rem; }
  @media only screen and (max-width: 1200px) {
    .select2-container .select2-selection--single .select2-selection__rendered {
      font-size: 1.4rem; } }

.select2.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: block;
  padding: 0 4px; }

.select2-container .select2-dropdown {
  background: transparent;
  border: none;
  top: 0.8rem;
  border-radius: 0rem;
  overflow: hidden;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3); }

.select2-container .select2-dropdown .select2-search {
  padding: 0; }

.select2-container .select2-dropdown .select2-search input {
  outline: none !important;
  border: none;
  border-bottom: none !important;
  padding: 10px 6px !important;
  background-color: #000000;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul {
  background: #ffffff;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  background-color: #8146A3;
  font-size: 1.6rem; }
  @media screen and (max-width: 800px) {
    .select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
      font-size: 1.4rem; } }
  @media screen and (max-width: 460px) {
    .select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
      font-size: 1.2rem; } }

.select2-container--default .select2-results__option--selected {
  background-color: transparent;
  color: #000000;
  font-size: 1.6rem;
  font-weight: 400; }
  @media screen and (max-width: 800px) {
    .select2-container--default .select2-results__option--selected {
      font-size: 1.4rem; } }
  @media screen and (max-width: 460px) {
    .select2-container--default .select2-results__option--selected {
      font-size: 1.2rem; } }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  border-color: #000 transparent transparent transparent;
  right: 0rem; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #c4bbbc; }

.select2-search.select2-search--dropdown {
  display: none !important; }

.select2-results__option {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 0.4rem 1rem; }
  @media screen and (max-width: 800px) {
    .select2-results__option {
      font-size: 1.4rem; } }
  @media screen and (max-width: 460px) {
    .select2-results__option {
      font-size: 1.2rem; } }

.select2-results {
  display: block;
  border-radius: 0 !important;
  overflow: hidden; }

.re-select-wrap.white .select2-selection__rendered {
  color: #a5a5a5 !important; }

.select2-dropdown {
  z-index: 4000 !important; }

body:has(.seclect2-seach-show) .select2-search.select2-search--dropdown {
  display: block !important;
  background: #ffffff;
  padding: 1.2rem 2.4rem 0.4rem; }

body:has(.seclect2-seach-show) .select2-container .select2-dropdown .select2-search input {
  background-color: rgba(217, 217, 217, 0.2); }

body:has(.seclect2-seach-show) .select2-dropdown {
  box-shadow: 0rem 0.4rem 4rem rgba(0, 0, 0, 0.1);
  margin-top: 2.2rem; }

body:has(.seclect2-seach-show) .select2-results__option {
  padding: 0.4rem 1rem;
  font-size: 1.2rem; }

body:has(.seclect2-seach-show) .select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  font-size: 1.2rem;
  line-height: 1.7;
  color: white; }

.menu-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 1.2rem; }
  @media screen and (max-width: 1400px) {
    .menu-list {
      row-gap: 0.8rem; } }

.menu-img {
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .menu-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media screen and (max-width: 1400px) {
    .menu-img {
      width: 2rem;
      height: 2rem; } }

.menu-ic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.8rem;
  width: 2.4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .menu-ic.actived i {
    transform: rotate(-180deg); }
  .menu-ic i {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
    color: #393D48;
    transition: all 0.3s linear; }

.menu-item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  row-gap: 1.2rem; }
  .menu-item.current-menu-item > .menu-link {
    border-color: #3C214C !important;
    color: #3C214C !important; }
    .menu-item.current-menu-item > .menu-link .note-text {
      color: #3C214C !important; }
    .menu-item.current-menu-item > .menu-link .menu-ic i {
      color: #3C214C !important; }
    .menu-item.current-menu-item > .menu-link::before {
      width: 100%; }
  @media screen and (max-width: 1400px) {
    .menu-item {
      row-gap: 0.8rem; } }
  .menu-item > .menu-list {
    width: 100%;
    height: unset;
    z-index: 2;
    display: none;
    transition: none !important;
    margin: 0; }
    .menu-item > .menu-list .menu-item {
      height: unset; }
      .menu-item > .menu-list .menu-item:not(:last-child) {
        margin-bottom: 1.2rem; }
      .menu-item > .menu-list .menu-item.current-menu-item > .menu-link {
        border-color: #3C214C !important;
        color: #3C214C !important; }
        .menu-item > .menu-list .menu-item.current-menu-item > .menu-link .note-text {
          color: #3C214C !important; }
        .menu-item > .menu-list .menu-item.current-menu-item > .menu-link .menu-ic i {
          color: #3C214C !important; }
        .menu-item > .menu-list .menu-item.current-menu-item > .menu-link::before {
          width: 100%; }
    .menu-item > .menu-list .menu-link {
      width: 100%;
      padding: 0.8rem 0.8rem 0.8rem 4.8rem;
      font-weight: 400;
      font-size: 1.5rem; }
      @media screen and (max-width: 1400px) {
        .menu-item > .menu-list .menu-link {
          padding: 0.8rem 0.8rem 0.8rem 4.2rem;
          font-size: 1.4rem; } }
      @media screen and (max-width: 800px) {
        .menu-item > .menu-list .menu-link {
          padding: 0.8rem 0.8rem 0.8rem 3.3rem; } }
  @media (min-width: 1201px) {
    .menu-item:hover > .menu-link {
      border-color: #3C214C !important;
      color: #3C214C !important; }
      .menu-item:hover > .menu-link .note-text {
        color: #3C214C !important; }
      .menu-item:hover > .menu-link .menu-ic i {
        color: #3C214C !important; }
      .menu-item:hover > .menu-link::before {
        width: 100%; } }

.menu-link {
  column-gap: 1.6rem;
  color: #393D48;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  height: 100%;
  width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
  border-left: 0.2rem solid transparent !important; }
  .menu-link::before {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 0rem;
    width: 0;
    height: 100%;
    border-radius: 0.4rem;
    background: #D9D9D9;
    transition: all 0.3s linear;
    z-index: -1; }
  .menu-link::after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 0rem;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    background: #D9D9D9;
    transition: all 0.3s linear;
    z-index: -1;
    opacity: 0; }
  .menu-link .note-text {
    transform: translateY(0.1rem); }

.menu-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1999; }
  .menu-modal.actived {
    opacity: 1;
    visibility: visible; }

.social-mb-list {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  column-gap: 1.2rem;
  padding: 0 0.6rem; }

.social-mb-item {
  display: flex;
  width: fit-content;
  justify-content: center; }

.social-mb-link {
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;
  display: block;
  border-radius: 50%;
  overflow: hidden; }
  .social-mb-link img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%; }

.menu-lh {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1rem;
  padding: 0 0.6rem; }
  .menu-lh .note-title {
    padding: 0 0.6rem;
    font-weight: 700; }
  .menu-lh-title {
    padding: 0 0.6rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: #8146A3;
    line-height: 1.5;
    text-transform: uppercase; }
    @media only screen and (max-width: 1200px) {
      .menu-lh-title {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .menu-lh-title {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 600px) {
      .menu-lh-title {
        font-size: 1.4rem; } }

.menu-mxh {
  padding: 2rem 0.6rem 1rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.3);
  border-top: 0.1rem solid rgba(0, 0, 0, 0.3); }

.menu-nav {
  position: sticky;
  top: 0;
  width: 9.6rem;
  left: 0;
  height: 100vh;
  background: #ffffff;
  z-index: 1000;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);
  padding: 0 2.4rem;
  padding-bottom: 1.2rem;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
  @media screen and (max-width: 1400px) {
    .menu-nav {
      padding: 0 1.2rem;
      width: 7.2rem; } }
  @media screen and (max-width: 1200px) {
    .menu-nav {
      position: fixed; } }
  @media screen and (max-width: 800px) {
    .menu-nav {
      left: -100%;
      pointer-events: none;
      visibility: hidden;
      transition: all 0.5s linear;
      height: 100%; } }
  .menu-nav-overflow {
    width: 100%;
    height: calc(100% - 1.2rem);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

@keyframes anHienSl {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }
  @media (min-width: 1201px) {
    .menu-nav:hover {
      width: 30rem; } }
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    .menu-nav:hover {
      width: 26rem; } }
  @media (min-width: 1201px) {
      .menu-nav:hover .menu-nav-top .title-sm {
        display: block;
        animation: anHienSl 1s linear forwards; }
      .menu-nav:hover .menu-nav-center .menu-link .note-text {
        display: -webkit-box;
        animation: anHienSl 1s linear forwards; }
      .menu-nav:hover .menu-nav-center .menu-link .menu-ic {
        display: flex;
        animation: anHienSl 1s linear forwards; }
      .menu-nav:hover .menu-nav-sign-info .note-text {
        display: block;
        animation: anHienSl 1s linear forwards; }
      .menu-nav:hover .menu-nav-sign .menu-img {
        display: none; }
      .menu-nav:hover .menu-nav-sign-btn {
        display: flex;
        animation: anHienSl 1s linear 0.2s forwards; }
      .menu-nav:hover .menu-nav-bottom .menu-img {
        display: none; }
      .menu-nav:hover .menu-nav-bottom-wrap {
        display: flex;
        animation: anHienSl 1s linear 0.2s forwards; } }
  .menu-nav.actived {
    width: 30rem;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
    @media screen and (max-width: 800px) {
      .menu-nav.actived {
        pointer-events: visible;
        visibility: visible;
        left: 0;
        width: 45%;
        transition: all 0.5s linear; } }
    @media screen and (max-width: 600px) {
      .menu-nav.actived {
        width: 60%; } }
    @media screen and (max-width: 460px) {
      .menu-nav.actived {
        width: 80%; } }
    @media screen and (max-width: 360px) {
      .menu-nav.actived {
        width: 90%; } }
    .menu-nav.actived ~ .menu-nav-modal {
      opacity: 1;
      visibility: visible;
      pointer-events: visible; }
    .menu-nav.actived .menu-nav-top .title-sm {
      display: block;
      animation: anHienSl 1s linear forwards; }
    .menu-nav.actived .menu-nav-center .menu-link .note-text {
      display: -webkit-box;
      animation: anHienSl 1s linear forwards; }
    .menu-nav.actived .menu-nav-center .menu-link .menu-ic {
      display: flex;
      animation: anHienSl 1s linear forwards; }
    .menu-nav.actived .menu-nav-sign-info .note-text {
      display: block;
      animation: anHienSl 1s linear forwards; }
    .menu-nav.actived .menu-nav-sign .menu-img {
      display: none; }
    .menu-nav.actived .menu-nav-sign-btn {
      display: flex;
      animation: anHienSl 1s linear 0.2s forwards; }
    .menu-nav.actived .menu-nav-bottom .menu-img {
      display: none; }
    .menu-nav.actived .menu-nav-bottom-wrap {
      display: flex;
      animation: anHienSl 1s linear 0.2s forwards; }
  .menu-nav-modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear; }
  .menu-nav-control {
    display: none;
    position: absolute;
    top: 50%;
    right: -2.8rem;
    transform: translateY(-50%);
    z-index: 5; }
    @media screen and (max-width: 1200px) {
      .menu-nav-control {
        display: block; } }
    .menu-nav-control-btn {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8146A3;
      z-index: 5;
      cursor: pointer;
      transition: all 0.3s linear; }
      .menu-nav-control-btn i {
        font-size: 1.6rem;
        color: #ffffff; }
      .menu-nav-control-btn.actived {
        transform: rotate(-180deg); }
      @media screen and (max-width: 800px) {
        .menu-nav-control-btn {
          transform: rotate(-180deg) !important; } }
  .menu-nav-top {
    display: flex;
    align-items: center;
    column-gap: 1.6rem;
    width: 100%;
    position: relative;
    transition: all 0.3s linear;
    height: 8.4rem;
    flex-shrink: 0;
    overflow: hidden; }
    @media screen and (max-width: 600px) {
      .menu-nav-top {
        height: 6rem; } }
    @media screen and (max-width: 1400px) {
      .menu-nav-top {
        column-gap: 0.8rem; } }
    .menu-nav-top .title-sm {
      transition: all 0.3s linear;
      line-height: 100%;
      display: none;
      opacity: 0; }
      @media screen and (max-width: 1400px) {
        .menu-nav-top .title-sm {
          font-size: 3rem; } }
    @media (min-width: 1201px) {
      .menu-nav-top:hover .menu-nav-logo::before {
        left: 180%;
        opacity: 1;
        transition: left 0.5s linear, top 0.5s linear, opacity 0.3s ease; }
      .menu-nav-top:hover .menu-nav-logo img {
        transform: scale(1.2); }
      .menu-nav-top:hover .title-sm {
        color: #393D48; } }
    .menu-nav-top::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.1rem;
      background: rgba(0, 0, 0, 0.1);
      bottom: 0;
      left: 0rem; }
  .menu-nav-logo {
    display: flex;
    width: 4.8rem;
    height: 4.8rem;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    transition: all 0.3s linear; }
    @media screen and (max-width: 600px) {
      .menu-nav-logo {
        width: 4rem;
        height: 4rem; } }
    .menu-nav-logo::before {
      content: "";
      top: 50%;
      left: -120%;
      position: absolute;
      transform: translate(-50%, -50%) rotate(-55deg);
      width: 250%;
      height: 50%;
      background: rgba(255, 255, 255, 0.2);
      z-index: 1;
      opacity: 0.3; }
    .menu-nav-logo img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease; }
  .menu-nav-lh {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem; }
    @media screen and (max-width: 1200px) {
      .menu-nav-lh {
        row-gap: 1rem; } }
    @media screen and (max-width: 860px) {
      .menu-nav-lh {
        row-gap: 0.8rem; } }
    .menu-nav-lh .btn {
      width: 100%; }
  .menu-nav-center {
    position: relative;
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    flex-shrink: 0;
    width: 100%; }
    @media screen and (max-width: 1600px) {
      .menu-nav-center {
        max-height: 36vh; } }
    @media screen and (max-width: 1200px) {
      .menu-nav-center {
        max-height: 45vh; } }
    @media screen and (max-width: 600px) {
      .menu-nav-center {
        max-height: 40vh; } }
    @media screen and (max-width: 460px) {
      .menu-nav-center {
        max-height: 40vh; } }
    .menu-nav-center .menu-list {
      height: 100%;
      width: 100%;
      margin-left: 0;
      margin-right: 0; }
    .menu-nav-center .menu-item {
      height: unset;
      justify-content: unset;
      align-items: unset; }
    .menu-nav-center .menu-link {
      height: unset;
      flex-shrink: 0;
      width: 100%;
      min-height: 4.2rem;
      padding-left: 1rem;
      align-items: center;
      border-left: 0;
      position: relative;
      left: 0;
      transition: all 0.3s ease 0.1s; }
      @media screen and (max-width: 1400px) {
        .menu-nav-center .menu-link {
          column-gap: 0.8rem; } }
      @media screen and (max-width: 1200px) {
        .menu-nav-center .menu-link {
          min-height: 3.8rem;
          padding-left: 1.2rem; } }
      @media screen and (max-width: 800px) {
        .menu-nav-center .menu-link {
          min-height: 3.8rem;
          padding-left: 0.5rem; } }
      .menu-nav-center .menu-link .menu-ic {
        display: none; }
      .menu-nav-center .menu-link .menu-img {
        transition: all 0.3s linear; }
      .menu-nav-center .menu-link .note-text {
        width: 100%;
        display: none;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        opacity: 0; }
        @media screen and (max-width: 1400px) {
          .menu-nav-center .menu-link .note-text {
            font-size: 1.4rem; } }
  .menu-nav-bottom {
    padding: 1.6rem 0;
    position: relative;
    flex-shrink: 0;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center; }
    .menu-nav-bottom::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.1rem;
      background: rgba(0, 0, 0, 0.1);
      top: 0;
      left: 0; }
    .menu-nav-bottom-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      width: 100%;
      display: none;
      opacity: 0; }
      @media screen and (max-width: 1200px) {
        .menu-nav-bottom-wrap {
          row-gap: 1.6rem; } }
      @media screen and (max-width: 860px) {
        .menu-nav-bottom-wrap {
          row-gap: 1.4rem; } }
      @media screen and (max-width: 600px) {
        .menu-nav-bottom-wrap {
          row-gap: 1.2rem; } }
      @media screen and (max-width: 460px) {
        .menu-nav-bottom-wrap {
          row-gap: 1rem; } }
    .menu-nav-bottom .menu-img {
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center; }
      .menu-nav-bottom .menu-img img {
        width: 2.4rem;
        height: 2.4rem; }
        @media screen and (max-width: 1400px) {
          .menu-nav-bottom .menu-img img {
            width: 2rem;
            height: 2rem; } }
  .menu-nav-social {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem; }
    @media screen and (max-width: 1200px) {
      .menu-nav-social {
        row-gap: 1rem; } }
    @media screen and (max-width: 860px) {
      .menu-nav-social {
        row-gap: 0.8rem; } }
  .menu-nav-sign {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.2rem;
    width: 100%; }
    .menu-nav-sign .menu-img {
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center; }
      .menu-nav-sign .menu-img img {
        width: 2.4rem;
        height: 2.4rem; }
        @media screen and (max-width: 1400px) {
          .menu-nav-sign .menu-img img {
            width: 2rem;
            height: 2rem; } }
    .menu-nav-sign-btn {
      width: 100%;
      display: none;
      opacity: 0; }
      .menu-nav-sign-btn .btn {
        width: 100%; }
        .menu-nav-sign-btn .btn.showed {
          display: flex !important; }
        .menu-nav-sign-btn .btn.hiddend {
          display: none !important; }
        .menu-nav-sign-btn .btn.red {
          display: none; }
    .menu-nav-sign-img {
      width: 4.8rem;
      height: 4.8rem;
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s linear; }
      @media screen and (max-width: 1200px) {
        .menu-nav-sign-img {
          width: 4rem;
          height: 4rem; } }
      @media screen and (max-width: 800px) {
        .menu-nav-sign-img {
          width: 3.2rem;
          height: 3.2rem; } }
      .menu-nav-sign-img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .menu-nav-sign-info {
      display: flex;
      align-items: center;
      column-gap: 1.6rem;
      position: relative;
      flex-shrink: 0;
      height: 4.8rem;
      width: 100%;
      display: none; }
      @media screen and (max-width: 1200px) {
        .menu-nav-sign-info {
          height: 4rem;
          column-gap: 1.4rem; } }
      @media screen and (max-width: 800px) {
        .menu-nav-sign-info {
          height: 3.2rem;
          column-gap: 1.2rem; } }
      @media screen and (max-width: 600px) {
        .menu-nav-sign-info {
          column-gap: 1rem; } }
      @media screen and (max-width: 460px) {
        .menu-nav-sign-info {
          column-gap: 0.8rem; } }
      .menu-nav-sign-info.showed {
        display: flex; }
      .menu-nav-sign-info .note-text {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: none;
        width: 100%;
        transition: all 0.3s linear;
        opacity: 0;
        white-space: nowrap; }
  .menu-nav-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    width: 100%;
    flex-shrink: 0;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .menu-nav-wrap {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 860px) {
      .menu-nav-wrap {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 600px) {
      .menu-nav-wrap {
        row-gap: 1rem; } }

.social-list {
  display: flex;
  gap: 0.8rem; }
  @media screen and (max-width: 800px) {
    .social-list {
      flex-wrap: wrap; } }

.social-link {
  display: flex;
  width: 3.8rem;
  height: 3.8rem;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .social-link {
      width: 3.2rem;
      height: 3.2rem; } }
  @media (min-width: 1201px) {
    .social-link:hover {
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5); }
      .social-link:hover .social-img img {
        transform: scale(1.2); } }

.social-img {
  width: 100%;
  height: 100%; }
  .social-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s linear; }

.breadcrumbs {
  overflow: hidden;
  width: 100%; }
  @media screen and (max-width: 1201px) {
    .breadcrumbs-wrap {
      padding: 1rem 0; } }
  .breadcrumbs-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    column-gap: 1.2rem; }
  .breadcrumbs-item {
    position: relative;
    height: 2.4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .breadcrumbs-item.current a {
      color: #8146A3 !important;
      pointer-events: none;
      font-weight: 700;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box; }
    .breadcrumbs-item:last-child {
      padding: 0 0.05rem; }
    .breadcrumbs-item:not(:last-child)::before {
      content: "/";
      color: #393D48;
      position: absolute;
      background-repeat: no-repeat;
      right: -1rem; }
    .breadcrumbs-item a {
      font-size: 1.6rem;
      line-height: 1.5;
      color: #393D48;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      letter-spacing: 0.005em; }
      .breadcrumbs-item a img {
        width: 1.4rem; }
      @media screen and (min-width: 1201px) {
        .breadcrumbs-item a:hover {
          color: #393D48 !important; } }
      @media screen and (max-width: 600px) {
        .breadcrumbs-item a {
          font-size: 1.2rem; } }
    .breadcrumbs-item.active a {
      opacity: 1; }
      @media screen and (min-width: 1201px) {
        .breadcrumbs-item.active a:hover {
          color: #000000; } }
    .breadcrumbs-item:not(:last-child) a {
      width: max-content; }
  .breadcrumbs.pd-t {
    padding-top: 1.5rem; }
  .breadcrumbs.other {
    width: 100%; }
    .breadcrumbs.other a {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.5;
      color: #ffffff; }
    .breadcrumbs.other span {
      color: white; }
    .breadcrumbs.other .breadcrumbs-list {
      position: relative;
      height: 100%;
      padding: 0; }
    .breadcrumbs.other .breadcrumbs-item a {
      font-size: 1.6rem; }
    .breadcrumbs.other .breadcrumbs-item:not(:last-child)::before {
      content: "/";
      position: absolute;
      color: white;
      z-index: 2;
      right: -1rem; }
    .breadcrumbs.other .breadcrumbs-item.current a {
      color: #393D48 !important;
      pointer-events: none;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .breadcrumbsSec.section {
    padding-top: var(--pdGen);
    overflow: hidden; }

.box-breadcrumbs {
  background: #8146A3;
  height: 12.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column; }
  .box-breadcrumbs-wrap {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    justify-content: center; }
    @media screen and (max-width: 1200px) {
      .box-breadcrumbs-wrap {
        row-gap: 0.2rem; } }
    .box-breadcrumbs-wrap::before {
      content: "";
      background-image: url(../assets/images/icBr.svg);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -2rem;
      width: 10rem;
      height: 10rem;
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 1;
      pointer-events: none; }
      @media screen and (max-width: 1200px) {
        .box-breadcrumbs-wrap::before {
          width: 6rem;
          height: 6rem;
          left: -1rem; } }
    .box-breadcrumbs-wrap .title-sm {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
      @media screen and (max-width: 600px) {
        .box-breadcrumbs-wrap .title-sm {
          font-size: 2rem; } }
  @media screen and (max-width: 1200px) {
    .box-breadcrumbs {
      height: 8rem; } }

.breadcrumbs-inside {
  position: relative; }
  .breadcrumbs-inside .breadcrumbs {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0; }
  .breadcrumbs-inside .breadcrumbs.other .breadcrumbs-list::before {
    display: none; }

.breadcrumbs-white .breadcrumbs.other a {
  color: white; }
  @media (min-width: 1201px) {
    .breadcrumbs-white .breadcrumbs.other a:hover {
      color: #D22522; } }

.breadcrumbs-white .breadcrumbs-item:not(:last-child)::before {
  filter: brightness(0) invert(1); }

[data-aos="fade-up-cus"] {
  transform: translate(0, 4rem);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-up-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-down-cus"] {
  transform: translate(0, -4rem);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-down-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-left-cus"] {
  transform: translate(4rem, 0);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-left-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-right-cus"] {
  transform: translate(-4rem, 0);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-right-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

.banner-main {
  height: calc(100vw * 483 / 1620);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 100%, 60% 85%, 0 85%, 0 100%);
  opacity: 0;
  position: relative;
  animation: hinhChay 1s linear forwards 1s; }

@keyframes hinhChay {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }
  @media screen and (max-width: 1200px) {
    .banner-main {
      margin-bottom: var(--size-pdl-tl);
      height: calc(100vw * 483 / 1200);
      clip-path: none; } }
  @media screen and (max-width: 600px) {
    .banner-main {
      margin-bottom: var(--size-pdl-mb); } }
  .banner-main-bg {
    width: 100%;
    height: 100%; }
  .banner-main-img {
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .banner-main-img img {
      width: 100%;
      height: 140%;
      object-fit: cover;
      object-position: 50% 50%; }
      @media screen and (max-width: 1200px) {
        .banner-main-img img {
          height: 100%; } }

.banner-slide {
  width: 100%;
  height: 100%;
  position: relative; }
  .banner-slide.sp .banner-slide-item {
    height: 100%;
    min-height: 58rem; }
    @media screen and (max-width: 1200px) {
      .banner-slide.sp .banner-slide-item {
        min-height: 40rem; } }
    @media screen and (max-width: 800px) {
      .banner-slide.sp .banner-slide-item {
        min-height: 35rem; } }
    @media screen and (max-width: 600px) {
      .banner-slide.sp .banner-slide-item {
        min-height: 30rem; } }
  .banner-slide.sp .banner-slide-content {
    max-width: 64rem; }
  .banner-slide.sp .banner-slide-wrap {
    padding-top: 14rem;
    padding-bottom: 14rem; }
    @media screen and (max-width: 1200px) {
      .banner-slide.sp .banner-slide-wrap {
        padding-top: 12rem;
        padding-bottom: 12rem; } }
    @media screen and (max-width: 800px) {
      .banner-slide.sp .banner-slide-wrap {
        padding-top: 10rem;
        padding-bottom: 10rem; } }
    @media screen and (max-width: 600px) {
      .banner-slide.sp .banner-slide-wrap {
        padding-top: 8rem;
        padding-bottom: 8rem; } }
  .banner-slide .breadcrumbs {
    position: absolute;
    top: 2.4rem;
    z-index: 2;
    width: 100%; }
  .banner-slide .swiper-pagination {
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0; }
    @media screen and (max-width: 1200px) {
      .banner-slide .swiper-pagination {
        bottom: 2.5rem; } }
    @media screen and (max-width: 600px) {
      .banner-slide .swiper-pagination {
        bottom: 2rem; } }
  .banner-slide .swiper-slide {
    height: auto; }
  .banner-slide .swiper-slide-active .banner-slide-wrap {
    clip-path: inset(0 0 0 0); }
  .banner-slide-item {
    height: 100%;
    min-height: 82rem; }
    @media screen and (max-width: 1200px) {
      .banner-slide-item {
        min-height: 60rem; } }
    @media screen and (max-width: 800px) {
      .banner-slide-item {
        min-height: 40rem; } }
    @media screen and (max-width: 600px) {
      .banner-slide-item {
        min-height: 30rem; } }
  .banner-slide-img {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0; }
    .banner-slide-img::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 59.42%, rgba(0, 0, 0, 0.2) 71.55%), linear-gradient(90deg, rgba(0, 0, 0, 0.6) 20.82%, rgba(0, 0, 0, 0) 64.84%);
      z-index: 1; }
      @media screen and (max-width: 1200px) {
        .banner-slide-img::before {
          background: rgba(0, 0, 0, 0.5); } }
    .banner-slide-img img {
      width: 100%;
      height: 100% !important;
      object-fit: cover; }
  .banner-slide-wrap {
    padding-top: 19rem;
    padding-bottom: 27.5rem;
    position: relative;
    z-index: 2;
    clip-path: inset(0 100% 0 0);
    transition: all 3s ease; }
    @media screen and (max-width: 1200px) {
      .banner-slide-wrap {
        padding-top: 15rem;
        padding-bottom: 20rem; } }
    @media screen and (max-width: 800px) {
      .banner-slide-wrap {
        padding-top: 15rem;
        padding-bottom: 15rem; } }
    @media screen and (max-width: 600px) {
      .banner-slide-wrap {
        padding-top: 12rem;
        padding-bottom: 12rem; } }
  .banner-slide-content {
    max-width: 54rem;
    width: 100%; }
    .banner-slide-content .title-lg {
      font-family: "Red Rose";
      line-height: 1.2;
      margin-bottom: 1.2rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .banner-slide-content .note-text {
      color: #f7f5f5;
      margin-bottom: 3rem;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden; }

.banner_home {
  overflow: hidden;
  padding-top: var(--size-pdl);
  padding-bottom: var(--size-pd); }
  @media screen and (max-width: 1200px) {
    .banner_home {
      padding-top: var(--size-pdl-tl);
      padding-bottom: var(--size-pd-tl); } }
  @media screen and (max-width: 1024px) {
    .banner_home {
      padding-top: 2rem; } }
  @media screen and (max-width: 800px) {
    .banner_home {
      padding-top: 2rem; } }
  @media screen and (max-width: 600px) {
    .banner_home {
      padding-top: 1rem;
      padding-bottom: var(--size-pd-mb); } }
  .banner_home--wrap {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .banner_home--wrap {
        row-gap: 2rem; } }
    .banner_home--wrap .swiper-pagination {
      position: absolute;
      top: 10rem;
      left: 8%;
      width: 4rem;
      flex-direction: column;
      align-items: center;
      row-gap: 3rem;
      margin-top: 0; }
      @media screen and (max-width: 1600px) {
        .banner_home--wrap .swiper-pagination {
          left: 4%; } }
      @media screen and (max-width: 1500px) {
        .banner_home--wrap .swiper-pagination {
          left: 3%; } }
      @media screen and (max-width: 1400px) {
        .banner_home--wrap .swiper-pagination {
          left: 2%; } }
      @media screen and (max-width: 1350px) {
        .banner_home--wrap .swiper-pagination {
          left: 1%; } }
      @media screen and (max-width: 1024px) {
        .banner_home--wrap .swiper-pagination {
          left: 1%;
          top: 12rem; } }
      @media screen and (max-width: 600px) {
        .banner_home--wrap .swiper-pagination {
          position: relative;
          flex-direction: row;
          top: unset;
          column-gap: 2rem;
          padding-top: 1rem;
          padding-left: 1.2rem;
          padding-right: 1.2rem;
          width: fit-content;
          display: none; } }
      .banner_home--wrap .swiper-pagination-bullet {
        width: fit-content;
        background: transparent;
        font-family: "Big Shoulders Display";
        font-weight: 700;
        color: #393D48;
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.8s ease-in-out; }
        .banner_home--wrap .swiper-pagination-bullet:not(:last-child)::before {
          content: " ";
          position: absolute;
          top: 34.5%;
          left: 50%;
          transform: translateX(-50%);
          width: 0.1rem;
          height: 0%;
          background: #393D48;
          opacity: 0;
          transition: all 0.5s linear; }
          @media screen and (max-width: 600px) {
            .banner_home--wrap .swiper-pagination-bullet:not(:last-child)::before {
              display: none; } }
        .banner_home--wrap .swiper-pagination-bullet:last-child::before {
          content: " ";
          position: absolute;
          bottom: 34.5%;
          left: 50%;
          transform: translateX(-50%);
          width: 0.1rem;
          height: 0%;
          background: #393D48;
          opacity: 0;
          transition: all 0.5s linear; }
          @media screen and (max-width: 600px) {
            .banner_home--wrap .swiper-pagination-bullet:last-child::before {
              display: none; } }
      .banner_home--wrap .swiper-pagination-bullet-active {
        font-size: 3.6rem;
        color: #8146A3;
        padding-bottom: 6rem; }
        @media only screen and (max-width: 1200px) {
          .banner_home--wrap .swiper-pagination-bullet-active {
            font-size: 2.6rem; } }
        @media screen and (max-width: 600px) {
          .banner_home--wrap .swiper-pagination-bullet-active {
            padding: 0 !important; } }
        .banner_home--wrap .swiper-pagination-bullet-active:last-child {
          padding-top: 6rem;
          padding-bottom: 0; }
        .banner_home--wrap .swiper-pagination-bullet-active::before {
          height: 100% !important;
          opacity: 1 !important; }
  .banner_home--top-wrap {
    width: calc(100% * 8 / 12);
    position: relative;
    z-index: 5; }
    @media screen and (max-width: 1350px) {
      .banner_home--top-wrap {
        padding-left: 6rem; } }
    @media screen and (max-width: 1024px) {
      .banner_home--top-wrap {
        width: 100%;
        padding-left: 5rem; } }
    @media screen and (max-width: 800px) {
      .banner_home--top-wrap {
        padding-left: 4rem; } }
    @media screen and (max-width: 600px) {
      .banner_home--top-wrap {
        padding-left: 0rem; } }
  .banner_home--top-list {
    width: 100%;
    height: 100%; }
    .banner_home--top-list .swiper {
      padding: 1rem 0; }
    .banner_home--top-list .swiper-slide {
      transition: none !important;
      height: auto;
      user-select: none; }
    .banner_home--top-list .swiper-slide-active .banner_home--top-item {
      animation: luotLenBh 1s ease-in-out forwards; }
      .banner_home--top-list .swiper-slide-active .banner_home--top-item .title-lg {
        animation: luotLenBh 1s ease-in-out forwards; }
      .banner_home--top-list .swiper-slide-active .banner_home--top-item .note-lg {
        animation: luotLenBh 0.8s ease-in-out forwards; }
      .banner_home--top-list .swiper-slide-active .banner_home--top-item .note-text {
        animation: luotLenBh 0.6s ease-in-out forwards; }
      .banner_home--top-list .swiper-slide-active .banner_home--top-item .btn {
        animation: luotLenBh 0.4s ease-in-out forwards; }
  .banner_home--top-item {
    height: 100%; }

@keyframes luotLenBh {
  0% {
    transform: translateY(30%);
    opacity: 0; }
  0% {
    transform: translateY(15%); }
  100% {
    transform: translateY(0%);
    opacity: 1; } }

@keyframes truotText {
  0% {
    clip-path: inset(0 100% 0 0);
    opacity: 0; }
  0% {
    clip-path: inset(0 50% 0 0);
    opacity: 0.5; }
  100% {
    clip-path: inset(0 0 0 0);
    opacity: 1; } }
    .banner_home--top-item .title-lg {
      margin-bottom: 0.3rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .banner_home--top-item .note-lg {
      margin-bottom: 1.6rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
      @media screen and (max-width: 1200px) {
        .banner_home--top-item .note-lg {
          margin-bottom: 1.4rem; } }
    .banner_home--top-item .note-text {
      max-width: 40rem;
      display: -webkit-box;
      margin-bottom: 4rem;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
      @media screen and (max-width: 1200px) {
        .banner_home--top-item .note-text {
          margin-bottom: 3rem; } }
      @media screen and (max-width: 800px) {
        .banner_home--top-item .note-text {
          margin-bottom: 2rem; } }
  .banner_home--bottom {
    position: relative;
    width: 100%; }
    .banner_home--bottom::before {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-image: url(../assets/images/bhbg.png);
      background-repeat: no-repeat;
      position: absolute;
      z-index: -1; }
    .banner_home--bottom-wrap {
      height: 100%; }
      @media screen and (max-width: 460px) {
        .banner_home--bottom-wrap {
          flex-direction: column; } }
    .banner_home--bottom-lf {
      width: calc(3 * 100% / 12); }
      @media screen and (max-width: 800px) {
        .banner_home--bottom-lf {
          width: calc(2 * 100% / 12); } }
      @media screen and (max-width: 768px) {
        .banner_home--bottom-lf {
          width: 100%; } }
      .banner_home--bottom-lf-wrap {
        padding-top: 22rem;
        padding-bottom: 7rem; }
        @media screen and (max-width: 1200px) {
          .banner_home--bottom-lf-wrap {
            padding-top: 16rem;
            padding-bottom: 4rem; } }
        @media screen and (max-width: 1024px) {
          .banner_home--bottom-lf-wrap {
            padding-top: 12rem;
            padding-bottom: 4rem; } }
        @media screen and (max-width: 900px) {
          .banner_home--bottom-lf-wrap {
            padding-top: 4rem;
            padding-bottom: 4rem; } }
        @media screen and (max-width: 800px) {
          .banner_home--bottom-lf-wrap {
            padding-top: 2rem;
            padding-bottom: 2rem; } }
        @media screen and (max-width: 460px) {
          .banner_home--bottom-lf-wrap {
            padding-top: 1.2rem;
            padding-bottom: 1.2rem; } }
        .banner_home--bottom-lf-wrap .box-navi {
          justify-content: start; }
    .banner_home--bottom-rt {
      width: 100%;
      flex: 1; }
      .banner_home--bottom-rt-wrap {
        width: 100%;
        height: 100%; }
      .banner_home--bottom-rt-list {
        width: 100%;
        height: 100%; }
        .banner_home--bottom-rt-list .swiper {
          overflow: visible; }
        .banner_home--bottom-rt-list .swiper-slide {
          height: auto; }
        .banner_home--bottom-rt-list .swiper-slide-active {
          opacity: 1; }
      .banner_home--bottom-rt-item {
        height: 100%;
        position: relative;
        width: 100%; }
      .banner_home--bottom-rt-box {
        width: calc((100vw - 120rem) / 2 + 100%);
        position: absolute;
        bottom: 0;
        right: calc((100vw - 120rem) / -2 - 6.4rem); }
        @media screen and (max-width: 1200px) {
          .banner_home--bottom-rt-box {
            right: -6.4rem; } }
        @media screen and (max-width: 768px) {
          .banner_home--bottom-rt-box {
            position: relative;
            bottom: 0;
            width: 100%; } }
        @media screen and (max-width: 460px) {
          .banner_home--bottom-rt-box {
            right: -3.2rem; } }
      .banner_home--bottom-rt-img {
        position: relative;
        padding-top: calc(100% * 640 / 1361);
        width: 100%;
        overflow: hidden; }
        .banner_home--bottom-rt-img img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          object-fit: cover; }

.links-main {
  position: fixed;
  bottom: 5rem;
  right: 3%;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 800;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 2rem; }
  @media screen and (max-width: 1200px) {
    .links-main {
      right: 1.5%; } }
  .links-main-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    pointer-events: none; }
    .links-main-list .links-main-item {
      cursor: pointer;
      position: relative; }
      @media (min-width: 1201px) {
        .links-main-list .links-main-item:hover .link-items img {
          transform: scale(1.1); }
        .links-main-list .links-main-item:hover .link-items-phone {
          width: 18rem; }
        .links-main-list .links-main-item:hover .link-items-phone-text {
          transform: translate(0%, 0%) scale(1);
          opacity: 1; } }
      .links-main-list .links-main-item .link-items-phone {
        position: absolute;
        display: flex;
        align-items: center;
        width: 0rem;
        height: 4rem;
        border-radius: 50%;
        top: -4rem;
        left: 0rem;
        transition: all 0.3s ease-in-out; }
        .links-main-list .links-main-item .link-items-phone-text {
          position: absolute;
          width: 100%;
          background-color: #393D48;
          border-radius: 10rem;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 1.5;
          letter-spacing: 0.02em;
          color: #ffffff;
          transition: all 0.3s ease-in-out;
          padding: 1rem 2rem 1rem 5rem;
          z-index: 4;
          opacity: 0;
          white-space: nowrap;
          left: -77.5%;
          padding: 1rem 4rem 1rem 2rem;
          transform: translate(20%, 0%) scale(0); }
        .links-main-list .links-main-item .link-items-phone-img {
          width: 4rem;
          height: 4rem;
          position: relative;
          z-index: 5;
          background-color: #393D48;
          border: 0.5rem solid #393D48;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0; }
          .links-main-list .links-main-item .link-items-phone-img::before {
            content: "";
            position: absolute;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.1rem solid #393D48;
            z-index: -1;
            opacity: 0;
            animation: phongTo 1s infinite linear backwards; }

@keyframes phongTo {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0; }
  40% {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: 0.4; }
  80% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.8; }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1; } }
          .links-main-list .links-main-item .link-items-phone-img::after {
            content: "";
            position: absolute;
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.1rem solid #393D48;
            z-index: -1;
            opacity: 0;
            animation: phongTo2 1s infinite linear backwards; }

@keyframes phongTo2 {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0; }
  30% {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: 0.4; }
  50% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.8; }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1; } }
          .links-main-list .links-main-item .link-items-phone-img img {
            width: 2.5rem;
            height: 2.5rem;
            position: relative;
            z-index: 10;
            animation: phoneRing 0.8s linear infinite; }
      .links-main-list .links-main-item .link-items {
        width: 4rem;
        height: 4rem;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center; }
        .links-main-list .links-main-item .link-items.pop {
          background-color: #393D48;
          display: none; }
          @media screen and (max-width: 1200px) {
            .links-main-list .links-main-item .link-items.pop {
              display: flex; } }
        .links-main-list .links-main-item .link-items i {
          color: #ffffff;
          font-size: 1.8rem; }
        .links-main-list .links-main-item .link-items img {
          transition: all 0.3s linear;
          z-index: 4;
          width: 100%;
          height: 100%; }
    .links-main-list.showed {
      display: flex;
      opacity: 1;
      visibility: visible;
      pointer-events: visible; }
    @media screen and (max-width: 1200px) {
      .links-main-list {
        margin-bottom: 0; } }
  .links-main.active {
    opacity: 1;
    visibility: visible;
    pointer-events: visible; }
  .links-main .btn-top.link-top {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
    transition: all 0.3s linear; }
    @media (min-width: 1201px) {
      .links-main .btn-top.link-top:hover {
        transform: translateY(-5%);
        background-color: #8146A3; }
        .links-main .btn-top.link-top:hover img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(139%) hue-rotate(56deg) brightness(112%) contrast(100%); } }
    @media screen and (max-width: 600px) {
      .links-main .btn-top.link-top {
        width: 3.2rem;
        height: 3.2rem; } }
    .links-main .btn-top.link-top img {
      width: 1.5rem;
      height: 1.5rem;
      transition: all 0.1s ease-in;
      animation: truotLenArr 1s linear infinite;
      filter: brightness(0) saturate(100%); }

@keyframes truotLenArr {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(10%); }
  100% {
    transform: translateY(0%); } }
  .links-main .btn-social {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #393D48;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    transition: all 0.3s linear; }
    @media (min-width: 1201px) {
      .links-main .btn-social:hover {
        background-color: #8146A3; }
        .links-main .btn-social:hover img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%); } }
    .links-main .btn-social i {
      color: white;
      animation: rungLac 1s ease-in-out infinite; }

@keyframes rungLac {
  0% {
    transform: translateX(0) rotate(0); }
  20% {
    transform: translateX(30%) rotate(3deg); }
  40% {
    transform: translateX(-30%) rotate(-3deg); }
  60% {
    transform: translateX(30%) rotate(3deg); }
  80% {
    transform: translateX(-30%) rotate(-3deg); }
  100% {
    transform: translateX(0%) rotate(0); } }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  column-gap: 0.8rem;
  height: 4.2rem;
  width: fit-content;
  padding: 0 2.4rem;
  min-width: 12rem;
  border-radius: 0.4rem;
  background: #4D2A62;
  transition: all 0.3s ease-in;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .btn {
      height: 4rem;
      padding: 0 1rem;
      min-width: 10rem; } }
  @media screen and (max-width: 600px) {
    .btn {
      height: 3.6rem;
      padding: 0 1rem;
      min-width: 10rem; } }
  @media (min-width: 1201px) {
    .btn:hover {
      transform: translate(-0.25rem, -0.25rem);
      box-shadow: 0.3rem 0.3rem rgba(0, 0, 0, 0.8);
      background: #f3e7f9; }
      .btn:hover .btn-text {
        color: #393d48; }
      .btn:hover .btn-ic i {
        color: #ffffff; } }
  .btn:active {
    transform: translate(0);
    box-shadow: none; }
  .btn.cl-2 {
    background: #393D48; }
    .btn.cl-2 .btn-text {
      color: #ffffff; }
    .btn.cl-2::before {
      background: #ffffff; }
    @media (min-width: 1201px) {
      .btn.cl-2:hover .btn-text {
        color: #8146A3; }
      .btn.cl-2:hover .btn-ic i {
        color: #8146A3; } }
  .btn.cl-3 {
    background: #ffffff; }
    .btn.cl-3 .btn-text {
      color: #8146A3; }
    .btn.cl-3 .btn-ic i {
      color: #8146A3; }
    .btn.cl-3::before {
      background: #393D48; }
    @media (min-width: 1201px) {
      .btn.cl-3:hover .btn-text {
        color: #ffffff; }
      .btn.cl-3:hover .btn-ic i {
        color: #ffffff; } }
  .btn.red {
    background: transparent;
    border: 0.1rem solid #ED1D24; }
    .btn.red .btn-text {
      color: #ED1D24; }
    @media (min-width: 1201px) {
      .btn.red:hover {
        background: #ED1D24; }
        .btn.red:hover .btn-text {
          color: #ffffff; } }
  .btn.trans {
    background: transparent;
    border: 0.1rem solid #4D2A62; }
    .btn.trans .btn-text {
      color: #4D2A62; }
    @media (min-width: 1201px) {
      .btn.trans:hover {
        background: #4D2A62; }
        .btn.trans:hover .btn-text {
          color: #ffffff; } }
  .btn.trans2 {
    background: transparent;
    border: 0.1rem solid #999; }
    .btn.trans2 .btn-text {
      color: #393d48; }
    @media (min-width: 1201px) {
      .btn.trans2:hover {
        background: #4D2A62;
        border: 0.1rem solid #4D2A62; }
        .btn.trans2:hover .btn-text {
          color: #ffffff; } }
  .btn.trans3 {
    background: transparent;
    border: 0.1rem solid #1d4ed8; }
    .btn.trans3 .btn-text {
      color: #1d4ed8; }
    @media (min-width: 1201px) {
      .btn.trans3:hover {
        background: #1d4ed8;
        border: 0.1rem solid #1d4ed8; }
        .btn.trans3:hover .btn-text {
          color: #ffffff; } }
  .btn-text {
    font-weight: 600;
    color: #ffffff;
    position: relative;
    z-index: 2;
    transition: all 0.3s linear;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem; }
    @media screen and (max-width: 800px) {
      .btn-text {
        font-size: 1.4rem; } }
  .btn-ic {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-shrink: 0; }
    .btn-ic i {
      font-size: 1.6rem;
      color: #ffffff;
      transition: all 0.3s linear; }
      @media screen and (max-width: 800px) {
        .btn-ic i {
          font-size: 1.4rem; } }
    .btn-ic img {
      width: 4.8rem;
      height: 4.8rem;
      flex-shrink: 0;
      object-fit: cover; }

.box-navi {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  column-gap: 1rem; }
  .box-navi .btn-navi {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    height: 4.8rem;
    background: #8146A3;
    z-index: 5;
    cursor: pointer;
    transition: all 0.3s linear; }
    @media screen and (max-width: 1200px) {
      .box-navi .btn-navi {
        width: 4rem;
        height: 4rem; } }
    @media screen and (max-width: 800px) {
      .box-navi .btn-navi {
        width: 3.4rem;
        height: 3.4rem; } }
    @media (min-width: 1201px) {
      .box-navi .btn-navi:hover {
        background: #393D48; } }
    .box-navi .btn-navi i {
      font-size: 2rem;
      color: #ffffff;
      transition: all 0.3s linear; }
      @media screen and (max-width: 1200px) {
        .box-navi .btn-navi i {
          font-size: 1.8rem; } }

.box-navi-pagi {
  display: flex;
  align-items: center;
  column-gap: 2.4rem;
  width: fit-content;
  padding: 0.8rem;
  margin-top: 3rem;
  margin-left: 1rem; }
  .box-navi-pagi.cl-2 .btn-navi-2 i {
    color: #f5d360; }
  .box-navi-pagi.cl-2 .swiper-pagination-bullet-active {
    background: #f5d360; }
  .box-navi-pagi .swiper-pagination {
    margin-top: 0; }
  .box-navi-pagi .btn-navi-2 {
    cursor: pointer; }
    .box-navi-pagi .btn-navi-2 i {
      font-size: 2rem;
      color: #8146A3; }

.page-pagi {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 4rem auto 0;
  column-gap: 0.5rem; }
  .page-pagi-wrap {
    display: flex;
    align-items: center;
    column-gap: 0.5rem; }

.number-pagi {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-weight: 600;
  border-radius: 50%;
  color: #393d48;
  transition: all 0.3s linear; }
  .number-pagi.current {
    color: #8146A3; }
  @media (min-width: 1201px) {
    .number-pagi:hover {
      color: #8146A3; } }
  .number-pagi-ic.prev {
    background-color: #8146A3;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
    transition: all 0.3s linear; }
    .number-pagi-ic.prev i {
      color: #e9bc59;
      transition: all 0.3s linear; }
    @media (min-width: 1201px) {
      .number-pagi-ic.prev:hover {
        background: linear-gradient(180deg, #f5d461 54.62%, #fdc202 72.84%, #cb983f 88.46%); }
        .number-pagi-ic.prev:hover i {
          color: #ffffff; } }
  .number-pagi-ic.next {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8146A3;
    font-weight: 600;
    border-radius: 50%;
    transition: all 0.3s linear; }
    .number-pagi-ic.next i {
      color: #e9bc59;
      transition: all 0.3s linear; }
    @media (min-width: 1201px) {
      .number-pagi-ic.next:hover {
        background: linear-gradient(180deg, #f5d461 54.62%, #fdc202 72.84%, #cb983f 88.46%); }
        .number-pagi-ic.next:hover i {
          color: #ffffff; } }

.box-navi-2 {
  display: flex;
  width: fit-content;
  align-items: center;
  column-gap: 2rem; }
  .box-navi-2 .btn-navi-2 {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #8146A3;
    transition: all 0.3s linear;
    cursor: pointer;
    position: absolute;
    z-index: 5; }
    .box-navi-2 .btn-navi-2 i {
      font-size: 2rem;
      color: #8146A3;
      transition: all 0.3s linear; }
    @media (min-width: 1201px) {
      .box-navi-2 .btn-navi-2:hover {
        background: #8146A3; }
        .box-navi-2 .btn-navi-2:hover i {
          color: #fdc202; } }
    @media screen and (max-width: 1200px) {
      .box-navi-2 .btn-navi-2 {
        width: 4rem;
        height: 4rem; }
        .box-navi-2 .btn-navi-2 i {
          font-size: 1.6rem; } }
    .box-navi-2 .btn-navi-2.prev {
      top: 50%;
      transform: translateY(-50%);
      left: -5rem; }
      @media screen and (max-width: 1380px) {
        .box-navi-2 .btn-navi-2.prev {
          left: -2rem; } }
      @media screen and (max-width: 1300px) {
        .box-navi-2 .btn-navi-2.prev {
          left: 1rem; } }
      @media screen and (max-width: 600px) {
        .box-navi-2 .btn-navi-2.prev {
          left: 0rem; } }
      @media screen and (max-width: 460px) {
        .box-navi-2 .btn-navi-2.prev {
          left: -1rem; } }
    .box-navi-2 .btn-navi-2.next {
      top: 50%;
      transform: translateY(-50%);
      right: -5rem; }
      @media screen and (max-width: 1380px) {
        .box-navi-2 .btn-navi-2.next {
          right: -2rem; } }
      @media screen and (max-width: 1300px) {
        .box-navi-2 .btn-navi-2.next {
          right: 1rem; } }
      @media screen and (max-width: 600px) {
        .box-navi-2 .btn-navi-2.next {
          right: 0rem; } }
      @media screen and (max-width: 460px) {
        .box-navi-2 .btn-navi-2.next {
          right: -1rem; } }

.btn-filter {
  height: 4rem;
  width: 4rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8146A3;
  cursor: pointer;
  display: none; }
  @media screen and (max-width: 1024px) {
    .btn-filter {
      display: flex; } }
  @media screen and (max-width: 700px) {
    .btn-filter {
      position: absolute;
      right: 0;
      top: 0; } }
  .btn-filter i {
    color: #ffffff;
    font-size: 2rem; }

.btn-exits {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  border-radius: 0.5rem; }
  .btn-exits i {
    font-size: 3rem;
    color: #8146A3; }

.box-dm {
  padding: 0.4rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  background: rgba(0, 57, 126, 0.1);
  width: fit-content;
  font-size: 1.2rem;
  font-weight: 600;
  color: #8146A3;
  transition: all 0.3s linear; }
  @media (min-width: 1201px) {
    .box-dm:hover {
      background: #8146A3;
      color: #393D48; } }

.btn-al-ic {
  padding: 1.6rem;
  background: #8146A3;
  transition: all 0.3s linear;
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 460px) {
    .btn-al-ic {
      width: 3.6rem;
      height: 3.6rem; } }
  .btn-al-ic.cl-2 {
    background: #ffffff; }
    .btn-al-ic.cl-2 i {
      color: #8146A3;
      font-size: 1.8rem; }
      @media only screen and (max-width: 1200px) {
        .btn-al-ic.cl-2 i {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 800px) {
        .btn-al-ic.cl-2 i {
          font-size: 1.5rem; } }
      @media only screen and (max-width: 600px) {
        .btn-al-ic.cl-2 i {
          font-size: 1.4rem; } }
    @media (min-width: 1201px) {
      .btn-al-ic.cl-2:hover {
        background: #393D48; }
        .btn-al-ic.cl-2:hover i {
          color: #ffffff;
          font-size: 1.8rem; } }
    @media only screen and (min-width: 1201px) and (max-width: 1200px) {
      .btn-al-ic.cl-2:hover i {
        font-size: 1.6rem; } }
    @media only screen and (min-width: 1201px) and (max-width: 800px) {
      .btn-al-ic.cl-2:hover i {
        font-size: 1.5rem; } }
    @media only screen and (min-width: 1201px) and (max-width: 600px) {
      .btn-al-ic.cl-2:hover i {
        font-size: 1.4rem; } }
  .btn-al-ic i {
    color: #ffffff;
    font-size: 1.8rem; }
    @media only screen and (max-width: 1200px) {
      .btn-al-ic i {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .btn-al-ic i {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 600px) {
      .btn-al-ic i {
        font-size: 1.4rem; } }
  @media (min-width: 1201px) {
    .btn-al-ic:hover {
      background: #393D48; } }

.swiper {
  width: 100%; }
  .swiper-container {
    width: 100%; }
  .swiper-scrollbar {
    height: 0.4rem;
    width: 98%;
    margin: 1rem auto 0;
    overflow: hidden;
    border-radius: 0;
    background: #ffffff;
    cursor: grab; }
    .swiper-scrollbar-drag {
      background: #393D48; }
  .swiper-pagination {
    position: static;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    margin-top: 2.4rem;
    height: 1.5rem; }
    .swiper-pagination.custom .swiper-pagination-bullet {
      width: 1.2rem;
      height: 1.2rem;
      transition: all 0.3s linear; }
    .swiper-pagination.custom .swiper-pagination-bullet-active {
      width: 2.4rem;
      height: 1.2rem; }
    @media screen and (max-width: 1200px) {
      .swiper-pagination.custom-2 {
        margin-top: 2rem; } }
    @media screen and (max-width: 600px) {
      .swiper-pagination.custom-2 {
        margin-top: 1.6rem; } }
    .swiper-pagination.custom-2 .swiper-pagination-bullet {
      width: 6rem;
      height: 0.4rem;
      background: #D9D9D9; }
      .swiper-pagination.custom-2 .swiper-pagination-bullet-active {
        background: #3c214c; }
      @media screen and (max-width: 800px) {
        .swiper-pagination.custom-2 .swiper-pagination-bullet {
          width: 3rem; } }
    .swiper-pagination::before {
      display: none; }
    .swiper-pagination-bullet {
      transition: all 0.3s ease-out;
      width: 1.2rem;
      height: 1.2rem;
      opacity: 1;
      border-radius: 10rem;
      margin: 0 0.5rem;
      background: #e7e3df; }
      .swiper-pagination-bullet-active {
        background: #393D48;
        width: 1.6rem;
        height: 1.6rem; }
    .swiper-pagination.swiper-pagination-lock {
      display: none; }
    .swiper-pagination.cl-pri .swiper-pagination-bullet {
      background-color: white;
      border: 0.1rem solid white; }
      .swiper-pagination.cl-pri .swiper-pagination-bullet-active {
        border: 0.1rem solid #393D48;
        background-color: transparent; }
    @media screen and (min-width: 1301px) {
      .swiper-pagination.disabled-on-desktop {
        display: none; } }
    .swiper-pagination-num {
      height: auto; }
      .swiper-pagination-num .swiper-pagination-bullet {
        background: unset;
        width: unset;
        height: unset;
        border: none;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.4;
        display: block;
        color: rgba(19, 23, 23, 0.6);
        text-align: center;
        position: relative;
        background: transparent; }
        .swiper-pagination-num .swiper-pagination-bullet::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0.2rem;
          left: 0;
          top: 50%;
          background: #8146A3;
          transform: translateY(-50%);
          transition: all 0.3s ease-in-out; }
        .swiper-pagination-num .swiper-pagination-bullet-active {
          color: #8146A3;
          padding-left: 2rem; }
          .swiper-pagination-num .swiper-pagination-bullet-active::before {
            width: 1.5rem; }
  .swiper-notification {
    display: none !important; }
  .swiper-navigation {
    cursor: pointer; }
    .swiper-navigation.circle {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      font-size: 1.2rem;
      font-weight: 900;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      border: 0.1rem solid #D22522; }
      @media screen and (max-width: 600px) {
        .swiper-navigation.circle {
          width: 3.2rem;
          height: 3.2rem; } }
      @media screen and (max-width: 400px) {
        .swiper-navigation.circle {
          width: 2.8rem;
          height: 2.8rem; } }
    .swiper-navigation.pri {
      border-radius: 50%;
      background: white;
      transition: all 0.3s ease-in-out;
      font-size: 1.2rem;
      color: white; }
      .swiper-navigation.pri i {
        font-weight: 300; }
      .swiper-navigation.pri.prev {
        top: 40%;
        left: -6rem;
        transform: translate(-50%, -50%); }
      .swiper-navigation.pri.next {
        top: 40%;
        right: -6rem;
        transform: translate(50%, -50%); }
      @media (min-width: 1201px) {
        .swiper-navigation.pri:hover {
          background: #D22522;
          color: white; }
          .swiper-navigation.pri:hover img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7448%) hue-rotate(44deg) brightness(114%) contrast(100%); } }
    .swiper-navigation.second {
      border-radius: 50%;
      background: #8146A3;
      transition: all 0.3s ease-in-out;
      font-size: 1.8rem;
      color: white; }
      .swiper-navigation.second.prev {
        top: 50%;
        left: -2.4rem;
        transform: translate(-50%, -50%); }
      .swiper-navigation.second.next {
        top: 50%;
        right: -2.4rem;
        transform: translate(50%, -50%); }
      @media (min-width: 1201px) {
        .swiper-navigation.second:hover {
          background: #393D48;
          color: white; } }
    @media screen and (max-width: 1300px) {
      .swiper-navigation.disabled-on-tablet {
        display: none; } }
    .swiper-navigation img {
      width: 1.1rem;
      height: 1.1rem;
      filter: brightness(0) saturate(100%) invert(44%) sepia(66%) saturate(4409%) hue-rotate(319deg) brightness(100%) contrast(103%); }
  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5; }
  .swiper-abs {
    position: absolute;
    z-index: 20; }
  .swiper-navi {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center; }
    .swiper-navi .swiper-navigation {
      margin: 0.6rem 1.2rem; }
      @media screen and (max-width: 1400px) {
        .swiper-navi .swiper-navigation {
          position: static;
          transform: none !important; } }
    .swiper-navi .swiper-pagination {
      margin-top: 0;
      width: fit-content;
      margin-left: 2rem;
      margin-right: 2rem;
      min-width: 17rem; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #393D48; }

.swiper-grid-column > .swiper-wrapper {
  flex-direction: row; }

.navigation .swiper-navigation {
  position: static;
  transform: none !important; }

.m-mid {
  margin: 0 auto; }

.m-end {
  margin-left: auto; }

.m-start {
  margin-right: auto; }

.f-mid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }

.f-end {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end; }

.fl {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }

.fl-center {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }

.f-space {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between; }

.spc.no-wrap .spc-list {
  flex-wrap: nowrap; }

.spc-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-left: -0.8rem;
  margin-bottom: -0.8rem; }

.spc-item {
  margin-left: 0.8rem;
  margin-bottom: 0.8rem; }

.spc-16 .spc-list {
  margin-left: -1.6rem;
  margin-bottom: -1.6rem; }

.spc-16 .spc-item {
  margin-bottom: 1.6rem;
  margin-left: 1.6rem; }

.spc-24 .spc-list {
  margin-left: -2.4rem;
  margin-bottom: -2.4rem; }

.spc-24 .spc-item {
  margin-bottom: 2.4rem;
  margin-left: 2.4rem; }

.bg-fade,
.bg-fade2,
.bg-fade3 {
  position: fixed;
  content: "";
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 980;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  .bg-fade.show,
  .bg-fade2.show,
  .bg-fade3.show {
    visibility: visible;
    opacity: 1; }

@media screen and (min-width: 1202px) {
  .hd-bg {
    display: none; } }

.bg-fade2 {
  z-index: 1000; }
  @media screen and (min-width: 1201px) {
    .bg-fade2 {
      display: none; } }

@media screen and (max-width: 1201px) {
  .bg-fade3 {
    display: none; } }

.list-wrap,
.d-wrap {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 1300px) {
    .list-wrap,
    .d-wrap {
      margin-left: -1.2rem;
      margin-right: -1.2rem; } }
  @media screen and (max-width: 800px) {
    .list-wrap,
    .d-wrap {
      margin-left: -0.8rem;
      margin-right: -0.8rem; } }
  @media screen and (max-width: 600px) {
    .list-wrap,
    .d-wrap {
      margin-left: -0.6rem;
      margin-right: -0.6rem; } }

.list-item,
.d-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  @media screen and (max-width: 1300px) {
    .list-item,
    .d-item {
      padding-left: 1.2rem;
      padding-right: 1.2rem; } }
  @media screen and (max-width: 800px) {
    .list-item,
    .d-item {
      padding-left: 0.8rem;
      padding-right: 0.8rem; } }
  @media screen and (max-width: 600px) {
    .list-item,
    .d-item {
      padding-left: 0.6rem;
      padding-right: 0.6rem; } }

.list-2,
.d-2 {
  width: calc(100% / 2); }

.list-3,
.d-3 {
  width: calc(100% / 3); }
  @media screen and (max-width: 800px) {
    .list-3,
    .d-3 {
      width: 50%; } }

.list-4,
.d-4 {
  width: calc(100% / 4); }
  @media screen and (max-width: 1024px) {
    .list-4,
    .d-4 {
      width: calc(100% / 3); } }
  @media screen and (max-width: 600px) {
    .list-4,
    .d-4 {
      width: calc(100% / 2); } }
  @media screen and (max-width: 350px) {
    .list-4,
    .d-4 {
      width: 100%; } }

.list-5,
.d-5 {
  width: calc(100% / 5); }

.dlarge-wrap {
  margin: 0 -3rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 1201px) {
    .dlarge-wrap {
      margin: 0 -1.2rem; } }
  @media screen and (max-width: 800px) {
    .dlarge-wrap {
      margin: 0 -0.6rem; } }
  @media screen and (max-width: 320px) {
    .dlarge-wrap {
      margin-left: -0.3rem;
      margin-right: -0.3rem; } }

.dlarge-item {
  padding-left: 3rem;
  padding-right: 3rem; }
  @media screen and (max-width: 1201px) {
    .dlarge-item {
      padding: 0 1.2rem; } }
  @media screen and (max-width: 800px) {
    .dlarge-item {
      padding-left: 0.6rem;
      padding-right: 0.6rem; } }
  @media screen and (max-width: 320px) {
    .dlarge-item {
      padding-left: 0.3rem;
      padding-right: 0.3rem; } }

.dlarge-2 {
  width: calc(100% / 2); }

.dlarge-3 {
  width: calc(100% / 3); }
  @media screen and (max-width: 800px) {
    .dlarge-3 {
      width: 50%; } }

.dlarge-4 {
  width: calc(100% / 4); }
  @media screen and (max-width: 1201px) {
    .dlarge-4 {
      width: calc(100% / 3); } }
  @media screen and (max-width: 800px) {
    .dlarge-4 {
      width: calc(100% / 2); } }
  @media screen and (max-width: 500px) {
    .dlarge-4 {
      width: 100%; } }

.dlarge-5 {
  width: calc(100% / 5); }

.dsm-wrap {
  margin: 0 -0.6rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }

.dsm-item {
  padding-left: 0.6rem;
  padding-right: 0.6rem; }

@keyframes line-slide {
  0% {
    background-position: -5% 0; }
  100% {
    background-position: 100% 0; } }

.swiper-next,
.swiper-prev {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border: 1px solid #393d48;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out; }
  .swiper-next.white,
  .swiper-prev.white {
    border: 1px solid white; }
    .swiper-next.white i,
    .swiper-prev.white i {
      color: white; }
  .swiper-next i,
  .swiper-prev i {
    color: #393d48;
    font-weight: 400; }

.bg-abs {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none; }
  .bg-abs img {
    object-fit: cover;
    height: 100%; }

.clear {
  clear: both; }

.gItem,
.swiper-slide {
  cursor: pointer; }

.backPage {
  padding: var(--pdGen) 0; }
  .backPage-link {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    .backPage-link .icon {
      font-size: 1.2rem;
      font-weight: 900;
      margin-right: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
    .backPage-link .text {
      font-weight: 500;
      font-size: 4rem; }
      @media only screen and (max-width: 1200px) {
        .backPage-link .text {
          font-size: 3rem; } }
      @media only screen and (max-width: 800px) {
        .backPage-link .text {
          font-size: 2.6rem; } }
      @media only screen and (max-width: 600px) {
        .backPage-link .text {
          font-size: 2.4rem; } }
      @media only screen and (max-width: 460px) {
        .backPage-link .text {
          font-size: 2.2rem; } }

.mona-content p {
  margin-bottom: var(--pdGen2); }
  @media screen and (max-width: 800px) {
    .mona-content p {
      font-size: 1.4rem; } }

.mona-content .plyr {
  margin-bottom: var(--pdGen2); }

@media screen and (min-width: 1202px) {
  .plyr__controls {
    opacity: 0;
    transform: translateY(3rem); } }

.plyr {
  border-radius: 0.8rem;
  height: 100%;
  overflow: hidden; }
  @media (min-width: 1201px) {
    .plyr:hover .plyr__controls {
      opacity: 1;
      transform: translateY(0); } }
  .plyr .plyr__control--overlaid {
    background: transparent;
    border: 0.3rem solid white;
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .plyr .plyr__control--overlaid svg {
      width: 1rem;
      margin: auto;
      transform: translateX(-0.1rem); }

.plyr__controls__item[data-plyr="captions"] {
  display: none; }

.plyr__controls__item[data-plyr="pip"] {
  display: none; }

.plyr__poster {
  background-size: cover; }

.scrollJS {
  overflow: auto;
  user-select: none; }
  .scrollJS::-webkit-scrollbar {
    display: none; }

#lg-actual-size,
#lg-share,
#lg-download {
  display: none; }

button[aria-label="Rotate left"] {
  display: none; }

button[aria-label="Rotate right"] {
  display: none; }

button[aria-label="Flip vertical"] {
  display: none; }

button[aria-label="flip horizontal"] {
  display: none; }

button[aria-label="Toggle autoplay"] {
  display: none; }

.page-numbers {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: start; }
  .page-numbers.m-top {
    margin-top: 3rem; }
  .page-numbers .dot {
    width: 5.3rem;
    height: 0.1rem;
    background: #393d48; }
  .page-numbers > li:not(:first-child) {
    margin-left: 1.6rem; }
    @media screen and (max-width: 460px) {
      .page-numbers > li:not(:first-child) {
        margin-left: 0.6rem; } }
  .page-numbers > li > .page-numbers {
    line-height: 1.5;
    font-weight: 700;
    color: #8146A3;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 4rem;
    height: 4rem;
    border-radius: 0;
    border: 0.1rem solid #999;
    font-size: 1.6rem;
    font-family: "Roboto"; }
    @media only screen and (max-width: 1200px) {
      .page-numbers > li > .page-numbers {
        font-size: 1.4rem; } }
    @media (min-width: 1201px) {
      .page-numbers > li > .page-numbers:hover {
        color: #8146A3;
        color: #ffffff;
        background: #8146A3;
        border-color: #8146A3; }
        .page-numbers > li > .page-numbers:hover i {
          color: white; } }
    @media screen and (max-width: 600px) {
      .page-numbers > li > .page-numbers {
        width: 3.2rem;
        height: 3.2rem;
        font-size: 1.4rem; } }
    .page-numbers > li > .page-numbers.current {
      background: #8146A3;
      color: #ffffff; }
    .page-numbers > li > .page-numbers.dot {
      pointer-events: none; }
    .page-numbers > li > .page-numbers .icon {
      font-weight: 400; }
  .page-numbers .page-numbers.next,
  .page-numbers .page-numbers.prev {
    cursor: pointer; }
    .page-numbers .page-numbers.next i,
    .page-numbers .page-numbers.prev i {
      font-size: 1.6rem;
      font-weight: 400;
      color: #8146A3; }
      @media only screen and (max-width: 1200px) {
        .page-numbers .page-numbers.next i,
        .page-numbers .page-numbers.prev i {
          font-size: 1.4rem; } }
    @media (min-width: 1201px) {
      .page-numbers .page-numbers.next:hover,
      .page-numbers .page-numbers.prev:hover {
        color: #ffffff;
        background: #393D48;
        border-color: #393D48; } }
    .page-numbers .page-numbers.next .icon,
    .page-numbers .page-numbers.prev .icon {
      font-weight: 900 !important;
      cursor: pointer;
      transition: all 0.3s ease-in-out; }

.pagination.center .page-numbers {
  justify-content: center; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-2 {
  margin-bottom: 0.2rem; }

.mb-2s:not(:last-child) {
  margin-bottom: 0.2rem; }

.mb-4 {
  margin-bottom: 0.4rem; }

.mb-4s:not(:last-child) {
  margin-bottom: 0.4rem; }

.mb-5 {
  margin-bottom: 0.5rem; }

.mb-5s:not(:last-child) {
  margin-bottom: 0.5rem; }

.mb-6 {
  margin-bottom: 0.6rem; }

.mb-6s:not(:last-child) {
  margin-bottom: 0.6rem; }

.mb-8 {
  margin-bottom: 0.8rem; }

.mb-8s:not(:last-child) {
  margin-bottom: 0.8rem; }

.mb-10 {
  margin-bottom: 1rem; }

.mb-10s:not(:last-child) {
  margin-bottom: 1rem; }

.mb-12 {
  margin-bottom: 1.2rem; }

.mb-12s:not(:last-child) {
  margin-bottom: 1.2rem; }

.mb-14 {
  margin-bottom: 1.4rem; }

.mb-14s:not(:last-child) {
  margin-bottom: 1.4rem; }

.mb-16 {
  margin-bottom: 1.6rem; }

.mb-16s:not(:last-child) {
  margin-bottom: 1.6rem; }

.mb-18 {
  margin-bottom: 1.8rem; }

.mb-18s:not(:last-child) {
  margin-bottom: 1.8rem; }

.mb-20 {
  margin-bottom: 2rem; }

.mb-20s:not(:last-child) {
  margin-bottom: 2rem; }

.mb-24 {
  margin-bottom: 2.4rem; }

.mb-24s:not(:last-child) {
  margin-bottom: 2.4rem; }

.mb-26 {
  margin-bottom: 2.6rem; }

.mb-26s:not(:last-child) {
  margin-bottom: 2.6rem; }

.mb-28 {
  margin-bottom: 2.8rem; }

.mb-28s:not(:last-child) {
  margin-bottom: 2.8rem; }

.mb-30 {
  margin-bottom: 3rem; }

.mb-30s:not(:last-child) {
  margin-bottom: 3rem; }

.mb-32 {
  margin-bottom: 3.2rem; }

.mb-32s:not(:last-child) {
  margin-bottom: 3.2rem; }

.mb-34 {
  margin-bottom: 3.4rem; }

.mb-34s:not(:last-child) {
  margin-bottom: 3.4rem; }

.mb-36 {
  margin-bottom: 3.6rem; }

.mb-36s:not(:last-child) {
  margin-bottom: 3.6rem; }

.mb-38 {
  margin-bottom: 3.8rem; }

.mb-38s:not(:last-child) {
  margin-bottom: 3.8rem; }

.mb-40 {
  margin-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .mb-40 {
      margin-bottom: 2rem; } }

.mb-40s:not(:last-child) {
  margin-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .mb-40s:not(:last-child) {
      margin-bottom: 2rem; } }

.mb-42 {
  margin-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-42 {
      margin-bottom: 2.1rem; } }

.mb-42s:not(:last-child) {
  margin-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-42s:not(:last-child) {
      margin-bottom: 2.1rem; } }

.mb-44 {
  margin-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-44 {
      margin-bottom: 2.2rem; } }

.mb-44s:not(:last-child) {
  margin-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-44s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-46 {
  margin-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-46 {
      margin-bottom: 2.3rem; } }

.mb-46s:not(:last-child) {
  margin-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-46s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-48 {
  margin-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-48 {
      margin-bottom: 2.4rem; } }

.mb-48s:not(:last-child) {
  margin-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-48s:not(:last-child) {
      margin-bottom: 2.4rem; } }

.mb-50 {
  margin-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .mb-50 {
      margin-bottom: 2.5rem; } }

.mb-50s:not(:last-child) {
  margin-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .mb-50s:not(:last-child) {
      margin-bottom: 2.5rem; } }

.mb-52 {
  margin-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-52 {
      margin-bottom: 2.6rem; } }

.mb-52s:not(:last-child) {
  margin-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-52s:not(:last-child) {
      margin-bottom: 2.6rem; } }

.mb-54 {
  margin-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-54 {
      margin-bottom: 2.7rem; } }

.mb-54s:not(:last-child) {
  margin-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-54s:not(:last-child) {
      margin-bottom: 2.7rem; } }

.mb-56 {
  margin-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-56 {
      margin-bottom: 2.8rem; } }

.mb-56s:not(:last-child) {
  margin-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-56s:not(:last-child) {
      margin-bottom: 2.8rem; } }

.mb-58 {
  margin-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-58 {
      margin-bottom: 2.9rem; } }

.mb-58s:not(:last-child) {
  margin-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-58s:not(:last-child) {
      margin-bottom: 2.9rem; } }

.mb-60 {
  margin-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .mb-60 {
      margin-bottom: 3rem; } }

.mb-60s:not(:last-child) {
  margin-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .mb-60s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-62 {
  margin-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-62 {
      margin-bottom: 3.1rem; } }

.mb-62s:not(:last-child) {
  margin-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-62s:not(:last-child) {
      margin-bottom: 3.1rem; } }

.mb-64 {
  margin-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-64 {
      margin-bottom: 3.2rem; } }

.mb-64s:not(:last-child) {
  margin-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-64s:not(:last-child) {
      margin-bottom: 3.2rem; } }

.mb-68 {
  margin-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-68 {
      margin-bottom: 3.4rem; } }

.mb-68s:not(:last-child) {
  margin-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-68s:not(:last-child) {
      margin-bottom: 3.4rem; } }

.mb-70 {
  margin-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .mb-70 {
      margin-bottom: 3.5rem; } }

.mb-70s:not(:last-child) {
  margin-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .mb-70s:not(:last-child) {
      margin-bottom: 3.5rem; } }

.mb-72 {
  margin-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-72 {
      margin-bottom: 3.6rem; } }

.mb-72s:not(:last-child) {
  margin-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-72s:not(:last-child) {
      margin-bottom: 3.6rem; } }

.mb-74 {
  margin-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-74 {
      margin-bottom: 3.7rem; } }

.mb-72s:not(:last-child) {
  margin-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-72s:not(:last-child) {
      margin-bottom: 3.7rem; } }

.mb-76 {
  margin-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-76 {
      margin-bottom: 3.8rem; } }

.mb-76s:not(:last-child) {
  margin-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-76s:not(:last-child) {
      margin-bottom: 3.8rem; } }

.mb-78 {
  margin-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-78 {
      margin-bottom: 3.9rem; } }

.mb-78s:not(:last-child) {
  margin-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-78s:not(:last-child) {
      margin-bottom: 3.9rem; } }

.mb-80 {
  margin-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .mb-80 {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .mb-80 {
      margin-bottom: 2rem; } }

.mb-80s:not(:last-child) {
  margin-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .mb-80s:not(:last-child) {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .mb-80s:not(:last-child) {
      margin-bottom: 2rem; } }

.mb-82 {
  margin-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-82 {
      margin-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mb-82 {
      margin-bottom: 2rem; } }

.mb-82s:not(:last-child) {
  margin-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-82s:not(:last-child) {
      margin-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mb-82s:not(:last-child) {
      margin-bottom: 2rem; } }

.mb-84 {
  margin-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-84 {
      margin-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mb-84 {
      margin-bottom: 2.2rem; } }

.mb-84s:not(:last-child) {
  margin-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-84s:not(:last-child) {
      margin-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mb-84s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-86 {
  margin-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-86 {
      margin-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mb-86 {
      margin-bottom: 2.3rem; } }

.mb-86s:not(:last-child) {
  margin-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-86s:not(:last-child) {
      margin-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mb-86s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-88 {
  margin-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-88 {
      margin-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mb-88 {
      margin-bottom: 2.2rem; } }

.mb-88s:not(:last-child) {
  margin-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-88s:not(:last-child) {
      margin-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mb-88s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-90 {
  margin-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .mb-90 {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mb-90 {
      margin-bottom: 2.2rem; } }

.mb-90s:not(:last-child) {
  margin-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .mb-90s:not(:last-child) {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mb-90s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-92 {
  margin-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-92 {
      margin-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mb-92 {
      margin-bottom: 2.3rem; } }

.mb-92s:not(:last-child) {
  margin-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-92s:not(:last-child) {
      margin-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mb-92s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-94 {
  margin-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-94 {
      margin-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mb-94 {
      margin-bottom: 2.3rem; } }

.mb-94s:not(:last-child) {
  margin-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-94s:not(:last-child) {
      margin-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mb-94s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-96 {
  margin-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-96 {
      margin-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mb-96 {
      margin-bottom: 2.4rem; } }

.mb-96s:not(:last-child) {
  margin-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-96s:not(:last-child) {
      margin-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mb-96s:not(:last-child) {
      margin-bottom: 2.4rem; } }

.mb-98 {
  margin-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-98 {
      margin-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mb-98 {
      margin-bottom: 2.4rem; } }

.mb-98s:not(:last-child) {
  margin-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-98s:not(:last-child) {
      margin-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mb-98s:not(:last-child) {
      margin-bottom: 2.4rem; } }

.mb-100 {
  margin-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .mb-100 {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .mb-100 {
      margin-bottom: 2.5rem; } }

.mb-100s:not(:last-child) {
  margin-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .mb-100s:not(:last-child) {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .mb-100s:not(:last-child) {
      margin-bottom: 2.5rem; } }

.mb-120 {
  margin-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .mb-120 {
      margin-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .mb-120 {
      margin-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .mb-120 {
      margin-bottom: 3rem; } }

.mb-120s:not(:last-child) {
  margin-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .mb-120s:not(:last-child) {
      margin-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .mb-120s:not(:last-child) {
      margin-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .mb-120s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-140 {
  margin-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .mb-140 {
      margin-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .mb-140 {
      margin-bottom: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .mb-140 {
      margin-bottom: 3rem; } }

.mb-140s:not(:last-child) {
  margin-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .mb-140s:not(:last-child) {
      margin-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .mb-140s:not(:last-child) {
      margin-bottom: 3.5rem; } }

@media screen and (max-width: 460px) {
  .mb-140s {
    margin-bottom: 3rem; } }

.mb-160 {
  margin-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .mb-160 {
      margin-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .mb-160 {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .mb-160 {
      margin-bottom: 3rem; } }

.mb-160s:not(:last-child) {
  margin-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .mb-160s:not(:last-child) {
      margin-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .mb-160s:not(:last-child) {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .mb-160s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-180 {
  margin-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .mb-180 {
      margin-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .mb-180 {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mb-180 {
      margin-bottom: 3rem; } }

.mb-180s:not(:last-child) {
  margin-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .mb-180s:not(:last-child) {
      margin-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .mb-180s:not(:last-child) {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mb-180s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-200 {
  margin-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .mb-200 {
      margin-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .mb-200 {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .mb-200 {
      margin-bottom: 3rem; } }

.mb-200s:not(:last-child) {
  margin-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .mb-200s:not(:last-child) {
      margin-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .mb-200s:not(:last-child) {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .mb-200s:not(:last-child) {
      margin-bottom: 3rem; } }

.mt-0 {
  margin-top: 0 !important; }

.mt-2 {
  margin-top: 0.2rem; }

.mt-2s:not(:last-child) {
  margin-top: 0.2rem; }

.mt-4 {
  margin-top: 0.4rem; }

.mt-4s:not(:last-child) {
  margin-top: 0.4rem; }

.mt-5 {
  margin-top: 0.5rem; }

.mt-5s:not(:last-child) {
  margin-top: 0.5rem; }

.mt-6 {
  margin-top: 0.6rem; }

.mt-6s:not(:last-child) {
  margin-top: 0.6rem; }

.mt-8 {
  margin-top: 0.8rem; }

.mt-8s:not(:last-child) {
  margin-top: 0.8rem; }

.mt-10 {
  margin-top: 1rem; }

.mt-10s:not(:last-child) {
  margin-top: 1rem; }

.mt-12 {
  margin-top: 1.2rem; }

.mt-12s:not(:last-child) {
  margin-top: 1.2rem; }

.mt-14 {
  margin-top: 1.4rem; }

.mt-14s:not(:last-child) {
  margin-top: 1.4rem; }

.mt-16 {
  margin-top: 1.6rem; }

.mt-16s:not(:last-child) {
  margin-top: 1.6rem; }

.mt-18 {
  margin-top: 1.8rem; }

.mt-18s:not(:last-child) {
  margin-top: 1.8rem; }

.mt-20 {
  margin-top: 2rem; }

.mt-20s:not(:last-child) {
  margin-top: 2rem; }

.mt-24 {
  margin-top: 2.4rem; }

.mt-24s:not(:last-child) {
  margin-top: 2.4rem; }

.mt-26 {
  margin-top: 2.6rem; }

.mt-26s:not(:last-child) {
  margin-top: 2.6rem; }

.mt-28 {
  margin-top: 2.8rem; }

.mt-28s:not(:last-child) {
  margin-top: 2.8rem; }

.mt-30 {
  margin-top: 3rem; }

.mt-30s:not(:last-child) {
  margin-top: 3rem; }

.mt-32 {
  margin-top: 3.2rem; }

.mt-32s:not(:last-child) {
  margin-top: 3.2rem; }

.mt-34 {
  margin-top: 3.4rem; }

.mt-34s:not(:last-child) {
  margin-top: 3.4rem; }

.mt-36 {
  margin-top: 3.6rem; }

.mt-36s:not(:last-child) {
  margin-top: 3.6rem; }

.mt-38 {
  margin-top: 3.8rem; }

.mt-38s:not(:last-child) {
  margin-top: 3.8rem; }

.mt-40 {
  margin-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .mt-40 {
      margin-top: 2rem; } }

.mt-40s:not(:last-child) {
  margin-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .mt-40s:not(:last-child) {
      margin-top: 2rem; } }

.mt-42 {
  margin-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-42 {
      margin-top: 2.1rem; } }

.mt-42s:not(:last-child) {
  margin-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-42s:not(:last-child) {
      margin-top: 2.1rem; } }

.mt-44 {
  margin-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-44 {
      margin-top: 2.2rem; } }

.mt-44s:not(:last-child) {
  margin-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-44s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-46 {
  margin-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-46 {
      margin-top: 2.3rem; } }

.mt-46s:not(:last-child) {
  margin-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-46s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-48 {
  margin-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-48 {
      margin-top: 2.4rem; } }

.mt-48s:not(:last-child) {
  margin-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-48s:not(:last-child) {
      margin-top: 2.4rem; } }

.mt-50 {
  margin-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .mt-50 {
      margin-top: 2.5rem; } }

.mt-50s:not(:last-child) {
  margin-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .mt-50s:not(:last-child) {
      margin-top: 2.5rem; } }

.mt-52 {
  margin-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-52 {
      margin-top: 2.6rem; } }

.mt-52s:not(:last-child) {
  margin-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-52s:not(:last-child) {
      margin-top: 2.6rem; } }

.mt-54 {
  margin-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-54 {
      margin-top: 2.7rem; } }

.mt-54s:not(:last-child) {
  margin-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-54s:not(:last-child) {
      margin-top: 2.7rem; } }

.mt-56 {
  margin-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-56 {
      margin-top: 2.8rem; } }

.mt-56s:not(:last-child) {
  margin-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-56s:not(:last-child) {
      margin-top: 2.8rem; } }

.mt-58 {
  margin-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-58 {
      margin-top: 2.9rem; } }

.mt-58s:not(:last-child) {
  margin-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-58s:not(:last-child) {
      margin-top: 2.9rem; } }

.mt-60 {
  margin-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .mt-60 {
      margin-top: 3rem; } }

.mt-60s:not(:last-child) {
  margin-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .mt-60s:not(:last-child) {
      margin-top: 3rem; } }

.mt-62 {
  margin-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-62 {
      margin-top: 3.1rem; } }

.mt-62s:not(:last-child) {
  margin-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-62s:not(:last-child) {
      margin-top: 3.1rem; } }

.mt-64 {
  margin-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-64 {
      margin-top: 3.2rem; } }

.mt-64s:not(:last-child) {
  margin-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-64s:not(:last-child) {
      margin-top: 3.2rem; } }

.mt-68 {
  margin-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-68 {
      margin-top: 3.4rem; } }

.mt-68s:not(:last-child) {
  margin-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-68s:not(:last-child) {
      margin-top: 3.4rem; } }

.mt-70 {
  margin-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .mt-70 {
      margin-top: 3.5rem; } }

.mt-70s:not(:last-child) {
  margin-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .mt-70s:not(:last-child) {
      margin-top: 3.5rem; } }

.mt-72 {
  margin-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-72 {
      margin-top: 3.6rem; } }

.mt-72s:not(:last-child) {
  margin-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-72s:not(:last-child) {
      margin-top: 3.6rem; } }

.mt-74 {
  margin-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-74 {
      margin-top: 3.7rem; } }

.mt-72s:not(:last-child) {
  margin-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-72s:not(:last-child) {
      margin-top: 3.7rem; } }

.mt-76 {
  margin-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-76 {
      margin-top: 3.8rem; } }

.mt-76s:not(:last-child) {
  margin-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-76s:not(:last-child) {
      margin-top: 3.8rem; } }

.mt-78 {
  margin-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-78 {
      margin-top: 3.9rem; } }

.mt-78s:not(:last-child) {
  margin-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-78s:not(:last-child) {
      margin-top: 3.9rem; } }

.mt-80 {
  margin-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .mt-80 {
      margin-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .mt-80 {
      margin-top: 2rem; } }

.mt-80s:not(:last-child) {
  margin-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .mt-80s:not(:last-child) {
      margin-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .mt-80s:not(:last-child) {
      margin-top: 2rem; } }

.mt-82 {
  margin-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-82 {
      margin-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mt-82 {
      margin-top: 2rem; } }

.mt-82s:not(:last-child) {
  margin-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-82s:not(:last-child) {
      margin-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mt-82s:not(:last-child) {
      margin-top: 2rem; } }

.mt-84 {
  margin-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-84 {
      margin-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mt-84 {
      margin-top: 2.2rem; } }

.mt-84s:not(:last-child) {
  margin-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-84s:not(:last-child) {
      margin-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mt-84s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-86 {
  margin-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-86 {
      margin-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mt-86 {
      margin-top: 2.3rem; } }

.mt-86s:not(:last-child) {
  margin-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-86s:not(:last-child) {
      margin-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mt-86s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-88 {
  margin-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-88 {
      margin-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mt-88 {
      margin-top: 2.2rem; } }

.mt-88s:not(:last-child) {
  margin-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-88s:not(:last-child) {
      margin-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mt-88s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-90 {
  margin-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .mt-90 {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mt-90 {
      margin-top: 2.2rem; } }

.mt-90s:not(:last-child) {
  margin-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .mt-90s:not(:last-child) {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mt-90s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-92 {
  margin-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-92 {
      margin-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mt-92 {
      margin-top: 2.3rem; } }

.mt-92s:not(:last-child) {
  margin-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-92s:not(:last-child) {
      margin-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mt-92s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-94 {
  margin-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-94 {
      margin-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mt-94 {
      margin-top: 2.3rem; } }

.mt-94s:not(:last-child) {
  margin-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-94s:not(:last-child) {
      margin-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mt-94s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-96 {
  margin-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-96 {
      margin-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mt-96 {
      margin-top: 2.4rem; } }

.mt-96s:not(:last-child) {
  margin-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-96s:not(:last-child) {
      margin-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mt-96s:not(:last-child) {
      margin-top: 2.4rem; } }

.mt-98 {
  margin-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-98 {
      margin-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mt-98 {
      margin-top: 2.4rem; } }

.mt-98s:not(:last-child) {
  margin-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-98s:not(:last-child) {
      margin-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mt-98s:not(:last-child) {
      margin-top: 2.4rem; } }

.mt-100 {
  margin-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .mt-100 {
      margin-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .mt-100 {
      margin-top: 2.5rem; } }

.mt-100s:not(:last-child) {
  margin-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .mt-100s:not(:last-child) {
      margin-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .mt-100s:not(:last-child) {
      margin-top: 2.5rem; } }

.mt-120 {
  margin-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .mt-120 {
      margin-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .mt-120 {
      margin-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .mt-120 {
      margin-top: 3rem; } }

.mt-120s:not(:last-child) {
  margin-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .mt-120s:not(:last-child) {
      margin-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .mt-120s:not(:last-child) {
      margin-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .mt-120s:not(:last-child) {
      margin-top: 3rem; } }

.mt-140 {
  margin-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .mt-140 {
      margin-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .mt-140 {
      margin-top: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .mt-140 {
      margin-top: 3rem; } }

.mt-140s:not(:last-child) {
  margin-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .mt-140s:not(:last-child) {
      margin-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .mt-140s:not(:last-child) {
      margin-top: 3.5rem; } }

@media screen and (max-width: 460px) {
  .mt-140s {
    margin-top: 3rem; } }

.mt-160 {
  margin-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .mt-160 {
      margin-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .mt-160 {
      margin-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .mt-160 {
      margin-top: 3rem; } }

.mt-160s:not(:last-child) {
  margin-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .mt-160s:not(:last-child) {
      margin-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .mt-160s:not(:last-child) {
      margin-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .mt-160s:not(:last-child) {
      margin-top: 3rem; } }

.mt-180 {
  margin-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .mt-180 {
      margin-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .mt-180 {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mt-180 {
      margin-top: 3rem; } }

.mt-180s:not(:last-child) {
  margin-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .mt-180s:not(:last-child) {
      margin-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .mt-180s:not(:last-child) {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mt-180s:not(:last-child) {
      margin-top: 3rem; } }

.mt-200 {
  margin-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .mt-200 {
      margin-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .mt-200 {
      margin-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .mt-200 {
      margin-top: 3rem; } }

.mt-200s:not(:last-child) {
  margin-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .mt-200s:not(:last-child) {
      margin-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .mt-200s:not(:last-child) {
      margin-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .mt-200s:not(:last-child) {
      margin-top: 3rem; } }

.pt-0 {
  padding-top: 0 !important; }

.pt-2 {
  padding-top: 0.2rem; }

.pt-2s:not(:last-child) {
  padding-top: 0.2rem; }

.pt-4 {
  padding-top: 0.4rem; }

.pt-4s:not(:last-child) {
  padding-top: 0.4rem; }

.pt-5 {
  padding-top: 0.5rem; }

.pt-5s:not(:last-child) {
  padding-top: 0.5rem; }

.pt-6 {
  padding-top: 0.6rem; }

.pt-6s:not(:last-child) {
  padding-top: 0.6rem; }

.pt-8 {
  padding-top: 0.8rem; }

.pt-8s:not(:last-child) {
  padding-top: 0.8rem; }

.pt-10 {
  padding-top: 1rem; }

.pt-10s:not(:last-child) {
  padding-top: 1rem; }

.pt-12 {
  padding-top: 1.2rem; }

.pt-12s:not(:last-child) {
  padding-top: 1.2rem; }

.pt-14 {
  padding-top: 1.4rem; }

.pt-14s:not(:last-child) {
  padding-top: 1.4rem; }

.pt-16 {
  padding-top: 1.6rem; }

.pt-16s:not(:last-child) {
  padding-top: 1.6rem; }

.pt-18 {
  padding-top: 1.8rem; }

.pt-18s:not(:last-child) {
  padding-top: 1.8rem; }

.pt-20 {
  padding-top: 2rem; }

.pt-20s:not(:last-child) {
  padding-top: 2rem; }

.pt-24 {
  padding-top: 2.4rem; }

.pt-24s:not(:last-child) {
  padding-top: 2.4rem; }

.pt-26 {
  padding-top: 2.6rem; }

.pt-26s:not(:last-child) {
  padding-top: 2.6rem; }

.pt-28 {
  padding-top: 2.8rem; }

.pt-28s:not(:last-child) {
  padding-top: 2.8rem; }

.pt-30 {
  padding-top: 3rem; }

.pt-30s:not(:last-child) {
  padding-top: 3rem; }

.pt-32 {
  padding-top: 3.2rem; }

.pt-32s:not(:last-child) {
  padding-top: 3.2rem; }

.pt-34 {
  padding-top: 3.4rem; }

.pt-34s:not(:last-child) {
  padding-top: 3.4rem; }

.pt-36 {
  padding-top: 3.6rem; }

.pt-36s:not(:last-child) {
  padding-top: 3.6rem; }

.pt-38 {
  padding-top: 3.8rem; }

.pt-38s:not(:last-child) {
  padding-top: 3.8rem; }

.pt-40 {
  padding-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .pt-40 {
      padding-top: 2rem; } }

.pt-40s:not(:last-child) {
  padding-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .pt-40s:not(:last-child) {
      padding-top: 2rem; } }

.pt-42 {
  padding-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-42 {
      padding-top: 2.1rem; } }

.pt-42s:not(:last-child) {
  padding-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-42s:not(:last-child) {
      padding-top: 2.1rem; } }

.pt-44 {
  padding-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-44 {
      padding-top: 2.2rem; } }

.pt-44s:not(:last-child) {
  padding-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-44s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-46 {
  padding-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-46 {
      padding-top: 2.3rem; } }

.pt-46s:not(:last-child) {
  padding-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-46s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-48 {
  padding-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-48 {
      padding-top: 2.4rem; } }

.pt-48s:not(:last-child) {
  padding-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-48s:not(:last-child) {
      padding-top: 2.4rem; } }

.pt-50 {
  padding-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .pt-50 {
      padding-top: 2.5rem; } }

.pt-50s:not(:last-child) {
  padding-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .pt-50s:not(:last-child) {
      padding-top: 2.5rem; } }

.pt-52 {
  padding-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-52 {
      padding-top: 2.6rem; } }

.pt-52s:not(:last-child) {
  padding-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-52s:not(:last-child) {
      padding-top: 2.6rem; } }

.pt-54 {
  padding-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-54 {
      padding-top: 2.7rem; } }

.pt-54s:not(:last-child) {
  padding-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-54s:not(:last-child) {
      padding-top: 2.7rem; } }

.pt-56 {
  padding-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-56 {
      padding-top: 2.8rem; } }

.pt-56s:not(:last-child) {
  padding-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-56s:not(:last-child) {
      padding-top: 2.8rem; } }

.pt-58 {
  padding-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-58 {
      padding-top: 2.9rem; } }

.pt-58s:not(:last-child) {
  padding-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-58s:not(:last-child) {
      padding-top: 2.9rem; } }

.pt-60 {
  padding-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .pt-60 {
      padding-top: 3rem; } }

.pt-60s:not(:last-child) {
  padding-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .pt-60s:not(:last-child) {
      padding-top: 3rem; } }

.pt-62 {
  padding-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-62 {
      padding-top: 3.1rem; } }

.pt-62s:not(:last-child) {
  padding-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-62s:not(:last-child) {
      padding-top: 3.1rem; } }

.pt-64 {
  padding-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-64 {
      padding-top: 3.2rem; } }

.pt-64s:not(:last-child) {
  padding-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-64s:not(:last-child) {
      padding-top: 3.2rem; } }

.pt-68 {
  padding-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-68 {
      padding-top: 3.4rem; } }

.pt-68s:not(:last-child) {
  padding-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-68s:not(:last-child) {
      padding-top: 3.4rem; } }

.pt-70 {
  padding-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .pt-70 {
      padding-top: 3.5rem; } }

.pt-70s:not(:last-child) {
  padding-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .pt-70s:not(:last-child) {
      padding-top: 3.5rem; } }

.pt-72 {
  padding-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-72 {
      padding-top: 3.6rem; } }

.pt-72s:not(:last-child) {
  padding-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-72s:not(:last-child) {
      padding-top: 3.6rem; } }

.pt-74 {
  padding-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-74 {
      padding-top: 3.7rem; } }

.pt-72s:not(:last-child) {
  padding-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-72s:not(:last-child) {
      padding-top: 3.7rem; } }

.pt-76 {
  padding-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-76 {
      padding-top: 3.8rem; } }

.pt-76s:not(:last-child) {
  padding-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-76s:not(:last-child) {
      padding-top: 3.8rem; } }

.pt-78 {
  padding-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-78 {
      padding-top: 3.9rem; } }

.pt-78s:not(:last-child) {
  padding-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-78s:not(:last-child) {
      padding-top: 3.9rem; } }

.pt-80 {
  padding-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .pt-80 {
      padding-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .pt-80 {
      padding-top: 2rem; } }

.pt-80s:not(:last-child) {
  padding-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .pt-80s:not(:last-child) {
      padding-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .pt-80s:not(:last-child) {
      padding-top: 2rem; } }

.pt-82 {
  padding-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-82 {
      padding-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pt-82 {
      padding-top: 2rem; } }

.pt-82s:not(:last-child) {
  padding-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-82s:not(:last-child) {
      padding-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pt-82s:not(:last-child) {
      padding-top: 2rem; } }

.pt-84 {
  padding-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-84 {
      padding-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pt-84 {
      padding-top: 2.2rem; } }

.pt-84s:not(:last-child) {
  padding-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-84s:not(:last-child) {
      padding-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pt-84s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-86 {
  padding-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-86 {
      padding-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pt-86 {
      padding-top: 2.3rem; } }

.pt-86s:not(:last-child) {
  padding-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-86s:not(:last-child) {
      padding-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pt-86s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-88 {
  padding-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-88 {
      padding-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pt-88 {
      padding-top: 2.2rem; } }

.pt-88s:not(:last-child) {
  padding-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-88s:not(:last-child) {
      padding-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pt-88s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-90 {
  padding-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .pt-90 {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pt-90 {
      padding-top: 2.2rem; } }

.pt-90s:not(:last-child) {
  padding-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .pt-90s:not(:last-child) {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pt-90s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-92 {
  padding-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-92 {
      padding-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pt-92 {
      padding-top: 2.3rem; } }

.pt-92s:not(:last-child) {
  padding-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-92s:not(:last-child) {
      padding-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pt-92s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-94 {
  padding-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-94 {
      padding-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pt-94 {
      padding-top: 2.3rem; } }

.pt-94s:not(:last-child) {
  padding-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-94s:not(:last-child) {
      padding-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pt-94s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-96 {
  padding-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-96 {
      padding-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pt-96 {
      padding-top: 2.4rem; } }

.pt-96s:not(:last-child) {
  padding-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-96s:not(:last-child) {
      padding-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pt-96s:not(:last-child) {
      padding-top: 2.4rem; } }

.pt-98 {
  padding-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-98 {
      padding-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pt-98 {
      padding-top: 2.4rem; } }

.pt-98s:not(:last-child) {
  padding-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-98s:not(:last-child) {
      padding-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pt-98s:not(:last-child) {
      padding-top: 2.4rem; } }

.pt-100 {
  padding-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .pt-100 {
      padding-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .pt-100 {
      padding-top: 2.5rem; } }

.pt-100s:not(:last-child) {
  padding-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .pt-100s:not(:last-child) {
      padding-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .pt-100s:not(:last-child) {
      padding-top: 2.5rem; } }

.pt-120 {
  padding-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .pt-120 {
      padding-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .pt-120 {
      padding-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .pt-120 {
      padding-top: 3rem; } }

.pt-120s:not(:last-child) {
  padding-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .pt-120s:not(:last-child) {
      padding-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .pt-120s:not(:last-child) {
      padding-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .pt-120s:not(:last-child) {
      padding-top: 3rem; } }

.pt-140 {
  padding-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .pt-140 {
      padding-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .pt-140 {
      padding-top: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .pt-140 {
      padding-top: 3rem; } }

.pt-140s:not(:last-child) {
  padding-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .pt-140s:not(:last-child) {
      padding-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .pt-140s:not(:last-child) {
      padding-top: 3.5rem; } }

@media screen and (max-width: 460px) {
  .pt-140s {
    padding-top: 3rem; } }

.pt-160 {
  padding-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .pt-160 {
      padding-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .pt-160 {
      padding-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .pt-160 {
      padding-top: 3rem; } }

.pt-160s:not(:last-child) {
  padding-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .pt-160s:not(:last-child) {
      padding-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .pt-160s:not(:last-child) {
      padding-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .pt-160s:not(:last-child) {
      padding-top: 3rem; } }

.pt-180 {
  padding-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .pt-180 {
      padding-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .pt-180 {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pt-180 {
      padding-top: 3rem; } }

.pt-180s:not(:last-child) {
  padding-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .pt-180s:not(:last-child) {
      padding-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .pt-180s:not(:last-child) {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pt-180s:not(:last-child) {
      padding-top: 3rem; } }

.pt-200 {
  padding-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .pt-200 {
      padding-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .pt-200 {
      padding-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .pt-200 {
      padding-top: 3rem; } }

.pt-200s:not(:last-child) {
  padding-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .pt-200s:not(:last-child) {
      padding-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .pt-200s:not(:last-child) {
      padding-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .pt-200s:not(:last-child) {
      padding-top: 3rem; } }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-2 {
  padding-bottom: 0.2rem; }

.pb-2s:not(:last-child) {
  padding-bottom: 0.2rem; }

.pb-4 {
  padding-bottom: 0.4rem; }

.pb-4s:not(:last-child) {
  padding-bottom: 0.4rem; }

.pb-5 {
  padding-bottom: 0.5rem; }

.pb-5s:not(:last-child) {
  padding-bottom: 0.5rem; }

.pb-6 {
  padding-bottom: 0.6rem; }

.pb-6s:not(:last-child) {
  padding-bottom: 0.6rem; }

.pb-8 {
  padding-bottom: 0.8rem; }

.pb-8s:not(:last-child) {
  padding-bottom: 0.8rem; }

.pb-10 {
  padding-bottom: 1rem; }

.pb-10s:not(:last-child) {
  padding-bottom: 1rem; }

.pb-12 {
  padding-bottom: 1.2rem; }

.pb-12s:not(:last-child) {
  padding-bottom: 1.2rem; }

.pb-14 {
  padding-bottom: 1.4rem; }

.pb-14s:not(:last-child) {
  padding-bottom: 1.4rem; }

.pb-16 {
  padding-bottom: 1.6rem; }

.pb-16s:not(:last-child) {
  padding-bottom: 1.6rem; }

.pb-18 {
  padding-bottom: 1.8rem; }

.pb-18s:not(:last-child) {
  padding-bottom: 1.8rem; }

.pb-20 {
  padding-bottom: 2rem; }

.pb-20s:not(:last-child) {
  padding-bottom: 2rem; }

.pb-24 {
  padding-bottom: 2.4rem; }

.pb-24s:not(:last-child) {
  padding-bottom: 2.4rem; }

.pb-26 {
  padding-bottom: 2.6rem; }

.pb-26s:not(:last-child) {
  padding-bottom: 2.6rem; }

.pb-28 {
  padding-bottom: 2.8rem; }

.pb-28s:not(:last-child) {
  padding-bottom: 2.8rem; }

.pb-30 {
  padding-bottom: 3rem; }

.pb-30s:not(:last-child) {
  padding-bottom: 3rem; }

.pb-32 {
  padding-bottom: 3.2rem; }

.pb-32s:not(:last-child) {
  padding-bottom: 3.2rem; }

.pb-34 {
  padding-bottom: 3.4rem; }

.pb-34s:not(:last-child) {
  padding-bottom: 3.4rem; }

.pb-36 {
  padding-bottom: 3.6rem; }

.pb-36s:not(:last-child) {
  padding-bottom: 3.6rem; }

.pb-38 {
  padding-bottom: 3.8rem; }

.pb-38s:not(:last-child) {
  padding-bottom: 3.8rem; }

.pb-40 {
  padding-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .pb-40 {
      padding-bottom: 2rem; } }

.pb-40s:not(:last-child) {
  padding-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .pb-40s:not(:last-child) {
      padding-bottom: 2rem; } }

.pb-42 {
  padding-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-42 {
      padding-bottom: 2.1rem; } }

.pb-42s:not(:last-child) {
  padding-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-42s:not(:last-child) {
      padding-bottom: 2.1rem; } }

.pb-44 {
  padding-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-44 {
      padding-bottom: 2.2rem; } }

.pb-44s:not(:last-child) {
  padding-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-44s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-46 {
  padding-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-46 {
      padding-bottom: 2.3rem; } }

.pb-46s:not(:last-child) {
  padding-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-46s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-48 {
  padding-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-48 {
      padding-bottom: 2.4rem; } }

.pb-48s:not(:last-child) {
  padding-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-48s:not(:last-child) {
      padding-bottom: 2.4rem; } }

.pb-50 {
  padding-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .pb-50 {
      padding-bottom: 2.5rem; } }

.pb-50s:not(:last-child) {
  padding-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .pb-50s:not(:last-child) {
      padding-bottom: 2.5rem; } }

.pb-52 {
  padding-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-52 {
      padding-bottom: 2.6rem; } }

.pb-52s:not(:last-child) {
  padding-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-52s:not(:last-child) {
      padding-bottom: 2.6rem; } }

.pb-54 {
  padding-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-54 {
      padding-bottom: 2.7rem; } }

.pb-54s:not(:last-child) {
  padding-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-54s:not(:last-child) {
      padding-bottom: 2.7rem; } }

.pb-56 {
  padding-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-56 {
      padding-bottom: 2.8rem; } }

.pb-56s:not(:last-child) {
  padding-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-56s:not(:last-child) {
      padding-bottom: 2.8rem; } }

.pb-58 {
  padding-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-58 {
      padding-bottom: 2.9rem; } }

.pb-58s:not(:last-child) {
  padding-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-58s:not(:last-child) {
      padding-bottom: 2.9rem; } }

.pb-60 {
  padding-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .pb-60 {
      padding-bottom: 3rem; } }

.pb-60s:not(:last-child) {
  padding-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .pb-60s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-62 {
  padding-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-62 {
      padding-bottom: 3.1rem; } }

.pb-62s:not(:last-child) {
  padding-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-62s:not(:last-child) {
      padding-bottom: 3.1rem; } }

.pb-64 {
  padding-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-64 {
      padding-bottom: 3.2rem; } }

.pb-64s:not(:last-child) {
  padding-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-64s:not(:last-child) {
      padding-bottom: 3.2rem; } }

.pb-68 {
  padding-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-68 {
      padding-bottom: 3.4rem; } }

.pb-68s:not(:last-child) {
  padding-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-68s:not(:last-child) {
      padding-bottom: 3.4rem; } }

.pb-70 {
  padding-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .pb-70 {
      padding-bottom: 3.5rem; } }

.pb-70s:not(:last-child) {
  padding-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .pb-70s:not(:last-child) {
      padding-bottom: 3.5rem; } }

.pb-72 {
  padding-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-72 {
      padding-bottom: 3.6rem; } }

.pb-72s:not(:last-child) {
  padding-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-72s:not(:last-child) {
      padding-bottom: 3.6rem; } }

.pb-74 {
  padding-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-74 {
      padding-bottom: 3.7rem; } }

.pb-72s:not(:last-child) {
  padding-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-72s:not(:last-child) {
      padding-bottom: 3.7rem; } }

.pb-76 {
  padding-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-76 {
      padding-bottom: 3.8rem; } }

.pb-76s:not(:last-child) {
  padding-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-76s:not(:last-child) {
      padding-bottom: 3.8rem; } }

.pb-78 {
  padding-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-78 {
      padding-bottom: 3.9rem; } }

.pb-78s:not(:last-child) {
  padding-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-78s:not(:last-child) {
      padding-bottom: 3.9rem; } }

.pb-80 {
  padding-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .pb-80 {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .pb-80 {
      padding-bottom: 2rem; } }

.pb-80s:not(:last-child) {
  padding-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .pb-80s:not(:last-child) {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .pb-80s:not(:last-child) {
      padding-bottom: 2rem; } }

.pb-82 {
  padding-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-82 {
      padding-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pb-82 {
      padding-bottom: 2rem; } }

.pb-82s:not(:last-child) {
  padding-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-82s:not(:last-child) {
      padding-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pb-82s:not(:last-child) {
      padding-bottom: 2rem; } }

.pb-84 {
  padding-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-84 {
      padding-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pb-84 {
      padding-bottom: 2.2rem; } }

.pb-84s:not(:last-child) {
  padding-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-84s:not(:last-child) {
      padding-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pb-84s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-86 {
  padding-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-86 {
      padding-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pb-86 {
      padding-bottom: 2.3rem; } }

.pb-86s:not(:last-child) {
  padding-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-86s:not(:last-child) {
      padding-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pb-86s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-88 {
  padding-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-88 {
      padding-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pb-88 {
      padding-bottom: 2.2rem; } }

.pb-88s:not(:last-child) {
  padding-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-88s:not(:last-child) {
      padding-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pb-88s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-90 {
  padding-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .pb-90 {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pb-90 {
      padding-bottom: 2.2rem; } }

.pb-90s:not(:last-child) {
  padding-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .pb-90s:not(:last-child) {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pb-90s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-92 {
  padding-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-92 {
      padding-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pb-92 {
      padding-bottom: 2.3rem; } }

.pb-92s:not(:last-child) {
  padding-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-92s:not(:last-child) {
      padding-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pb-92s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-94 {
  padding-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-94 {
      padding-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pb-94 {
      padding-bottom: 2.3rem; } }

.pb-94s:not(:last-child) {
  padding-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-94s:not(:last-child) {
      padding-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pb-94s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-96 {
  padding-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-96 {
      padding-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pb-96 {
      padding-bottom: 2.4rem; } }

.pb-96s:not(:last-child) {
  padding-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-96s:not(:last-child) {
      padding-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pb-96s:not(:last-child) {
      padding-bottom: 2.4rem; } }

.pb-98 {
  padding-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-98 {
      padding-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pb-98 {
      padding-bottom: 2.4rem; } }

.pb-98s:not(:last-child) {
  padding-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-98s:not(:last-child) {
      padding-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pb-98s:not(:last-child) {
      padding-bottom: 2.4rem; } }

.pb-100 {
  padding-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .pb-100 {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .pb-100 {
      padding-bottom: 2.5rem; } }

.pb-100s:not(:last-child) {
  padding-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .pb-100s:not(:last-child) {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .pb-100s:not(:last-child) {
      padding-bottom: 2.5rem; } }

.pb-120 {
  padding-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .pb-120 {
      padding-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .pb-120 {
      padding-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .pb-120 {
      padding-bottom: 3rem; } }

.pb-120s:not(:last-child) {
  padding-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .pb-120s:not(:last-child) {
      padding-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .pb-120s:not(:last-child) {
      padding-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .pb-120s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-140 {
  padding-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .pb-140 {
      padding-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .pb-140 {
      padding-bottom: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .pb-140 {
      padding-bottom: 3rem; } }

.pb-140s:not(:last-child) {
  padding-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .pb-140s:not(:last-child) {
      padding-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .pb-140s:not(:last-child) {
      padding-bottom: 3.5rem; } }

@media screen and (max-width: 460px) {
  .pb-140s {
    padding-bottom: 3rem; } }

.pb-160 {
  padding-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .pb-160 {
      padding-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .pb-160 {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .pb-160 {
      padding-bottom: 3rem; } }

.pb-160s:not(:last-child) {
  padding-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .pb-160s:not(:last-child) {
      padding-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .pb-160s:not(:last-child) {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .pb-160s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-180 {
  padding-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .pb-180 {
      padding-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .pb-180 {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pb-180 {
      padding-bottom: 3rem; } }

.pb-180s:not(:last-child) {
  padding-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .pb-180s:not(:last-child) {
      padding-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .pb-180s:not(:last-child) {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pb-180s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-200 {
  padding-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .pb-200 {
      padding-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .pb-200 {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .pb-200 {
      padding-bottom: 3rem; } }

.pb-200s:not(:last-child) {
  padding-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .pb-200s:not(:last-child) {
      padding-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .pb-200s:not(:last-child) {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .pb-200s:not(:last-child) {
      padding-bottom: 3rem; } }

.f-14 {
  font-size: 1.4rem; }

.f-16 {
  font-size: 1.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-16 {
      font-size: 1.4rem; } }

.f-18 {
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-18 {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-18 {
      font-size: 1.5rem; } }
  @media only screen and (max-width: 600px) {
    .f-18 {
      font-size: 1.4rem; } }

.f-20 {
  font-size: 2rem; }
  @media only screen and (max-width: 1200px) {
    .f-20 {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-20 {
      font-size: 1.6rem; } }

.f-22 {
  font-size: 2.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-22 {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .f-22 {
      font-size: 1.9rem; } }

.f-24 {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-24 {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .f-24 {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-24 {
      font-size: 1.6rem; } }

.f-26 {
  font-size: 2.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-26 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-26 {
      font-size: 1.6rem; } }

.f-28 {
  font-size: 2.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-28 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-28 {
      font-size: 1.8rem; } }

.f-30 {
  font-size: 3rem; }
  @media only screen and (max-width: 1200px) {
    .f-30 {
      font-size: 2rem; } }

.f-32 {
  font-size: 3.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-32 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-32 {
      font-size: 2rem; } }
  @media only screen and (max-width: 460px) {
    .f-32 {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 360px) {
    .f-32 {
      font-size: 1.6rem; } }

.f-34 {
  font-size: 3.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-34 {
      font-size: 2.4rem; } }

.f-36 {
  font-size: 3.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-36 {
      font-size: 2.6rem; } }

.f-38 {
  font-size: 3.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-38 {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-38 {
      font-size: 2.4rem; } }

.f-40 {
  font-size: 4rem; }
  @media only screen and (max-width: 1200px) {
    .f-40 {
      font-size: 3rem; } }
  @media only screen and (max-width: 800px) {
    .f-40 {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-40 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 460px) {
    .f-40 {
      font-size: 2.2rem; } }

.f-42 {
  font-size: 4.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-42 {
      font-size: 3.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-42 {
      font-size: 2.2rem; } }

.f-44 {
  font-size: 4.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-44 {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-44 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-44 {
      font-size: 1.4rem; } }

.f-46 {
  font-size: 4.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-46 {
      font-size: 3.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-46 {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-46 {
      font-size: 1.6rem; } }

.f-48 {
  font-size: 4.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-48 {
      font-size: 3.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-48 {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 300px) {
    .f-48 {
      font-size: 2.4rem; } }

.f-50 {
  font-size: 5rem; }
  @media only screen and (max-width: 1200px) {
    .f-50 {
      font-size: 4rem; } }
  @media only screen and (max-width: 800px) {
    .f-50 {
      font-size: 3rem; } }
  @media only screen and (max-width: 600px) {
    .f-50 {
      font-size: 2rem; } }

.f-52 {
  font-size: 5.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-52 {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-52 {
      font-size: 3.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-52 {
      font-size: 2.2rem; } }

.f-54 {
  font-size: 5.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-54 {
      font-size: 4.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-54 {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-54 {
      font-size: 2.4rem; } }

.f-56 {
  font-size: 5.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-56 {
      font-size: 4.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-56 {
      font-size: 3.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-56 {
      font-size: 2.6rem; } }

.f-58 {
  font-size: 5.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-58 {
      font-size: 4.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-58 {
      font-size: 3.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-58 {
      font-size: 2.8rem; } }

.f-60 {
  font-size: 6rem; }
  @media only screen and (max-width: 1200px) {
    .f-60 {
      font-size: 5.5rem; } }
  @media only screen and (max-width: 800px) {
    .f-60 {
      font-size: 4.5rem; } }
  @media only screen and (max-width: 600px) {
    .f-60 {
      font-size: 3.5rem; } }

.f-62 {
  font-size: 6.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-62 {
      font-size: 5.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-62 {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-62 {
      font-size: 3.2rem; } }

.f-64 {
  font-size: 6.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-64 {
      font-size: 5.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-64 {
      font-size: 4.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-64 {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 460px) {
    .f-64 {
      font-size: 3rem; } }

.f-66 {
  font-size: 6.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-66 {
      font-size: 5.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-66 {
      font-size: 4.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-66 {
      font-size: 3.6rem; } }

.f-68 {
  font-size: 6.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-68 {
      font-size: 5.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-68 {
      font-size: 4.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-68 {
      font-size: 3.8rem; } }

.f-70 {
  font-size: 7rem; }
  @media only screen and (max-width: 1200px) {
    .f-70 {
      font-size: 6rem; } }
  @media only screen and (max-width: 800px) {
    .f-70 {
      font-size: 5rem; } }
  @media only screen and (max-width: 600px) {
    .f-70 {
      font-size: 4rem; } }

.f-72 {
  font-size: 7.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-72 {
      font-size: 6.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-72 {
      font-size: 5.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-72 {
      font-size: 4.2rem; } }

.f-74 {
  font-size: 7.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-74 {
      font-size: 6.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-74 {
      font-size: 5.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-74 {
      font-size: 4.4rem; } }

.f-76 {
  font-size: 7.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-76 {
      font-size: 5.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-76 {
      font-size: 4.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-76 {
      font-size: 3.6rem; } }

.f-78 {
  font-size: 7.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-78 {
      font-size: 5.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-78 {
      font-size: 4.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-78 {
      font-size: 3.8rem; } }

.f-80 {
  font-size: 8rem; }
  @media only screen and (max-width: 1200px) {
    .f-80 {
      font-size: 5rem; } }
  @media only screen and (max-width: 800px) {
    .f-80 {
      font-size: 4.5rem; } }
  @media only screen and (max-width: 600px) {
    .f-80 {
      font-size: 4rem;
      line-height: 1.5 !important; } }
  @media only screen and (max-width: 460px) {
    .f-80 {
      font-size: 3rem;
      line-height: 1.5 !important; } }

.f-82 {
  font-size: 8.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-82 {
      font-size: 7.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-82 {
      font-size: 6.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-82 {
      font-size: 5.2rem; } }

.f-84 {
  font-size: 8.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-84 {
      font-size: 7.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-84 {
      font-size: 6.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-84 {
      font-size: 5.4rem; } }

.f-86 {
  font-size: 8.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-86 {
      font-size: 7.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-86 {
      font-size: 6.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-86 {
      font-size: 5.6rem; } }

.f-88 {
  font-size: 8.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-88 {
      font-size: 7.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-88 {
      font-size: 6.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-88 {
      font-size: 5.8rem; } }

.f-90 {
  font-size: 9rem; }
  @media only screen and (max-width: 1200px) {
    .f-90 {
      font-size: 8rem; } }
  @media only screen and (max-width: 800px) {
    .f-90 {
      font-size: 7rem; } }
  @media only screen and (max-width: 600px) {
    .f-90 {
      font-size: 6rem; } }

.f-92 {
  font-size: 9.2rem; }
  @media only screen and (max-width: 1200px) {
    .f-92 {
      font-size: 8.2rem; } }
  @media only screen and (max-width: 800px) {
    .f-92 {
      font-size: 7.2rem; } }
  @media only screen and (max-width: 600px) {
    .f-92 {
      font-size: 6.2rem; } }

.f-94 {
  font-size: 9.4rem; }
  @media only screen and (max-width: 1200px) {
    .f-94 {
      font-size: 8.4rem; } }
  @media only screen and (max-width: 800px) {
    .f-94 {
      font-size: 7.4rem; } }
  @media only screen and (max-width: 600px) {
    .f-94 {
      font-size: 6.4rem; } }

.f-96 {
  font-size: 9.6rem; }
  @media only screen and (max-width: 1200px) {
    .f-96 {
      font-size: 8.6rem; } }
  @media only screen and (max-width: 800px) {
    .f-96 {
      font-size: 7.6rem; } }
  @media only screen and (max-width: 600px) {
    .f-96 {
      font-size: 6.6rem; } }
  @media only screen and (max-width: 500px) {
    .f-96 {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 400px) {
    .f-96 {
      font-size: 3.6rem; } }

.f-98 {
  font-size: 9.8rem; }
  @media only screen and (max-width: 1200px) {
    .f-98 {
      font-size: 8.8rem; } }
  @media only screen and (max-width: 800px) {
    .f-98 {
      font-size: 7.8rem; } }
  @media only screen and (max-width: 600px) {
    .f-98 {
      font-size: 6.8rem; } }

.f-100 {
  font-size: 10rem; }
  @media only screen and (max-width: 1200px) {
    .f-100 {
      font-size: 9rem; } }
  @media only screen and (max-width: 800px) {
    .f-100 {
      font-size: 8rem; } }
  @media only screen and (max-width: 600px) {
    .f-100 {
      font-size: 7rem; } }
  @media only screen and (max-width: 460px) {
    .f-100 {
      font-size: 6rem; } }

.f-120 {
  font-size: 12rem; }
  @media only screen and (max-width: 1200px) {
    .f-120 {
      font-size: 11rem; } }
  @media only screen and (max-width: 800px) {
    .f-120 {
      font-size: 10rem; } }
  @media only screen and (max-width: 600px) {
    .f-120 {
      font-size: 9rem; } }
  @media only screen and (max-width: 460px) {
    .f-120 {
      font-size: 7rem; } }

.f-140 {
  font-size: 14rem; }
  @media only screen and (max-width: 1200px) {
    .f-140 {
      font-size: 13rem; } }
  @media only screen and (max-width: 800px) {
    .f-140 {
      font-size: 12rem; } }
  @media only screen and (max-width: 600px) {
    .f-140 {
      font-size: 10rem; } }
  @media only screen and (max-width: 460px) {
    .f-140 {
      font-size: 6rem; } }

.f-160 {
  font-size: 16rem; }
  @media only screen and (max-width: 1200px) {
    .f-160 {
      font-size: 14rem; } }
  @media only screen and (max-width: 800px) {
    .f-160 {
      font-size: 14rem; } }
  @media only screen and (max-width: 600px) {
    .f-160 {
      font-size: 13rem; } }
  @media only screen and (max-width: 460px) {
    .f-160 {
      font-size: 8rem; } }

.f-180 {
  font-size: 18rem; }
  @media only screen and (max-width: 1200px) {
    .f-180 {
      font-size: 16rem; } }
  @media only screen and (max-width: 800px) {
    .f-180 {
      font-size: 15rem; } }
  @media only screen and (max-width: 600px) {
    .f-180 {
      font-size: 14rem; } }
  @media only screen and (max-width: 460px) {
    .f-180 {
      font-size: 9rem; } }

.f-200 {
  font-size: 20rem; }
  @media only screen and (max-width: 1200px) {
    .f-200 {
      font-size: 16rem; } }
  @media only screen and (max-width: 800px) {
    .f-200 {
      font-size: 13rem; } }
  @media only screen and (max-width: 600px) {
    .f-200 {
      font-size: 10rem; } }
  @media only screen and (max-width: 460px) {
    .f-200 {
      font-size: 8rem; } }

.wrap-text {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }
  .wrap-text .icon {
    width: 3.2rem;
    display: block;
    margin-right: 1.2rem; }

.t-white {
  color: white; }

.t-main {
  color: #8146A3; }

.t-second {
  color: #393D48; }

.t-third {
  color: #D22522; }

.t-text-2 {
  color: #040404; }

.t-pink {
  color: #ed907a; }

.t-1D1E41 {
  color: #1d1e41; }

.t-underline {
  text-decoration: underline; }

.t-line {
  position: relative;
  display: inline-block; }
  .t-line::before {
    position: absolute;
    content: "";
    min-width: 48rem;
    width: calc(100% + 3rem);
    height: 2.8rem;
    background: #8146A3;
    bottom: 0%;
    transform: translateY(0%);
    z-index: -1; }
    @media screen and (max-width: 1200px) {
      .t-line::before {
        min-width: 32rem;
        height: 1.4rem; } }
    @media screen and (max-width: 800px) {
      .t-line::before {
        min-width: 26rem;
        height: 1rem; } }
  .t-line.right::before {
    right: 0; }
  .t-line.left::before {
    left: 0; }
  .t-line.line-2 .text::before {
    bottom: 0.8rem;
    top: auto;
    transform: none;
    background: #d9d9d9;
    height: 0.2rem; }
  .t-line.short {
    margin-top: 0; }
    .t-line.short::before, .t-line.short::after {
      position: absolute;
      content: "";
      width: 6.6rem;
      height: 0.1rem;
      background: #8146A3;
      top: 50%;
      transform: translateY(-50%); }
    .t-line.short::before {
      right: calc(100% + 0.9rem); }
    .t-line.short::after {
      left: calc(100% + 0.9rem); }
  .t-line-db {
    position: relative;
    padding-bottom: 1rem; }
    .t-line-db::before, .t-line-db:after {
      position: absolute;
      content: "";
      width: 5rem;
      height: 0.2rem;
      background: #393D48;
      left: 50%; }
    .t-line-db::before {
      bottom: 0;
      transform: translateX(-10%); }
    .t-line-db::after {
      bottom: 0.5rem;
      transform: translateX(-60%); }
    .t-line-db.line-db-2::after {
      left: 0;
      transform: none; }
    .t-line-db.line-db-2::before {
      left: 0;
      transform: translateX(50%); }

.t-break {
  white-space: pre-line; }

.t-stroke {
  text-shadow: 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem black, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white;
  -webkit-font-smoothing: antialiased;
  color: #393D48; }

.t-pre-line {
  white-space: pre-line; }

.t-sp-1 {
  letter-spacing: 0.06em; }

.t-sd-1 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.t-font-2 {
  font-family: "SVN-Taken by Vultures"; }

.t-pre-lỉne {
  white-space: pre-line; }

.t-linear {
  background: linear-gradient(90deg, #39b16c 2.67%, #38af6b 3.67%, #255361 71.67%, #1e2f5e 102.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.text-line {
  padding-bottom: 2rem;
  position: relative; }
  .text-line::before {
    position: absolute;
    content: "";
    height: 0.1rem;
    width: 12rem;
    background: linear-gradient(0deg, #0eb252 47.37%, #72c595 102.98%);
    opacity: 0.5;
    bottom: 0;
    left: 0; }
    @media screen and (max-width: 450px) {
      .text-line::before {
        left: 50%;
        transform: translateX(-50%); } }

.fw-1 {
  font-weight: 100; }

.fw-2 {
  font-weight: 200; }

.fw-4 {
  font-weight: 400; }

.fw-3 {
  font-weight: 300; }

.fw-5 {
  font-weight: 500; }

.fw-6 {
  font-weight: 600; }

.fw-7 {
  font-weight: 700; }

.fw-8 {
  font-weight: 800; }

.fw-b {
  font-weight: bold; }

.fw-i {
  font-style: italic; }

.t-upper {
  text-transform: uppercase; }

.t-non-upper {
  text-transform: unset; }

.title-box {
  padding: 2.6rem 2.4rem 2.5rem;
  background: #ffffff;
  border-radius: 0.4rem 12rem 12rem 0.4rem;
  width: 100%;
  filter: drop-shadow(0px 0.8rem 1.6rem rgba(0, 0, 0, 0.05));
  border-left: 0.4rem solid #8146A3; }
  @media screen and (max-width: 800px) {
    .title-box {
      padding: 1.6rem 1.2rem 1.5rem; } }

.decor-text {
  position: relative;
  width: fit-content;
  padding-bottom: 0.8rem;
  padding-top: 0.6rem; }
  @media screen and (max-width: 800px) {
    .decor-text {
      padding-bottom: 0.3rem; } }
  .decor-text::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.1rem;
    background: #8146A3;
    bottom: 0;
    transition: all 0.3s ease-in-out; }
  .decor-text::after {
    position: absolute;
    content: "";
    width: 2rem;
    height: 0.8rem;
    border-top: 0.1rem solid #8146A3;
    border-left: 0.1rem solid #8146A3;
    top: 0;
    left: -1rem;
    transition: all 0.3s ease-in-out; }
    @media screen and (max-width: 800px) {
      .decor-text::after {
        left: -0.4rem; } }
  .decor-text.hover::before {
    width: 0;
    visibility: hidden; }
  .decor-text.hover::after {
    width: 0;
    height: 0;
    left: 0;
    visibility: hidden; }
  .decor-text.hover:hover {
    color: #8146A3;
    padding-left: 1rem; }
    .decor-text.hover:hover::before {
      width: calc(100% - 1rem);
      visibility: visible; }
    .decor-text.hover:hover::after {
      width: 2rem;
      height: 0.8rem;
      visibility: visible; }
  .decor-text.white::before {
    background: white; }
  .decor-text.white::after {
    border-color: white; }

.video {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: var(--size-pd); }
  @media screen and (max-width: 1200px) {
    .video {
      padding-bottom: var(--size-pd-tl); } }
  @media screen and (max-width: 600px) {
    .video {
      padding-bottom: var(--size-pd-mb); } }
  .video_audio {
    margin-bottom: 2.4rem;
    padding-top: calc(100% * 540 / 960);
    width: 100%;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .video_audio {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 460px) {
      .video_audio {
        padding-top: 100%; } }
    .video_audio img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .video_audio iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .video_content {
    display: flex;
    flex-direction: column;
    row-gap: 2rem; }
    @media screen and (max-width: 1200px) {
      .video_content {
        row-gap: 1.2rem; } }
    .video_content-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 2.4rem; }
      @media screen and (max-width: 1400px) {
        .video_content-wrap {
          row-gap: 2rem; } }
      @media screen and (max-width: 1200px) {
        .video_content-wrap {
          row-gap: 1.6rem; } }
      @media screen and (max-width: 860px) {
        .video_content-wrap {
          row-gap: 1.4rem; } }
      @media screen and (max-width: 600px) {
        .video_content-wrap {
          row-gap: 1.2rem; } }
  .video_game {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd); }
    @media screen and (max-width: 1200px) {
      .video_game {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pd-tl); } }
    @media screen and (max-width: 600px) {
      .video_game {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pd-mb); } }
    .video_game--wrap {
      display: flex;
      flex-direction: column;
      row-gap: 2.4rem; }
      @media screen and (max-width: 1400px) {
        .video_game--wrap {
          row-gap: 1.6rem; } }
      @media screen and (max-width: 600px) {
        .video_game--wrap {
          row-gap: 1.2rem; } }
    .video_game--box {
      padding: 2.4rem 3.2rem;
      border: 0.1rem solid #999;
      border-radius: 0.6rem; }
      @media screen and (max-width: 1400px) {
        .video_game--box {
          padding: 2rem 1.2rem; } }
      @media screen and (max-width: 1200px) {
        .video_game--box {
          padding: 1.2rem 1.2rem; } }
      .video_game--box-wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 2rem; }
        @media screen and (max-width: 1200px) {
          .video_game--box-wrap {
            column-gap: 1rem; } }
        @media screen and (max-width: 460px) {
          .video_game--box-wrap {
            flex-direction: column;
            row-gap: 1.2rem;
            align-items: start; } }
    .video_game--lf {
      width: 100%; }
      .video_game--lf-wrap {
        display: flex;
        column-gap: 3rem;
        width: 100%; }
        @media screen and (max-width: 1400px) {
          .video_game--lf-wrap {
            column-gap: 2rem; } }
        @media screen and (max-width: 1200px) {
          .video_game--lf-wrap {
            column-gap: 1.2rem; } }
        @media screen and (max-width: 360px) {
          .video_game--lf-wrap {
            row-gap: 1.2rem;
            flex-direction: column; } }
        @media (min-width: 1201px) {
          .video_game--lf-wrap:hover .video_game--lf-img img {
            transform: translate(-50%, -50%) scale(1.1); }
          .video_game--lf-wrap:hover .video_game--lf-info .title-mn {
            color: #4D2A62; } }
      .video_game--lf-info {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem; }
        .video_game--lf-info .title-mn {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          transition: all 0.3s linear; }
        .video_game--lf-info .note-text {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; }
      .video_game--lf-thumbs {
        max-width: 19rem;
        width: 100%;
        display: block;
        flex-shrink: 0; }
        @media screen and (max-width: 1200px) {
          .video_game--lf-thumbs {
            max-width: 12rem; } }
        @media screen and (max-width: 600px) {
          .video_game--lf-thumbs {
            max-width: 10rem; } }
        @media screen and (max-width: 360px) {
          .video_game--lf-thumbs {
            max-width: none; } }
      .video_game--lf-img {
        padding-top: calc(100% * 119 / 190);
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
        height: 100%;
        border-radius: 0.6rem; }
        .video_game--lf-img img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s linear; }
        @media screen and (max-width: 460px) {
          .video_game--lf-img {
            padding-top: 100%; } }
        @media screen and (max-width: 360px) {
          .video_game--lf-img {
            padding-top: calc(100% * 119 / 190); } }
    .video_game--rt {
      width: fit-content; }
      @media screen and (max-width: 460px) {
        .video_game--rt {
          width: 100%; } }
      @media screen and (max-width: 460px) {
        .video_game--rt .btn {
          width: 100%; } }
  .video_lq {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    @media screen and (max-width: 1400px) {
      .video_lq {
        row-gap: 2rem; } }
    @media screen and (max-width: 1200px) {
      .video_lq {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 860px) {
      .video_lq {
        row-gap: 1.2rem; } }
    .video_lq--list {
      display: flex;
      flex-direction: column;
      row-gap: 2.4rem; }
      @media screen and (max-width: 1400px) {
        .video_lq--list {
          row-gap: 1.2rem; } }
      @media screen and (max-width: 860px) {
        .video_lq--list {
          gap: 1.2rem;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between; } }
    .video_lq--item:not(:last-child) {
      padding-bottom: 2.4rem;
      border-bottom: 0.1rem solid #D9D9D9; }
      @media screen and (max-width: 1400px) {
        .video_lq--item:not(:last-child) {
          padding-bottom: 1.2rem; } }
      @media screen and (max-width: 860px) {
        .video_lq--item:not(:last-child) {
          padding-bottom: 0;
          border-bottom: none; } }
    @media screen and (max-width: 860px) {
      .video_lq--item {
        width: calc(50% - 0.6rem); } }
    @media screen and (max-width: 460px) {
      .video_lq--item {
        width: 100%; } }
    .video_lq--link {
      display: flex;
      column-gap: 2.4rem;
      align-items: center; }
      @media screen and (max-width: 1600px) {
        .video_lq--link {
          column-gap: 1.6rem; } }
      @media screen and (max-width: 1400px) {
        .video_lq--link {
          column-gap: 1.2rem; } }
      @media (min-width: 1201px) {
        .video_lq--link:hover .video_lq--img img {
          transform: translate(-50%, -50%) scale(1.2); } }
    .video_lq--lf {
      max-width: 11.2rem;
      width: 100%;
      flex-shrink: 0; }
      @media screen and (max-width: 1200px) {
        .video_lq--lf {
          max-width: 9.2rem; } }
    .video_lq--img {
      padding-top: calc(100% * 74 / 112);
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 0.4rem;
      display: block; }
      .video_lq--img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s linear; }
    .video_lq--rt .note-md {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .video_pb {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    .video_pb--list {
      display: flex;
      flex-direction: column;
      row-gap: 2.4rem; }
      @media screen and (max-width: 1200px) {
        .video_pb--list {
          row-gap: 2rem; } }
      @media screen and (max-width: 860px) {
        .video_pb--list {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 1.2rem; } }
    @media screen and (max-width: 860px) {
      .video_pb--item {
        width: calc(50% - 0.6rem); } }
    .video_pb--link {
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;
      height: 100%;
      justify-content: space-between; }
      @media screen and (max-width: 1200px) {
        .video_pb--link {
          row-gap: 0.8rem; } }
      .video_pb--link .note-md {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden; }
      .video_pb--link-wrap {
        display: flex;
        column-gap: 0.8rem;
        align-items: center; }
      .video_pb--link-ic {
        width: 1.6rem;
        height: 1.6rem;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .video_pb--link-ic img {
          width: 100%;
          height: 100%;
          object-fit: cover; }

.comment {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  --commentRow: 1.6rem;
  --widthImg: 4.8rem;
  --commentCol: 1.6rem;
  --commentPd: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .comment {
      --widthImg: 3.6rem;
      --commentRow: 1.2rem;
      --commentCol: 1.2rem;
      --commentPd: 4.8rem;
      padding-top: var(--size-pd-tl);
      padding-bottom: var(--size-pd-tl);
      row-gap: 1.2rem; } }
  @media screen and (max-width: 600px) {
    .comment {
      padding-top: var(--size-pd-mb);
      padding-bottom: var(--size-pd-mb); } }
  @media screen and (max-width: 460px) {
    .comment {
      --widthImg: 3.2rem;
      --commentRow: 0.6rem;
      --commentCol: 0.6rem;
      --commentPd: 3.8rem;
      row-gap: 0.8rem; } }
  .comment-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem; }
  .comment-nav-prev {
    font-size: 1.6rem; }
    @media only screen and (max-width: 1200px) {
      .comment-nav-prev {
        font-size: 1.4rem; } }
    @media screen and (max-width: 320px) {
      .comment-nav-prev {
        max-width: 10rem; } }
  .comment-nav-next {
    font-size: 1.6rem; }
    @media only screen and (max-width: 1200px) {
      .comment-nav-next {
        font-size: 1.4rem; } }
    @media screen and (max-width: 320px) {
      .comment-nav-next {
        max-width: 10rem; } }
  .comment_title {
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem solid #D9D9D9; }
    @media screen and (max-width: 1200px) {
      .comment_title {
        padding-bottom: 1.2rem; } }
    @media screen and (max-width: 460px) {
      .comment_title {
        padding-bottom: 0.8rem; } }
  .comment_box {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem; }
    @media screen and (max-width: 1200px) {
      .comment_box {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 460px) {
      .comment_box {
        row-gap: 0.8rem; } }
    .comment_box .btn {
      margin-left: auto;
      width: fit-content;
      min-width: 6rem; }
      @media screen and (max-width: 1200px) {
        .comment_box .btn {
          min-width: 4rem;
          height: 3rem; } }
    .comment_box--title .note-text a {
      color: #ED1D24;
      text-decoration: underline; }
    .comment_box--input {
      width: 100%; }
      .comment_box--input-wrap {
        width: 100%;
        padding: 1.4rem 1.6rem;
        border-radius: 0.6rem;
        border: 0.1rem solid #C7C9D9;
        box-shadow: 0.1rem 0.2rem 0.3rem 0px rgba(0, 0, 0, 0.08) inset;
        display: flex;
        flex-direction: column;
        position: relative; }
        @media screen and (max-width: 1200px) {
          .comment_box--input-wrap {
            padding: 1.2rem 1.2rem; } }
        @media screen and (max-width: 600px) {
          .comment_box--input-wrap {
            padding: 0.8rem 1rem; } }
      .comment_box--input-ex {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #ED1D24;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -0.6rem;
        right: -0.2rem;
        z-index: 1;
        cursor: pointer; }
        .comment_box--input-ex i {
          font-size: 1rem;
          color: #ffffff; }
        @media screen and (max-width: 1200px) {
          .comment_box--input-ex {
            width: 1.6rem;
            height: 1.6rem;
            right: 0rem; } }
        @media screen and (max-width: 600px) {
          .comment_box--input-ex {
            width: 1.2rem;
            height: 1.2rem;
            right: 0.3rem;
            top: -0.3rem; } }
      .comment_box--input-ip {
        width: 100%; }
        .comment_box--input-ip .emojionearea .emojionearea-button {
          position: absolute;
          top: 50%;
          right: 0rem;
          transform: translateY(-50%);
          width: 2.4rem;
          height: 2.4rem;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media screen and (max-width: 1200px) {
            .comment_box--input-ip .emojionearea .emojionearea-button {
              opacity: 1; } }
          @media screen and (max-width: 460px) {
            .comment_box--input-ip .emojionearea .emojionearea-button {
              width: 1.6rem;
              height: 1.6rem; } }
          @media screen and (max-width: 460px) {
            .comment_box--input-ip .emojionearea .emojionearea-button {
              top: 50%; } }
          .comment_box--input-ip .emojionearea .emojionearea-button > div {
            width: 2.4rem;
            height: 2.4rem;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url(../assets/images/smile.svg) !important;
            background-position: center center; }
            @media screen and (max-width: 460px) {
              .comment_box--input-ip .emojionearea .emojionearea-button > div {
                width: 1.6rem;
                height: 1.6rem; } }
        .comment_box--input-ip .emojionearea .emojionearea-editor {
          min-height: 2.2rem;
          padding: 0;
          color: #393d48;
          font-size: 1.6rem;
          width: 95%;
          padding-right: 2rem;
          overflow-y: auto;
          max-height: 6rem; }
          @media only screen and (max-width: 1200px) {
            .comment_box--input-ip .emojionearea .emojionearea-editor {
              font-size: 1.4rem; } }
          @media screen and (max-width: 860px) {
            .comment_box--input-ip .emojionearea .emojionearea-editor {
              width: 90%;
              min-height: 2rem; } }
          @media screen and (max-width: 460px) {
            .comment_box--input-ip .emojionearea .emojionearea-editor {
              font-size: 1.2rem;
              max-height: 3.2rem;
              min-height: 1.6rem; } }
        .comment_box--input-ip .emojionearea {
          box-shadow: unset !important;
          border: none !important;
          position: relative;
          z-index: 100; }
        .comment_box--input-ip .emojionearea .emojionearea-editor:empty:before {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; }
        .comment_box--input-ip textarea {
          font-family: var(--font-pri);
          font-size: 1.6rem;
          color: #393d48;
          width: 100%;
          line-height: 100%;
          padding: 0;
          min-height: 5.2rem !important;
          height: 100%; }
          @media only screen and (max-width: 1200px) {
            .comment_box--input-ip textarea {
              font-size: 1.4rem; } }
          .comment_box--input-ip textarea::placeholder {
            color: #C7C9D9;
            line-height: 100%; }
      .comment_box--input-btn {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        column-gap: 0.8rem; }
      .comment_box--input-ic {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        cursor: pointer;
        opacity: 0.6;
        transition: all 0.3s ease-in-out; }
        .comment_box--input-ic input {
          display: none; }
        @media (min-width: 1201px) {
          .comment_box--input-ic:hover {
            opacity: 1; } }
        @media screen and (max-width: 1200px) {
          .comment_box--input-ic {
            opacity: 1; } }
        @media screen and (max-width: 460px) {
          .comment_box--input-ic {
            width: 1.6rem;
            height: 1.6rem; } }
        .comment_box--input-ic img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .comment_box--input-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;
        position: relative;
        z-index: 10;
        width: 100%; }
        @media screen and (max-width: 600px) {
          .comment_box--input-top {
            column-gap: 0.5rem; } }
      .comment_box--input-bottom {
        position: relative;
        z-index: 1; }
      .comment_box--input-list {
        display: flex;
        align-items: center;
        row-gap: 1rem; }
        .comment_box--input-list.d-wrap {
          margin-left: -0.8rem;
          margin-right: -0.8rem; }
          @media screen and (max-width: 600px) {
            .comment_box--input-list.d-wrap {
              margin-left: -0.6rem;
              margin-right: -0.6rem; } }
        .comment_box--input-list .d-item {
          padding-left: 0.8rem;
          padding-right: 0.8rem; }
          @media screen and (max-width: 600px) {
            .comment_box--input-list .d-item {
              padding-left: 0.6rem;
              padding-right: 0.6rem; } }
      .comment_box--input-item {
        width: calc(100% / 5);
        position: relative;
        margin-top: 2rem; }
      .comment_box--input-img {
        padding-top: 100%;
        position: relative;
        overflow: hidden;
        display: block; }
        .comment_box--input-img img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .comment_cmt {
    display: flex;
    flex-direction: column;
    row-gap: 2.2rem; }
    @media screen and (max-width: 1400px) {
      .comment_cmt {
        row-gap: 1.8rem; } }
    @media screen and (max-width: 1200px) {
      .comment_cmt {
        row-gap: 1.4rem; } }
    .comment_cmt-filter-wrap {
      max-width: 20rem;
      width: 100%;
      display: flex;
      column-gap: 0.6rem;
      align-items: end; }
      .comment_cmt-filter-wrap .note-text {
        flex-shrink: 0; }
    .comment_cmt--img-list {
      display: none; }
      .comment_cmt--img-list.showed {
        display: flex; }
      .comment_cmt--img-list.d-wrap {
        margin-left: -0.6rem;
        margin-right: -0.6rem; }
      .comment_cmt--img-list .d-item {
        padding-left: 0.6rem;
        padding-right: 0.6rem; }
    .comment_cmt--img-wrap {
      width: 100%;
      position: relative; }
    .comment_cmt--img-sl {
      display: flex;
      align-items: center;
      column-gap: 0.1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      display: none;
      user-select: none;
      pointer-events: none; }
    .comment_cmt--img-bg {
      padding-top: 100%;
      position: relative;
      overflow: hidden;
      display: block; }
      .comment_cmt--img-bg::after {
        content: "";
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        display: none;
        z-index: 2; }
      .comment_cmt--img-bg img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .comment_cmt--list {
      display: flex;
      flex-direction: column;
      row-gap: 3.2rem; }
      @media screen and (max-width: 1200px) {
        .comment_cmt--list {
          row-gap: 2.4rem; } }
      @media screen and (max-width: 860px) {
        .comment_cmt--list {
          row-gap: 2rem; } }
      @media screen and (max-width: 600px) {
        .comment_cmt--list {
          row-gap: 1.6rem; } }
    .comment_cmt--item {
      display: flex;
      flex-direction: column;
      row-gap: var(--commentRow); }
      .comment_cmt--item.end .comment_cmt--item-wrap::before {
        display: none; }
      .comment_cmt--item-wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--commentRow);
        position: relative; }
        .comment_cmt--item-wrap::before {
          content: "";
          position: absolute;
          top: calc(var(--widthImg) + 2rem);
          left: calc(var(--widthImg) / 2);
          width: 0.1rem;
          height: calc(100% + var(--commentRow) - (var(--widthImg) - 1rem));
          background: #999; }
          @media screen and (max-width: 460px) {
            .comment_cmt--item-wrap::before {
              height: calc(100% + var(--commentRow) - (var(--widthImg) - 1rem)); } }
      .comment_cmt--item > .comment_cmt--list {
        padding-left: var(--commentPd); }
        .comment_cmt--item > .comment_cmt--list .comment_cmt--item {
          border-bottom: none !important; }
          .comment_cmt--item > .comment_cmt--list .comment_cmt--item .comment_cmt--item-ava {
            position: relative; }
            .comment_cmt--item > .comment_cmt--list .comment_cmt--item .comment_cmt--item-ava::before {
              content: "";
              position: absolute;
              left: -4rem;
              top: 50%;
              width: calc(var(--widthImg) - 1.8rem);
              height: 0.1rem;
              background: #999; }
              @media screen and (max-width: 1200px) {
                .comment_cmt--item > .comment_cmt--list .comment_cmt--item .comment_cmt--item-ava::before {
                  left: -3rem; } }
              @media screen and (max-width: 460px) {
                .comment_cmt--item > .comment_cmt--list .comment_cmt--item .comment_cmt--item-ava::before {
                  left: -2.2rem; } }
          .comment_cmt--item > .comment_cmt--list .comment_cmt--item > .comment_cmt--list .comment_cmt--item {
            margin-bottom: 1.6rem; }
            .comment_cmt--item > .comment_cmt--list .comment_cmt--item > .comment_cmt--list .comment_cmt--item:first-child .comment_cmt--item-ava::before {
              display: block; }
            .comment_cmt--item > .comment_cmt--list .comment_cmt--item > .comment_cmt--list .comment_cmt--item:last-child {
              margin-bottom: 0; }
            .comment_cmt--item > .comment_cmt--list .comment_cmt--item > .comment_cmt--list .comment_cmt--item .comment_cmt--item-ava::before {
              display: none; }
      .comment_cmt--item-title {
        display: flex;
        align-items: center;
        column-gap: var(--commentCol); }
      .comment_cmt--item-ava {
        position: relative;
        flex-shrink: 0; }
      @media screen and (max-width: 1200px) {
        .comment_cmt--item-info .note-mn:first-child {
          transform: translateY(0.3rem); }
        .comment_cmt--item-info .note-mn:last-child {
          transform: translateY(-0.3rem); } }
      .comment_cmt--item-img {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        overflow: hidden; }
        @media screen and (max-width: 1200px) {
          .comment_cmt--item-img {
            width: 3.6rem;
            height: 3.6rem; } }
        @media screen and (max-width: 460px) {
          .comment_cmt--item-img {
            width: 3.2rem;
            height: 3.2rem; } }
        .comment_cmt--item-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .comment_cmt--item-ic {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: -0.5rem;
        right: -0.5rem; }
        @media screen and (max-width: 1200px) {
          .comment_cmt--item-ic {
            width: 1.6rem;
            height: 1.6rem; } }
        @media screen and (max-width: 460px) {
          .comment_cmt--item-ic {
            width: 1.5rem;
            height: 1.5rem; } }
        .comment_cmt--item-ic img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .comment_cmt--item-box {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        padding-left: var(--commentPd);
        padding-right: 1.2rem; }
        @media screen and (max-width: 1200px) {
          .comment_cmt--item-box {
            padding-left: var(--commentPd);
            padding-right: 1.2rem; } }
        @media screen and (max-width: 600px) {
          .comment_cmt--item-box {
            padding-left: var(--commentPd);
            padding-right: 0rem; } }
      .comment_cmt--item-content {
        display: flex;
        flex-direction: column;
        row-gap: 2rem; }
        .comment_cmt--item-content .note-text {
          transition: all 0.3s linear; }
          .comment_cmt--item-content .note-text.rg {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7; }
          @media screen and (max-width: 460px) {
            .comment_cmt--item-content .note-text {
              font-size: 1.2rem; } }
        .comment_cmt--item-content .btn-see {
          width: fit-content;
          transition: all 0.3s linear;
          display: none; }
          .comment_cmt--item-content .btn-see.showed {
            display: block; }
          @media (min-width: 1201px) {
            .comment_cmt--item-content .btn-see:hover {
              color: #999; } }
      .comment_cmt--item-control {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem; }
        .comment_cmt--item-control.actived {
          z-index: 1100; }
          .comment_cmt--item-control.actived .comment_cmt--item-control-bottom {
            position: relative;
            z-index: 2100; }
        .comment_cmt--item-control-top {
          display: flex;
          align-items: center;
          column-gap: 3.2rem; }
          @media screen and (max-width: 1200px) {
            .comment_cmt--item-control-top {
              row-gap: 2.4rem; } }
          .comment_cmt--item-control-top .note-text {
            cursor: pointer;
            transition: all 0.3s linear; }
            @media (min-width: 1201px) {
              .comment_cmt--item-control-top .note-text:hover {
                color: #999; } }
            .comment_cmt--item-control-top .note-text.showed {
              display: block !important; }
            .comment_cmt--item-control-top .note-text.btnControlRep {
              display: none; }
            .comment_cmt--item-control-top .note-text.btnControlClose {
              display: none; }
        .comment_cmt--item-control-bottom {
          display: none;
          opacity: 0; }

@keyframes anHienBt {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }
          .comment_cmt--item-control-bottom.showed {
            display: block;
            animation: anHienBt 0.8s linear forwards; }
        .comment_cmt--item-control-modal {
          position: fixed;
          inset: 0;
          z-index: -1;
          background: transparent;
          visibility: hidden;
          pointer-events: none;
          width: 100%;
          height: 100%; }
          .comment_cmt--item-control-modal.actived {
            visibility: visible;
            pointer-events: visible; }

.like {
  display: flex;
  align-items: center;
  column-gap: 0.8rem; }
  .like input {
    display: none; }
    .like input:checked ~ .like-ic i {
      font-weight: 900; }
  .like-ic {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .like-ic i {
      font-size: 2rem;
      line-height: 100%;
      transform: translateY(-0.2rem);
      color: #8146A3; }
      @media only screen and (max-width: 1200px) {
        .like-ic i {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 600px) {
        .like-ic i {
          font-size: 1.6rem; } }
  .like-sl {
    color: #000000;
    font-size: 1.6rem; }
    @media only screen and (max-width: 1200px) {
      .like-sl {
        font-size: 1.4rem; } }

.post {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem; }
  @media screen and (max-width: 1400px) {
    .post {
      row-gap: 2rem; } }
  @media screen and (max-width: 1200px) {
    .post {
      row-gap: 1.6rem; } }
  @media screen and (max-width: 860px) {
    .post {
      row-gap: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .post {
      row-gap: 1.2rem; } }
  @media screen and (max-width: 460px) {
    .post {
      row-gap: 1rem; } }
  .post .title-main {
    margin-bottom: 0; }
  .post_share {
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    margin-top: 2rem; }
    @media screen and (max-width: 1200px) {
      .post_share {
        margin-top: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .post_share {
        margin-top: 1.2rem; } }
    .post_share .note-text {
      flex-shrink: 0; }
    .post_share--list {
      display: flex;
      align-items: center;
      column-gap: 1.2rem; }
    .post_share--item {
      width: 1.8rem;
      height: 1.8rem; }
    .post_share--link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      @media (min-width: 1201px) {
        .post_share--link:hover img {
          transform: scale(1.2); } }
      .post_share--link img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s linear; }
  .post_ct {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    margin-bottom: 2.4rem; }
    .post_ct .wp-caption {
      max-width: unset;
      width: 100% !important;
      padding: 0;
      margin: 0 !important; }
      .post_ct .wp-caption img {
        width: 100%;
        height: 100%;
        display: block; }
    .post_ct .wp-caption p.wp-caption-text {
      margin-top: 1rem; }
    .post_ct i {
      font-style: normal !important; }
    .post_ct h2 {
      margin: unset !important; }
      @media only screen and (max-width: 1200px) {
        .post_ct h2 {
          font-size: 2.4rem !important; } }
      @media only screen and (max-width: 860px) {
        .post_ct h2 {
          font-size: 2rem !important; } }
    @media screen and (max-width: 1400px) {
      .post_ct {
        row-gap: 2rem;
        margin-bottom: 2rem; } }
    @media screen and (max-width: 1200px) {
      .post_ct {
        row-gap: 1.6rem;
        margin-bottom: 1.6rem; } }
    @media screen and (max-width: 860px) {
      .post_ct {
        row-gap: 1.4rem;
        margin-bottom: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .post_ct {
        row-gap: 1.2rem;
        margin-bottom: 1.2rem; } }
    .post_ct--img {
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem; }
      .post_ct--img img {
        width: 100%;
        height: 100%; }
      .post_ct--img .note-sm {
        text-align: center;
        font-style: italic;
        display: block;
        width: 100%; }
    .post_ct iframe {
      width: 100%;
      height: calc(100vw * 600 / 1728);
      margin-bottom: 2rem; }
  .post_tab.ogran .post_tab--item.actived .post_tab--link {
    background: #d97706;
    color: #fefefe; }
  .post_tab.ogran .post_tab--item.actived .post_tab--ic img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); }
  @media (min-width: 1201px) {
    .post_tab.ogran .post_tab--link:hover {
      background: #d97706;
      color: #fefefe; }
      .post_tab.ogran .post_tab--link:hover .post_tab--ic img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); } }
  .post_tab.green .post_tab--item.actived .post_tab--link {
    background: #047857;
    color: #fefefe; }
  .post_tab.green .post_tab--item.actived .post_tab--ic img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); }
  @media (min-width: 1201px) {
    .post_tab.green .post_tab--link:hover {
      background: #047857;
      color: #fefefe; }
      .post_tab.green .post_tab--link:hover .post_tab--ic img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); } }
  .post_tab.green2 .post_tab--item.actived .post_tab--link {
    background: #0FB93C;
    color: #fefefe; }
  .post_tab.green2 .post_tab--item.actived .post_tab--ic img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); }
  @media (min-width: 1201px) {
    .post_tab.green2 .post_tab--link:hover {
      background: #0FB93C;
      color: #fefefe; }
      .post_tab.green2 .post_tab--link:hover .post_tab--ic img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); } }
  .post_tab.blue .post_tab--item.actived .post_tab--link {
    background: #1d4ed8;
    color: #fefefe; }
  .post_tab.blue .post_tab--item.actived .post_tab--ic img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); }
  @media (min-width: 1201px) {
    .post_tab.blue .post_tab--link:hover {
      background: #1d4ed8;
      color: #fefefe; }
      .post_tab.blue .post_tab--link:hover .post_tab--ic img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); } }
  .post_tab.red .post_tab--item.actived .post_tab--link {
    background: #ED1D24;
    color: #fefefe; }
  .post_tab.red .post_tab--item.actived .post_tab--ic img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); }
  @media (min-width: 1201px) {
    .post_tab.red .post_tab--link:hover {
      background: #ED1D24;
      color: #fefefe; }
      .post_tab.red .post_tab--link:hover .post_tab--ic img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); } }
  .post_tab--list {
    display: flex; }
    .post_tab--list.d-wrap {
      margin-left: -0.6rem;
      margin-right: -0.6rem; }
    .post_tab--list .d-item {
      padding-left: 0.6rem;
      padding-right: 0.6rem; }
    .post_tab--list .swiper-slide {
      height: auto;
      width: fit-content; }
  .post_tab--item {
    height: 100%; }
    .post_tab--item.actived .post_tab--link {
      background: #8146A3;
      color: #fefefe; }
    .post_tab--item.actived .post_tab--ic img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); }
  .post_tab--ic {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; }
    .post_tab--ic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0) saturate(100%) invert(21%) sepia(7%) saturate(1268%) hue-rotate(186deg) brightness(97%) contrast(88%); }
    @media screen and (max-width: 600px) {
      .post_tab--ic {
        width: 1.8rem;
        height: 1.8rem; } }
  .post_tab--link {
    transition: none;
    padding: 1.2rem 1.6rem;
    border-radius: 0.6rem;
    background: #D9D9D9;
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    user-select: none;
    font-size: 1.4rem; }
    @media (min-width: 1201px) {
      .post_tab--link:hover {
        background: #8146A3;
        color: #fefefe; }
        .post_tab--link:hover .post_tab--ic img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(87deg) brightness(109%) contrast(111%); } }
    @media screen and (max-width: 1200px) {
      .post_tab--link {
        padding: 1rem 1.4rem;
        column-gap: 0.6rem; } }
    @media screen and (max-width: 600px) {
      .post_tab--link {
        padding: 0.8rem 1.2rem; } }
    @media screen and (max-width: 460px) {
      .post_tab--link {
        padding: 0.6rem 1rem;
        font-size: 1.2rem; } }
  .post-wrap {
    width: 100%; }
  .post_list {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem; }
    @media screen and (max-width: 1200px) {
      .post_list {
        row-gap: 2rem; } }
    @media screen and (max-width: 800px) {
      .post_list {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .post_list {
        row-gap: 1.2rem; } }
  .post_link {
    height: 100%;
    width: 100%; }
    @media (min-width: 1201px) {
      .post_link:hover .post_link--lf-img img {
        transform: translate(-50%, -50%) scale(1.1); } }
    .post_link--wrap {
      width: 100%;
      height: 100%;
      display: flex;
      column-gap: 2rem; }
      @media screen and (max-width: 1400px) {
        .post_link--wrap {
          column-gap: 1.4rem; } }
      @media screen and (max-width: 1200px) {
        .post_link--wrap {
          column-gap: 1.2rem; } }
      @media screen and (max-width: 600px) {
        .post_link--wrap {
          column-gap: 0.6rem; } }
      @media screen and (max-width: 360px) {
        .post_link--wrap {
          flex-direction: column;
          row-gap: 0.6rem; } }
    .post_link--lf {
      flex-shrink: 0;
      width: calc(100% / 3); }
      @media screen and (max-width: 360px) {
        .post_link--lf {
          width: 100%; } }
      .post_link--lf-wrap {
        height: 100%;
        width: 100%; }
      .post_link--lf-img {
        padding-top: calc(100% * 198 / 300);
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        overflow: hidden;
        border-radius: 0.4rem;
        flex-shrink: 0; }
        @media (min-width: 1201px) {
          .post_link--lf-img:hover img {
            transform: translate(-50%, -50%) scale(1.1); } }
        .post_link--lf-img img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s linear; }
    .post_link--rt {
      flex: 1;
      width: 100%;
      overflow: hidden; }
      .post_link--rt-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem; }
        @media screen and (max-width: 1200px) {
          .post_link--rt-wrap {
            row-gap: 0.8rem; } }
        @media screen and (max-width: 600px) {
          .post_link--rt-wrap {
            row-gap: 0.6rem; } }
        .post_link--rt-wrap .title-mn {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .post_link--rt-wrap .note-text {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; }
    .post_link--list {
      display: flex;
      flex-direction: column;
      row-gap: 0.8rem; }
    .post_link--link {
      display: flex;
      align-items: start;
      column-gap: 1rem; }
      .post_link--link .note-sm {
        transition: all 0.3s linear;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; }
      @media (min-width: 1201px) {
        .post_link--link:hover .note-sm {
          color: #9E1C1A; }
        .post_link--link:hover .post_link--ic {
          background: #9E1C1A; }
          .post_link--link:hover .post_link--ic i {
            color: #ffffff; } }
    .post_link--ic {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: #e6e6e6;
      border-radius: 50%;
      overflow: hidden;
      transition: all 0.3s linear;
      transform: translateY(0.1rem); }
      .post_link--ic i {
        font-size: 1.2rem;
        color: #777777;
        transition: all 0.3s linear; }

.news_post {
  width: 100%;
  height: 100%; }
  .news_post--link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
    @media (min-width: 1201px) {
      .news_post--link:hover .news_post--img img {
        transform: translate(-50%, -50%) scale(1.1); }
      .news_post--link:hover .news_post--title .note-md {
        color: #ffffff; } }
  .news_post--img {
    display: block;
    padding-top: 101%;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .news_post--img img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      transition: all 0.3s linear;
      object-position: top; }
  .news_post--title {
    padding: 1rem 1.6rem;
    background: #2f343f;
    min-height: 10rem;
    display: block;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0; }
    .news_post--title .note-md {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .news_post--title {
        padding: 1rem 1.2rem; } }

.news_list {
  row-gap: 3.2rem; }
  @media screen and (max-width: 1400px) {
    .news_list {
      row-gap: 2rem; } }
  @media screen and (max-width: 860px) {
    .news_list {
      row-gap: 1.6rem; } }

.news_lq {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem; }
  @media screen and (max-width: 1400px) {
    .news_lq {
      row-gap: 2rem; } }
  @media screen and (max-width: 1200px) {
    .news_lq {
      row-gap: 1.6rem; } }
  .news_lq--list {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem; }
    @media screen and (max-width: 1400px) {
      .news_lq--list {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 860px) {
      .news_lq--list {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 0.8rem;
        justify-content: space-between; } }
  .news_lq--alert {
    position: absolute;
    top: 2.4rem;
    left: 2.4rem;
    background: #8146A3;
    z-index: 2;
    color: #fefefe;
    font-size: 1.4rem;
    padding: 0.8rem 1rem;
    border-radius: 0.6rem;
    overflow: hidden; }
    @media screen and (max-width: 1400px) {
      .news_lq--alert {
        top: 2rem;
        left: 2rem;
        padding: 0.4rem 0.8rem; } }
    @media screen and (max-width: 1200px) {
      .news_lq--alert {
        top: 1.6rem;
        left: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .news_lq--alert {
        top: 1.6rem;
        left: 1.6rem;
        padding: 0.4rem 0.6rem;
        font-size: 1.2rem; } }
  .news_lq--item {
    overflow: hidden; }
    @media screen and (max-width: 860px) {
      .news_lq--item {
        width: 100%; }
        .news_lq--item:not(:first-child) {
          width: calc(50% - 0.8rem); }
        .news_lq--item:first-child .news_lq--title {
          padding-top: 20rem; } }
    @media screen and (max-width: 600px) {
      .news_lq--item {
        width: 100% !important; }
        .news_lq--item .news_lq--title {
          padding-top: 18rem !important; } }
  .news_lq--link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 0.6rem;
    height: 100%; }
    @media (min-width: 1201px) {
      .news_lq--link:hover .news_lq--bg {
        transform: scale(1.1); } }
  .news_lq--bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s linear; }
    .news_lq--bg::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #242424 49.77%, rgba(36, 36, 36, 0) 100%);
      opacity: 0.5; }
    .news_lq--bg img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top; }
  .news_lq--title {
    display: block;
    padding: 14rem 2.4rem 2.4rem 2.4rem;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 1400px) {
      .news_lq--title {
        padding: 12rem 2rem 2rem 2rem; } }
    @media screen and (max-width: 1200px) {
      .news_lq--title {
        padding: 12rem 1.6rem 1.6rem 1.6rem; } }
    .news_lq--title .note-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      transition: all 0.3s linear; }

.author_bg {
  padding-top: calc(100% * 416 / 1290);
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden; }
  .author_bg img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover; }

.author_wrap {
  max-width: 95%;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 2; }
  @media screen and (max-width: 800px) {
    .author_wrap {
      flex-direction: column;
      row-gap: 0.6rem;
      align-items: center;
      max-width: unset; } }

.author_bio {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  width: fit-content; }
  .author_bio--ic {
    width: 1.6rem;
    height: 1.6rem;
    flex-shrink: 0; }
    .author_bio--ic img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .author_bio .note-text {
    transform: translateY(0.2rem); }

.author_avatar {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .author_avatar--img {
    width: 23rem;
    height: 23rem;
    border-radius: 50%;
    overflow: hidden;
    border: 0.4rem solid #fefefe;
    flex-shrink: 0; }
    @media screen and (max-width: 1200px) {
      .author_avatar--img {
        width: 20rem;
        height: 20rem; } }
    @media screen and (max-width: 600px) {
      .author_avatar--img {
        width: 12rem;
        height: 12rem; } }
    @media screen and (max-width: 460px) {
      .author_avatar--img {
        width: 10rem;
        height: 10rem; } }
    @media screen and (max-width: 360px) {
      .author_avatar--img {
        width: 8rem;
        height: 8rem; } }
    .author_avatar--img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top; }
  .author_avatar--ic {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    position: absolute;
    bottom: 0rem;
    right: 1rem; }
    @media screen and (max-width: 1200px) {
      .author_avatar--ic {
        width: 4rem;
        height: 4rem;
        right: 2.4rem; } }
    @media screen and (max-width: 600px) {
      .author_avatar--ic {
        width: 3rem;
        height: 3rem;
        right: 2.4rem; } }
    @media screen and (max-width: 460px) {
      .author_avatar--ic {
        width: 2.4rem;
        height: 2.4rem;
        right: 2rem; } }
    .author_avatar--ic img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.author_lf {
  width: 26rem; }
  @media screen and (max-width: 1200px) {
    .author_lf {
      width: 24rem; } }
  @media screen and (max-width: 800px) {
    .author_lf {
      width: 22rem; } }
  @media screen and (max-width: 600px) {
    .author_lf {
      width: 15rem; } }
  @media screen and (max-width: 460px) {
    .author_lf {
      width: 12rem; } }
  .author_lf--wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.6rem;
    width: 100%;
    margin-top: -50%; }
    @media screen and (max-width: 800px) {
      .author_lf--wrap {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 360px) {
      .author_lf--wrap {
        margin-top: -40%; } }

.author_rt {
  flex: 1;
  width: 100%; }
  .author_rt--wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    margin-top: -8rem; }
    @media screen and (max-width: 1200px) {
      .author_rt--wrap {
        row-gap: 2rem;
        margin-top: -6rem; } }
    @media screen and (max-width: 800px) {
      .author_rt--wrap {
        row-gap: 1rem;
        margin-top: 0rem; } }
    @media screen and (max-width: 600px) {
      .author_rt--wrap {
        row-gap: 0.6rem;
        margin-top: 0rem; } }

.author_content {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem; }
  @media screen and (max-width: 800px) {
    .author_content {
      align-items: center;
      row-gap: 1.2rem; } }
  @media screen and (max-width: 600px) {
    .author_content {
      align-items: center;
      row-gap: 1rem; } }
  @media screen and (max-width: 460px) {
    .author_content {
      align-items: center;
      row-gap: 0.8rem; } }

.author_info {
  display: flex;
  align-items: center;
  column-gap: 2.4rem; }
  @media screen and (max-width: 800px) {
    .author_info {
      flex-wrap: wrap;
      row-gap: 0.6rem;
      justify-content: center; } }

.author_location {
  display: flex;
  align-items: center;
  column-gap: 0.2rem; }
  .author_location--ic {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0; }
    .author_location--ic i {
      font-size: 2rem; }
      @media only screen and (max-width: 1200px) {
        .author_location--ic i {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 600px) {
        .author_location--ic i {
          font-size: 1.6rem; } }

@media screen and (max-width: 800px) {
  .author_name .title-sm4 {
    color: #393d48;
    text-align: center; } }

.form-item {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem; }
  .form-item-lb {
    font-size: 1.8rem; }
    @media only screen and (max-width: 1200px) {
      .form-item-lb {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .form-item-lb {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 600px) {
      .form-item-lb {
        font-size: 1.4rem; } }
    .form-item-lb strong {
      color: #ED1D24; }
  .form-item .select2-container {
    height: 5.9rem;
    padding: 0rem 2.4rem;
    background: transparent !important;
    border: 0.1rem solid #999 !important;
    border-radius: 0.6rem; }
    @media screen and (max-width: 1200px) {
      .form-item .select2-container {
        padding: 0rem 2rem;
        height: 4.9rem; } }
    @media screen and (max-width: 800px) {
      .form-item .select2-container {
        height: 3.9rem;
        padding: 0rem 1.4rem; } }
    @media screen and (max-width: 600px) {
      .form-item .select2-container {
        padding: 0rem 1.2rem; } }
  .form-item .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 1.8rem;
    color: #393D48 !important;
    font-weight: 400; }
    @media screen and (max-width: 1200px) {
      .form-item .select2-container .select2-selection--single .select2-selection__rendered {
        font-size: 1.6rem !important; } }
    @media screen and (max-width: 800px) {
      .form-item .select2-container .select2-selection--single .select2-selection__rendered {
        font-size: 1.4rem !important; } }
    @media screen and (max-width: 460px) {
      .form-item .select2-container .select2-selection--single .select2-selection__rendered {
        font-size: 1.2rem !important; } }
  .form-item .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);
    right: 2.4rem; }
    @media screen and (max-width: 1200px) {
      .form-item .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: 2rem; } }
    @media screen and (max-width: 800px) {
      .form-item .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: 1.4rem; } }
  .form-item .select2-container--default .select2-selection--single .select2-selection__arrow::before {
    top: 55%; }
    @media screen and (max-width: 800px) {
      .form-item .select2-container--default .select2-selection--single .select2-selection__arrow::before {
        top: 58%; } }
  .form-item-ip {
    width: 100%;
    height: 6rem;
    background: transparent !important;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    padding: 1.6rem 2.4rem;
    color: #393D48;
    border: 0.1rem solid #999 !important;
    transition: all 0.3s linear;
    border-radius: 0.6rem; }
    @media only screen and (max-width: 1200px) {
      .form-item-ip {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .form-item-ip {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 600px) {
      .form-item-ip {
        font-size: 1.4rem; } }
    .form-item-ip:focus {
      border-color: #8146A3 !important; }
    @media screen and (max-width: 1200px) {
      .form-item-ip {
        padding: 1.4rem 2rem;
        font-size: 1.6rem;
        height: 5rem; } }
    @media screen and (max-width: 800px) {
      .form-item-ip {
        height: 4rem;
        padding: 1.2rem 1.4rem;
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .form-item-ip {
        padding: 0rem 1.2rem; } }
    @media screen and (max-width: 460px) {
      .form-item-ip {
        font-size: 1.2rem; } }
    .form-item-ip.cus {
      background: transparent !important;
      color: #ffffff;
      border: none !important; }
    .form-item-ip::placeholder {
      color: #393D48; }
  .form-item-tx {
    width: 100%;
    min-height: 13rem;
    background: transparent !important;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    padding: 1.6rem 2.4rem;
    color: #393D48;
    border: 0.1rem solid #999 !important;
    transition: all 0.3s linear;
    border-radius: 0.6rem; }
    @media only screen and (max-width: 1200px) {
      .form-item-tx {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .form-item-tx {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 600px) {
      .form-item-tx {
        font-size: 1.4rem; } }
    .form-item-tx:focus {
      border-color: #8146A3 !important; }
    @media screen and (max-width: 1200px) {
      .form-item-tx {
        padding: 1.4rem 2rem;
        font-size: 1.6rem;
        min-height: 10rem; } }
    @media screen and (max-width: 800px) {
      .form-item-tx {
        min-height: 8rem;
        padding: 1.2rem 1.4rem;
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .form-item-tx {
        padding: 1.2rem 1.2rem; } }
    @media screen and (max-width: 460px) {
      .form-item-tx {
        font-size: 1.2rem; } }
    .form-item-tx::placeholder {
      color: #393D48; }

.cs {
  padding-top: var(--size-pdl);
  padding-bottom: var(--size-pdl); }
  @media screen and (max-width: 1200px) {
    .cs {
      padding-top: var(--size-pdl-tl);
      padding-bottom: var(--size-pdl-tl); } }
  @media screen and (max-width: 600px) {
    .cs {
      padding-top: var(--size-pdl-mb);
      padding-bottom: var(--size-pdl-mb); } }
  .cs_title {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    margin-bottom: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .cs_title {
        row-gap: 1.4rem;
        margin-bottom: 2.4rem; } }
    @media screen and (max-width: 860px) {
      .cs_title {
        row-gap: 1.2rem;
        margin-bottom: 2rem; } }
    @media screen and (max-width: 600px) {
      .cs_title {
        row-gap: 0.8rem;
        margin-bottom: 1.6rem; } }
  .cs_content {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem; }
    @media screen and (max-width: 1200px) {
      .cs_content {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .cs_content {
        row-gap: 1.2rem; } }
  .cs_text {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem; }
  .cs_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1rem; }
    .cs_wrap.mona-content h2, .cs_wrap.mona-content h3 {
      margin: unset !important; }
      @media only screen and (max-width: 1200px) {
        .cs_wrap.mona-content h2, .cs_wrap.mona-content h3 {
          font-size: 2rem !important; } }
      @media only screen and (max-width: 860px) {
        .cs_wrap.mona-content h2, .cs_wrap.mona-content h3 {
          font-size: 2rem !important; } }
      @media only screen and (max-width: 600px) {
        .cs_wrap.mona-content h2, .cs_wrap.mona-content h3 {
          font-size: 1.6rem !important; } }
    .cs_wrap.mona-content ul {
      margin: unset !important;
      padding-left: 2rem; }
      .cs_wrap.mona-content ul li {
        font-size: 1.6rem; }
        @media only screen and (max-width: 1200px) {
          .cs_wrap.mona-content ul li {
            font-size: 1.4rem; } }

.layout {
  margin-top: -4rem; }
  @media screen and (max-width: 1200px) {
    .layout {
      margin-top: 0; } }
  .layout_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .layout_wrap {
        row-gap: 2.4rem; } }
    @media screen and (max-width: 860px) {
      .layout_wrap {
        row-gap: 1.8rem; } }
    @media screen and (max-width: 600px) {
      .layout_wrap {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 460px) {
      .layout_wrap {
        row-gap: 1.2rem; } }
  .layout_title {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem; }
    @media screen and (max-width: 1200px) {
      .layout_title {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 860px) {
      .layout_title {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 600px) {
      .layout_title {
        row-gap: 1rem; } }
    .layout_title .title-sm {
      max-width: 70rem;
      width: 100%; }
  .layout_date {
    display: flex;
    align-items: center;
    column-gap: 1.6rem; }
    @media screen and (max-width: 1200px) {
      .layout_date {
        flex-direction: column;
        row-gap: 0.6rem;
        align-items: start; } }
    .layout_date.page-content {
      max-width: 64rem;
      width: 100%; }
      .layout_date.page-content .note-text {
        padding-right: 0;
        border-right: none; }
    .layout_date .note-text {
      padding-right: 1.6rem;
      border-right: 0.1rem solid #D9D9D9; }
      @media screen and (max-width: 1200px) {
        .layout_date .note-text {
          border-right: none;
          padding-right: 0; } }

.follow {
  padding-bottom: var(--size-pdl);
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem; }
  @media screen and (max-width: 1200px) {
    .follow {
      row-gap: 2.4rem;
      padding-bottom: var(--size-pdl-tl); } }
  @media screen and (max-width: 860px) {
    .follow {
      row-gap: 1.8rem; } }
  @media screen and (max-width: 600px) {
    .follow {
      row-gap: 1.4rem;
      padding-bottom: var(--size-pdl-mb); } }
  .follow_content {
    display: flex;
    flex-direction: column;
    row-gap: 2rem; }
    @media screen and (max-width: 1200px) {
      .follow_content {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .follow_content {
        row-gap: 1.3rem; } }
  .follow_list {
    display: flex;
    column-gap: 1.4rem;
    flex-wrap: wrap;
    row-gap: 0.6rem; }
  .follow_item {
    flex-shrink: 0; }
  .follow_link {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    transition: all 0.3s linear; }
    @media screen and (max-width: 600px) {
      .follow_link {
        height: 3.2rem; } }
    @media (min-width: 1201px) {
      .follow_link:hover {
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5); } }
    .follow_link img {
      width: 100%;
      height: 100%;
      object-fit: contain; }

.site_map {
  padding-bottom: var(--size-pdl); }
  @media screen and (max-width: 1200px) {
    .site_map {
      padding-bottom: var(--size-pdl-tl); } }
  @media screen and (max-width: 600px) {
    .site_map {
      padding-bottom: var(--size-pdl-mb); } }
  .site_map--list {
    row-gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .site_map--list {
        row-gap: 2.4rem; } }
    @media screen and (max-width: 860px) {
      .site_map--list {
        row-gap: 1.8rem; } }
    @media screen and (max-width: 600px) {
      .site_map--list {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 460px) {
      .site_map--list {
        row-gap: 1.2rem; } }
  .site_map--item-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem; }
    @media screen and (max-width: 1200px) {
      .site_map--item-wrap {
        row-gap: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .site_map--item-wrap {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 460px) {
      .site_map--item-wrap {
        row-gap: 1rem; } }
    .site_map--item-wrap ul {
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      list-style: disc;
      margin-left: 2rem; }
      @media screen and (max-width: 1200px) {
        .site_map--item-wrap ul {
          row-gap: 1.4rem; } }
      @media screen and (max-width: 600px) {
        .site_map--item-wrap ul {
          row-gap: 1.3rem; } }
      @media screen and (max-width: 460px) {
        .site_map--item-wrap ul {
          row-gap: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .site_map--item-wrap ul {
          row-gap: 1rem; } }

.contact {
  padding-bottom: var(--size-pdl); }
  @media screen and (max-width: 1200px) {
    .contact {
      padding-bottom: var(--size-pdl-tl); } }
  @media screen and (max-width: 600px) {
    .contact {
      padding-bottom: var(--size-pdl-mb); } }
  .contact_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem; }
    @media screen and (max-width: 1200px) {
      .contact_wrap {
        row-gap: 2rem; } }
    @media screen and (max-width: 860px) {
      .contact_wrap {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .contact_wrap {
        row-gap: 1.2rem; } }
  .contact_list {
    row-gap: 2.4rem; }
    @media screen and (max-width: 1200px) {
      .contact_list {
        row-gap: 2rem; } }
    @media screen and (max-width: 860px) {
      .contact_list {
        row-gap: 1.6rem; } }
    @media screen and (max-width: 600px) {
      .contact_list {
        row-gap: 1.2rem; } }
    @media screen and (max-width: 460px) {
      .contact_list {
        row-gap: 0.8rem; } }
  @media screen and (max-width: 600px) {
    .contact_item:last-child {
      width: 100%; } }
  @media screen and (max-width: 360px) {
    .contact_item {
      width: 100% !important; } }

.home {
  padding-top: var(--size-pdl);
  padding-bottom: var(--size-pdl); }
  @media screen and (max-width: 1200px) {
    .home {
      padding-top: var(--size-pdl-tl);
      padding-bottom: var(--size-pdl-tl); } }
  @media screen and (max-width: 800px) {
    .home {
      padding-top: var(--size-pdl-tlv);
      padding-bottom: var(--size-pdl-tlv); } }
  @media screen and (max-width: 600px) {
    .home {
      padding-top: var(--size-pdl-mb);
      padding-bottom: var(--size-pdl-mb); } }
  .home_top {
    padding-bottom: var(--size-pd); }
    @media screen and (max-width: 1200px) {
      .home_top {
        padding-bottom: var(--size-pd-tl); } }
    @media screen and (max-width: 600px) {
      .home_top {
        padding-bottom: var(--size-pd-mbs); } }
    .home_top--list {
      overflow: hidden; }
      .home_top--list .swiper-slide {
        height: auto; }
        .home_top--list .swiper-slide:nth-child(4n + 1) .news_post--title {
          background: #047857; }
        .home_top--list .swiper-slide:nth-child(4n + 2) .news_post--title {
          background: #2f343f; }
        .home_top--list .swiper-slide:nth-child(4n + 3) .news_post--title {
          background: #d97706; }
        .home_top--list .swiper-slide:nth-child(4n + 4) .news_post--title {
          background: #673882; }
      .home_top--list .news_post--link {
        height: 100%; }
  .home_story {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .home_story {
        row-gap: 2.4rem; } }
    @media screen and (max-width: 860px) {
      .home_story {
        row-gap: 1.8rem; } }
    @media screen and (max-width: 600px) {
      .home_story {
        row-gap: 1.4rem; } }
    .home_story .title-main {
      margin-bottom: 0; }
    @media screen and (max-width: 1200px) {
      .home_story--wrap {
        row-gap: 1.6rem;
        flex-direction: column; } }
    .home_story--lf {
      width: calc(100% * 3 / 12); }
      @media screen and (max-width: 1200px) {
        .home_story--lf {
          width: 100%;
          order: 2; } }
      .home_story--lf-wrap {
        width: 100%;
        height: 100%; }
      .home_story--lf-list {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        justify-content: space-between; }
        @media screen and (max-width: 1200px) {
          .home_story--lf-list {
            flex-direction: row;
            column-gap: 1.2rem; } }
        @media screen and (max-width: 360px) {
          .home_story--lf-list {
            flex-direction: column; } }
      .home_story--lf-item:not(:last-child) {
        padding-bottom: 2rem;
        border-bottom: 0.1rem solid #D9D9D9; }
        @media screen and (max-width: 1200px) {
          .home_story--lf-item:not(:last-child) {
            padding-bottom: 0;
            border-bottom: 0; } }
      @media screen and (max-width: 1200px) {
        .home_story--lf-item {
          width: calc(50% - 0.6rem); } }
      @media screen and (max-width: 360px) {
        .home_story--lf-item {
          width: 100%; } }
      .home_story--lf-item .post_link--wrap {
        flex-direction: column;
        row-gap: 1.6rem; }
      .home_story--lf-item .post_link--lf-wrap {
        max-width: none; }
      .home_story--lf-item .post_link--lf {
        width: 100%; }
      .home_story--lf-item .post_link--lf-img {
        padding-top: calc(100% * 177 / 300); }
      .home_story--lf-item .post_link--rt {
        width: 100%; }
        .home_story--lf-item .post_link--rt .title-mn {
          font-size: 2rem;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; }
          @media only screen and (max-width: 1200px) {
            .home_story--lf-item .post_link--rt .title-mn {
              font-size: 1.8rem; } }
          @media only screen and (max-width: 600px) {
            .home_story--lf-item .post_link--rt .title-mn {
              font-size: 1.6rem; } }
    .home_story--ct {
      flex: 1;
      width: 100%; }
      @media screen and (max-width: 1200px) {
        .home_story--ct {
          width: 100%;
          order: 1; } }
      .home_story--ct-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 1.2rem; }
        .home_story--ct-wrap .post_link--wrap {
          flex-direction: column;
          row-gap: 1.6rem; }
        .home_story--ct-wrap .post_link--lf-wrap {
          max-width: none; }
        .home_story--ct-wrap .post_link--lf-img {
          padding-top: calc(100% * 383 / 630); }
        .home_story--ct-wrap .post_link--lf {
          width: 100%;
          max-width: none; }
        .home_story--ct-wrap .post_link--rt {
          width: 100%; }
    .home_story--rt {
      width: calc(100% * 3 / 12); }
      @media screen and (max-width: 1200px) {
        .home_story--rt {
          width: 100%;
          order: 3; } }
      .home_story--rt-wrap {
        width: 100%;
        height: 100%; }
      .home_story--rt-list {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        justify-content: space-between; }
        @media screen and (max-width: 1200px) {
          .home_story--rt-list {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 1.2rem; } }
      @media screen and (max-width: 1200px) {
        .home_story--rt-item.actived {
          order: -1;
          width: 100%; } }
      @media (min-width: 1201px) {
        .home_story--rt-item.actived:hover .home_story--rt-bg img {
          transform: scale(1.1); } }
      .home_story--rt-item.actived .home_story--rt-content {
        padding: 7.8rem 2.4rem 2.4rem 2.4rem; }
        .home_story--rt-item.actived .home_story--rt-content .note-md {
          color: #ffffff;
          font-size: 2rem; }
          @media only screen and (max-width: 1200px) {
            .home_story--rt-item.actived .home_story--rt-content .note-md {
              font-size: 1.8rem; } }
          @media only screen and (max-width: 600px) {
            .home_story--rt-item.actived .home_story--rt-content .note-md {
              font-size: 1.6rem; } }
        @media screen and (max-width: 1200px) {
          .home_story--rt-item.actived .home_story--rt-content {
            padding: 30rem 1.2rem 1.2rem 1.2rem; } }
        @media screen and (max-width: 800px) {
          .home_story--rt-item.actived .home_story--rt-content {
            padding: 25rem 1.2rem 1.2rem 1.2rem; } }
        @media screen and (max-width: 460px) {
          .home_story--rt-item.actived .home_story--rt-content {
            padding: 16rem 1.2rem 1.2rem 1.2rem; } }
        @media screen and (max-width: 460px) {
          .home_story--rt-item.actived .home_story--rt-content {
            padding: 14rem 1.2rem 1.2rem 1.2rem; } }
      .home_story--rt-item.actived .home_story--rt-bg {
        display: block; }
      .home_story--rt-item:not(:last-child) {
        padding-bottom: 1.6rem;
        border-bottom: 0.1rem solid #D9D9D9; }
        @media screen and (max-width: 1200px) {
          .home_story--rt-item:not(:last-child) {
            padding-bottom: 0;
            border-bottom: 0; } }
      @media screen and (max-width: 1200px) {
        .home_story--rt-item {
          width: calc(50% - 0.6rem); } }
      @media screen and (max-width: 360px) {
        .home_story--rt-item {
          width: 100%; } }
      .home_story--rt-content {
        position: relative; }
        .home_story--rt-content .note-md {
          position: relative;
          z-index: 2;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; }
      .home_story--rt-bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: none;
        overflow: hidden; }
        .home_story--rt-bg::before {
          content: "";
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 2; }
        .home_story--rt-bg img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s linear;
          object-position: top; }
      .home_story--rt-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem; }
  .home_all {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem; }
    @media screen and (max-width: 1200px) {
      .home_all {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pd-tl); } }
    @media screen and (max-width: 800px) {
      .home_all {
        row-gap: 1.8rem; } }
    @media screen and (max-width: 600px) {
      .home_all {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pd-tl); } }
    .home_all--title {
      overflow: hidden; }
      .home_all--title-wrap {
        display: flex;
        align-items: center;
        column-gap: 2.4rem;
        justify-content: space-between; }
        @media screen and (max-width: 1200px) {
          .home_all--title-wrap {
            column-gap: 1.2rem; } }
        @media screen and (max-width: 800px) {
          .home_all--title-wrap {
            flex-direction: column;
            row-gap: 2rem; } }
      .home_all--title-logo {
        display: flex;
        align-items: center;
        column-gap: 0.8rem;
        width: 40%;
        flex-shrink: 0; }
        @media screen and (max-width: 800px) {
          .home_all--title-logo {
            width: 100%; } }
        .home_all--title-logo .title-sm {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; }
      .home_all--title-img {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0; }
        .home_all--title-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .home_all--title-list {
        position: relative;
        width: fit-content; }
        @media screen and (max-width: 800px) {
          .home_all--title-list {
            width: 100%; } }
        .home_all--title-list .swiper-slide {
          width: fit-content; }
        .home_all--title-list .swiper-scrollbar {
          margin: 0;
          position: absolute;
          bottom: -1rem;
          left: 50%;
          transform: translateX(-50%);
          width: 95%;
          height: 0.3rem; }
      .home_all--title-item.actived .home_all--title-link .home_all--title-ic img {
        filter: brightness(0) saturate(100%) invert(53%) sepia(82%) saturate(3138%) hue-rotate(240deg) brightness(88%) contrast(91%); }
      .home_all--title-item.actived .home_all--title-link .note-sm {
        color: #8146A3; }
      .home_all--title-ic {
        width: 1.2rem;
        height: 1.2rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .home_all--title-ic img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(612%) hue-rotate(177deg) brightness(86%) contrast(82%); }
      .home_all--title-link {
        display: flex;
        align-items: center;
        column-gap: 0.4rem; }
        .home_all--title-link .note-sm {
          line-height: 100%; }
        @media (min-width: 1201px) {
          .home_all--title-link:hover .home_all--title-ic img {
            filter: brightness(0) saturate(100%) invert(53%) sepia(82%) saturate(3138%) hue-rotate(240deg) brightness(88%) contrast(91%); }
          .home_all--title-link:hover .note-sm {
            color: #8146A3; } }
    @media screen and (max-width: 460px) {
      .home_all--wrap {
        flex-direction: column;
        row-gap: 2rem; } }
    .home_all--lf {
      width: calc(100% * 9 / 12); }
      @media screen and (max-width: 800px) {
        .home_all--lf {
          width: calc(100% * 8 / 12); } }
      @media screen and (max-width: 460px) {
        .home_all--lf {
          width: 100%; } }
      .home_all--lf-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        width: 100%; }
        @media screen and (max-width: 1200px) {
          .home_all--lf-wrap {
            row-gap: 2rem; } }
        @media screen and (max-width: 600px) {
          .home_all--lf-wrap {
            row-gap: 1.4rem; } }
      .home_all--lf-top {
        width: 100%; }
        .home_all--lf-top-wrap {
          width: 100%; }
          .home_all--lf-top-wrap .post_link--lf {
            width: calc(100% * 4.5 / 9); }
            @media screen and (max-width: 800px) {
              .home_all--lf-top-wrap .post_link--lf {
                width: 100%; } }
          @media screen and (max-width: 800px) {
            .home_all--lf-top-wrap .post_link--wrap {
              flex-direction: column;
              row-gap: 1.2rem; } }
          .home_all--lf-top-wrap .post_link--lf-wrap {
            max-width: none; }
          .home_all--lf-top-wrap .post_link--lf-img {
            padding-top: calc(100% * 310 / 470); }
      .home_all--lf-bottom {
        width: 100%; }
        .home_all--lf-bottom-list {
          row-gap: 2.4rem;
          flex-wrap: wrap; }
          @media screen and (max-width: 1200px) {
            .home_all--lf-bottom-list {
              row-gap: 2rem; } }
          @media screen and (max-width: 600px) {
            .home_all--lf-bottom-list {
              row-gap: 1.4rem; } }
        .home_all--lf-bottom-item .post_link--rt-wrap {
          flex-direction: column-reverse;
          row-gap: 0.8rem; }
        @media screen and (max-width: 800px) {
          .home_all--lf-bottom-item .post_link--lf {
            width: 100%; } }
        @media screen and (max-width: 800px) {
          .home_all--lf-bottom-item .post_link--wrap {
            flex-direction: column;
            row-gap: 1.2rem; } }
        .home_all--lf-bottom-item .post_link--rt-wrap .title-mn {
          font-size: 1.6rem;
          -webkit-line-clamp: 3; }
          @media only screen and (max-width: 1200px) {
            .home_all--lf-bottom-item .post_link--rt-wrap .title-mn {
              font-size: 1.4rem; } }
    .home_all--rt {
      width: 100%;
      flex: 1; }
      .home_all--rt-wrap {
        position: sticky;
        top: 2rem; }
        @media screen and (max-width: 800px) {
          .home_all--rt-wrap {
            top: 10rem; } }
    .home_all--video {
      width: 100%; }
      .home_all--video-wrap {
        padding-top: calc(100% * 725 / 1290);
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        border-radius: 0.6rem; }
        @media screen and (max-width: 460px) {
          .home_all--video-wrap {
            padding-top: 100%; } }
        .home_all--video-wrap iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: cover; }
        .home_all--video-wrap img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          object-fit: cover;
          transform: translate(-50%, -50%); }
  .home_nb {
    margin-top: var(--size-pd);
    margin-bottom: var(--size-pd);
    width: 100%;
    overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .home_nb {
        margin-top: var(--size-pd-tl);
        margin-bottom: var(--size-pd-tl); } }
    @media screen and (max-width: 600px) {
      .home_nb {
        margin-top: var(--size-pd-mb);
        margin-bottom: var(--size-pd-mb); } }
    .home_nb--wrap {
      position: relative;
      padding: 2.4rem 4.5rem;
      display: flex;
      flex-direction: column;
      row-gap: 3.2rem;
      width: 100%;
      overflow: hidden; }
      @media screen and (max-width: 1200px) {
        .home_nb--wrap {
          padding: 2rem 2.4rem;
          row-gap: 1.2rem; } }
      @media screen and (max-width: 860px) {
        .home_nb--wrap {
          padding: 2rem 2rem; } }
      @media screen and (max-width: 600px) {
        .home_nb--wrap {
          padding: 2rem 1.8rem; } }
      @media screen and (max-width: 460px) {
        .home_nb--wrap {
          padding: 1.8rem 1.6rem;
          row-gap: 1.2rem; } }
      .home_nb--wrap::before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-image: url(../assets/images/bgnb.png); }
  .home_gt {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    width: 100%; }
    @media screen and (max-width: 1200px) {
      .home_gt {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pd-tl); } }
    @media screen and (max-width: 600px) {
      .home_gt {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pd-mb); } }
    @media screen and (max-width: 460px) {
      .home_gt--wrap {
        flex-direction: column;
        row-gap: 2rem; } }
    .home_gt--lf {
      width: calc(100% * 9 / 12); }
      @media screen and (max-width: 800px) {
        .home_gt--lf {
          width: calc(100% * 8 / 12); } }
      @media screen and (max-width: 460px) {
        .home_gt--lf {
          width: 100%; } }
      .home_gt--lf-wrap {
        width: 100%;
        display: flex;
        column-gap: 2.4rem; }
        @media screen and (max-width: 1400px) {
          .home_gt--lf-wrap {
            column-gap: 2rem; } }
        @media screen and (max-width: 1200px) {
          .home_gt--lf-wrap {
            column-gap: 1.8rem; } }
        @media screen and (max-width: 800px) {
          .home_gt--lf-wrap {
            flex-direction: column;
            row-gap: 1.6rem; } }
        @media screen and (max-width: 600px) {
          .home_gt--lf-wrap {
            column-gap: 1.4rem; } }
      .home_gt--lf-lf {
        width: calc(100% * 5 / 9);
        flex-shrink: 0; }
        @media screen and (max-width: 1200px) {
          .home_gt--lf-lf {
            width: calc(100% * 4.5 / 9); } }
        @media screen and (max-width: 800px) {
          .home_gt--lf-lf {
            width: 100%; } }
        .home_gt--lf-lf-wrap {
          width: 100%; }
          .home_gt--lf-lf-wrap .post_link--wrap {
            flex-direction: column;
            row-gap: 2rem; }
            @media screen and (max-width: 1200px) {
              .home_gt--lf-lf-wrap .post_link--wrap {
                row-gap: 1.4rem; } }
            @media screen and (max-width: 600px) {
              .home_gt--lf-lf-wrap .post_link--wrap {
                row-gap: 1.3rem; } }
          .home_gt--lf-lf-wrap .post_link--lf {
            width: 100%; }
          .home_gt--lf-lf-wrap .post_link--lf-img {
            padding-top: calc(100% * 307 / 520); }
      .home_gt--lf-rt {
        width: 100%;
        flex: 1; }
        .home_gt--lf-rt-list {
          display: flex;
          flex-direction: column;
          row-gap: 2.4rem;
          width: 100%; }
          @media screen and (max-width: 1200px) {
            .home_gt--lf-rt-list {
              row-gap: 1.8rem; } }
          @media screen and (max-width: 860px) {
            .home_gt--lf-rt-list {
              row-gap: 1.6rem; } }
          @media screen and (max-width: 600px) {
            .home_gt--lf-rt-list {
              row-gap: 1.4rem; } }
        .home_gt--lf-rt-item .post_link--wrap {
          flex-direction: row-reverse; }
        .home_gt--lf-rt-item .post_link--rt-wrap .title-mn {
          font-size: 1.6rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; }
          @media only screen and (max-width: 1200px) {
            .home_gt--lf-rt-item .post_link--rt-wrap .title-mn {
              font-size: 1.4rem; } }
        .home_gt--lf-rt-item .post_link--lf {
          width: calc(100% / 3); }
        .home_gt--lf-rt-item .post_link--lf-img {
          padding-top: calc(100% * 98 / 148); }
    .home_gt--rt {
      width: 100%;
      flex: 1; }
      .home_gt--rt-wrap {
        width: 100%;
        position: sticky;
        top: 2rem; }
        @media screen and (max-width: 800px) {
          .home_gt--rt-wrap {
            top: 10rem; } }
  .home_poster {
    margin-top: var(--size-pd);
    margin-bottom: var(--size-pd); }
    @media screen and (max-width: 1200px) {
      .home_poster {
        margin-top: var(--size-pd-tl);
        margin-bottom: var(--size-pd-tl); } }
    @media screen and (max-width: 600px) {
      .home_poster {
        margin-top: var(--size-pd-mb);
        margin-bottom: var(--size-pd-mb); } }
    .home_poster--link {
      width: 100%;
      border-radius: 1.2rem;
      overflow: hidden;
      display: block; }
      @media screen and (max-width: 1200px) {
        .home_poster--link {
          border-radius: 0.6rem; } }
      .home_poster--link img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1.2rem;
        display: block; }
        @media screen and (max-width: 1200px) {
          .home_poster--link img {
            border-radius: 0.6rem; } }
  .home_news {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd); }
    @media screen and (max-width: 1200px) {
      .home_news {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pd-tl); } }
    @media screen and (max-width: 600px) {
      .home_news {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pd-mb); } }
    .home_news--wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 2.4rem; }
      @media screen and (max-width: 1200px) {
        .home_news--wrap {
          row-gap: 1.8rem; } }
      @media screen and (max-width: 860px) {
        .home_news--wrap {
          row-gap: 1.6rem; } }
      @media screen and (max-width: 600px) {
        .home_news--wrap {
          row-gap: 1.4rem; } }
    .home_news--top {
      width: 100%; }
      .home_news--top-wrap {
        display: flex;
        flex-direction: column; }
        .home_news--top-wrap .post_link--lf {
          width: calc(100% * 6 / 12); }
          @media screen and (max-width: 1200px) {
            .home_news--top-wrap .post_link--lf {
              width: 100%; } }
        .home_news--top-wrap .post_link--lf-img {
          padding-top: calc(100% * 383 / 635); }
        .home_news--top-wrap .post_link--ic {
          transform: translateY(0.2rem); }
        @media screen and (max-width: 1200px) {
          .home_news--top-wrap .post_link--wrap {
            flex-direction: column;
            row-gap: 1.6rem; } }
    .home_news--bottom {
      width: 100%; }
      .home_news--bottom-list {
        row-gap: 1.2rem; }
      @media screen and (max-width: 1200px) {
        .home_news--bottom-item {
          width: calc(100% / 2); } }
      .home_news--bottom-item .post_link--wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem; }
      .home_news--bottom-item .post_link--lf {
        width: 100%; }
      .home_news--bottom-item .post_link--rt-wrap .title-mn {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 2rem; }
        @media only screen and (max-width: 1200px) {
          .home_news--bottom-item .post_link--rt-wrap .title-mn {
            font-size: 1.8rem; } }
        @media only screen and (max-width: 600px) {
          .home_news--bottom-item .post_link--rt-wrap .title-mn {
            font-size: 1.6rem; } }
  .home_more {
    padding-top: var(--size-pd);
    width: 100%; }
    @media screen and (max-width: 1200px) {
      .home_more {
        padding-top: var(--size-pd-tl); } }
    @media screen and (max-width: 600px) {
      .home_more {
        padding-top: var(--size-pd-mb); } }
    .home_more--wrap {
      display: flex;
      flex-direction: column;
      row-gap: 2.4rem;
      width: 100%; }
      .home_more--wrap .btn {
        margin: auto; }
    .home_more--list {
      row-gap: 2.4rem; }
      @media screen and (max-width: 1200px) {
        .home_more--list {
          row-gap: 1.8rem; } }
      @media screen and (max-width: 860px) {
        .home_more--list {
          row-gap: 1.6rem; } }
      @media screen and (max-width: 600px) {
        .home_more--list {
          row-gap: 1.4rem; } }
    @media screen and (max-width: 1200px) {
      .home_more--item {
        width: 50%; } }
    @media screen and (max-width: 360px) {
      .home_more--item {
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .home_more--item .post_link--lf {
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .home_more--item .post_link--wrap {
        flex-direction: column;
        row-gap: 1.2rem; } }
    .home_more--item .post_link--rt-wrap .title-mn {
      font-size: 1.8rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; }
      @media only screen and (max-width: 1200px) {
        .home_more--item .post_link--rt-wrap .title-mn {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 800px) {
        .home_more--item .post_link--rt-wrap .title-mn {
          font-size: 1.5rem; } }
      @media only screen and (max-width: 600px) {
        .home_more--item .post_link--rt-wrap .title-mn {
          font-size: 1.4rem; } }
