.home {
    padding-top: var(--size-pdl);
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pdl-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 800px) {
        padding-top: var(--size-pdl-tlv);
        padding-bottom: var(--size-pdl-tlv);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pdl-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    &_top {
        padding-bottom: var(--size-pd);

        @media screen and (max-width: 1200px) {
            padding-bottom: var(--size-pd-tl);
        }

        @media screen and (max-width: 600px) {
            padding-bottom: var(--size-pd-mbs);
        }

        &--list {
            overflow: hidden;
            .swiper-slide {
                height: auto;

                &:nth-child(4n + 1) {
                    .news_post--title {
                        background: $color-green;
                    }
                }

                &:nth-child(4n + 2) {
                    .news_post--title {
                        background: $color-black-4;
                    }
                }

                &:nth-child(4n + 3) {
                    .news_post--title {
                        background: $color-organ;
                    }
                }

                &:nth-child(4n + 4) {
                    .news_post--title {
                        background: $color-purple;
                    }
                }
            }

            .news_post--link {
                height: 100%;
            }
        }
    }

    &_story {
        padding-top: var(--size-pd);
        padding-bottom: var(--size-pd);
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;

        @media screen and (max-width: 1200px) {
            row-gap: 2.4rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.8rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1.4rem;
        }

        .title-main {
            margin-bottom: 0;
        }

        &--wrap {
            @media screen and (max-width: 1200px) {
                row-gap: 1.6rem;
                flex-direction: column;
            }
        }

        &--lf {
            width: calc(100% * 3 / 12);

            @media screen and (max-width: 1200px) {
                width: 100%;
                order: 2;
            }

            &-wrap {
                width: 100%;
                height: 100%;
            }

            &-list {
                display: flex;
                flex-direction: column;
                row-gap: 2rem;
                justify-content: space-between;

                @media screen and (max-width: 1200px) {
                    flex-direction: row;
                    column-gap: 1.2rem;
                }

                @media screen and (max-width: 360px) {
                    flex-direction: column;
                }
            }

            &-item {
                &:not(:last-child) {
                    padding-bottom: 2rem;
                    border-bottom: 0.1rem solid $color-gray2;

                    @media screen and (max-width: 1200px) {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                }

                @media screen and (max-width: 1200px) {
                    width: calc(50% - 0.6rem);
                }

                @media screen and (max-width: 360px) {
                    width: 100%;
                }

                .post_link--wrap {
                    flex-direction: column;
                    row-gap: 1.6rem;
                }

                .post_link--lf-wrap {
                    max-width: none;
                }

                .post_link--lf {
                    width: 100%;
                }

                .post_link--lf-img {
                    padding-top: calc(100% * 177 / 300);
                }

                .post_link--rt {
                    width: 100%;

                    .title-mn {
                        @include fz-20;
                        font-weight: 600;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }

        &--ct {
            flex: 1;
            width: 100%;

            @media screen and (max-width: 1200px) {
                width: 100%;
                order: 1;
            }

            &-wrap {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                row-gap: 1.2rem;
                .post_link--wrap {
                    flex-direction: column;
                    row-gap: 1.6rem;
                }

                .post_link--lf-wrap {
                    max-width: none;
                }

                .post_link--lf-img {
                    padding-top: calc(100% * 383 / 630);
                }

                .post_link--lf {
                    width: 100%;
                    max-width: none;
                }

                .post_link--rt {
                    width: 100%;
                }
            }
        }

        &--rt {
            width: calc(100% * 3 / 12);

            @media screen and (max-width: 1200px) {
                width: 100%;
                order: 3;
            }

            &-wrap {
                width: 100%;
                height: 100%;
            }

            &-list {
                display: flex;
                flex-direction: column;
                row-gap: 1.6rem;
                justify-content: space-between;

                @media screen and (max-width: 1200px) {
                    flex-direction: row;
                    flex-wrap: wrap;
                    column-gap: 1.2rem;
                }
            }

            &-item {
                &.actived {
                    @media screen and (max-width: 1200px) {
                        order: -1;
                        width: 100%;
                    }

                    @include hover {
                        .home_story--rt-bg {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                    .home_story--rt-content {
                        padding: 7.8rem 2.4rem 2.4rem 2.4rem;

                        .note-md {
                            color: $color-white;
                            @include fz-20;
                            // @include hover{
                            //     color: $color-six;
                            // }
                        }

                        @media screen and (max-width: 1200px) {
                            padding: 30rem 1.2rem 1.2rem 1.2rem;
                        }

                        @media screen and (max-width: 800px) {
                            padding: 25rem 1.2rem 1.2rem 1.2rem;
                        }

                        @media screen and (max-width: 460px) {
                            padding: 16rem 1.2rem 1.2rem 1.2rem;
                        }

                        @media screen and (max-width: 460px) {
                            padding: 14rem 1.2rem 1.2rem 1.2rem;
                        }
                    }

                    .home_story--rt-bg {
                        display: block;
                    }
                }

                &:not(:last-child) {
                    padding-bottom: 1.6rem;
                    border-bottom: 0.1rem solid $color-gray2;

                    @media screen and (max-width: 1200px) {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                }

                @media screen and (max-width: 1200px) {
                    width: calc(50% - 0.6rem);
                }

                @media screen and (max-width: 360px) {
                    width: 100%;
                }
            }

            &-content {
                position: relative;

                .note-md {
                    position: relative;
                    z-index: 2;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            &-bg {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                display: none;
                overflow: hidden;
                &::before {
                    content: "";
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color: #000000, $alpha: 0.5);
                    z-index: 2;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s linear;
                    object-position: top;
                }
            }

            &-wrap {
                display: flex;
                flex-direction: column;
                row-gap: 0.8rem;
            }
        }
    }

    &_all {
        padding-top: var(--size-pd);
        padding-bottom: var(--size-pd);
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        @media screen and (max-width: 1200px) {
            padding-top: var(--size-pd-tl);
            padding-bottom: var(--size-pd-tl);
        }

        @media screen and (max-width: 800px) {
            row-gap: 1.8rem;
        }

        @media screen and (max-width: 600px) {
            padding-top: var(--size-pd-mb);
            padding-bottom: var(--size-pd-tl);
        }

        &--title {
            overflow: hidden;
            &-wrap {
                display: flex;
                align-items: center;
                column-gap: 2.4rem;
                justify-content: space-between;

                @media screen and (max-width: 1200px) {
                    column-gap: 1.2rem;
                }

                @media screen and (max-width: 800px) {
                    flex-direction: column;
                    row-gap: 2rem;
                }
            }

            &-logo {
                display: flex;
                align-items: center;
                column-gap: 0.8rem;
                width: 40%;
                flex-shrink: 0;
                @media screen and (max-width: 800px) {
                    width: 100%;
                }

                .title-sm {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
            }

            &-img {
                width: 2.4rem;
                height: 2.4rem;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-list {
                position: relative;
                // flex: 1;
                width: fit-content;

                @media screen and (max-width: 800px) {
                    width: 100%;
                }

                .swiper-slide {
                    width: fit-content;
                }

                .swiper-scrollbar {
                    margin: 0;
                    position: absolute;
                    bottom: -1rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 95%;
                    height: 0.3rem;
                }
            }

            &-item {
                &.actived {
                    .home_all--title-link {
                        .home_all--title-ic {
                            img {
                                filter: brightness(0) saturate(100%) invert(53%) sepia(82%) saturate(3138%)
                                    hue-rotate(240deg) brightness(88%) contrast(91%);
                            }
                        }

                        .note-sm {
                            color: $color-pri;
                        }
                    }
                }
            }

            &-ic {
                width: 1.2rem;
                height: 1.2rem;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(612%) hue-rotate(177deg)
                        brightness(86%) contrast(82%);
                }
            }

            &-link {
                display: flex;
                align-items: center;
                column-gap: 0.4rem;

                .note-sm {
                    line-height: 100%;
                }

                @include hover {
                    .home_all--title-ic {
                        img {
                            filter: brightness(0) saturate(100%) invert(53%) sepia(82%) saturate(3138%)
                                hue-rotate(240deg) brightness(88%) contrast(91%);
                        }
                    }

                    .note-sm {
                        color: $color-pri;
                    }
                }
            }
        }

        &--wrap {
            @media screen and (max-width: 460px) {
                flex-direction: column;
                row-gap: 2rem;
            }
        }

        &--lf {
            width: calc(100% * 9 / 12);

            @media screen and (max-width: 800px) {
                width: calc(100% * 8 / 12);
            }

            @media screen and (max-width: 460px) {
                width: 100%;
            }

            &-wrap {
                display: flex;
                flex-direction: column;
                row-gap: 3rem;
                width: 100%;

                @media screen and (max-width: 1200px) {
                    row-gap: 2rem;
                }

                @media screen and (max-width: 600px) {
                    row-gap: 1.4rem;
                }
            }

            &-top {
                width: 100%;
                &-wrap {
                    width: 100%;
                    .post_link--lf {
                        width: calc(100% * 4.5 / 9);

                        @media screen and (max-width: 800px) {
                            width: 100%;
                        }
                    }

                    .post_link--wrap {
                        @media screen and (max-width: 800px) {
                            flex-direction: column;
                            row-gap: 1.2rem;
                        }
                    }
                    .post_link--lf-wrap {
                        max-width: none;
                    }

                    .post_link--lf-img {
                        padding-top: calc(100% * 310 / 470);
                    }
                }
            }

            &-bottom {
                width: 100%;
                &-list {
                    // width: 100%;
                    row-gap: 2.4rem;
                    flex-wrap: wrap;

                    @media screen and (max-width: 1200px) {
                        row-gap: 2rem;
                    }

                    @media screen and (max-width: 600px) {
                        row-gap: 1.4rem;
                    }
                }

                &-item {
                    .post_link--rt-wrap {
                        flex-direction: column-reverse;
                        row-gap: 0.8rem;
                    }

                    .post_link--lf {
                        @media screen and (max-width: 800px) {
                            width: 100%;
                        }
                    }

                    .post_link--wrap {
                        @media screen and (max-width: 800px) {
                            flex-direction: column;
                            row-gap: 1.2rem;
                        }
                    }

                    .post_link--rt-wrap {
                        .title-mn {
                            @include fz-16;
                            -webkit-line-clamp: 3;
                        }
                    }
                }
            }
        }

        &--rt {
            width: 100%;
            flex: 1;

            &-wrap {
                position: sticky;
                top: 2rem;

                @media screen and (max-width: 800px) {
                    top: 10rem;
                }
            }
        }

        &--video {
            width: 100%;

            &-wrap {
                padding-top: calc(100% * 725 / 1290);
                width: 100%;
                display: block;
                overflow: hidden;
                position: relative;
                border-radius: 0.6rem;

                @media screen and (max-width: 460px) {
                    padding-top: 100%;
                }

                iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    object-fit: cover;
                }

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    object-fit: cover;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &_nb {
        margin-top: var(--size-pd);
        margin-bottom: var(--size-pd);
        width: 100%;
        overflow: hidden;
        @media screen and (max-width: 1200px) {
            margin-top: var(--size-pd-tl);
            margin-bottom: var(--size-pd-tl);
        }

        @media screen and (max-width: 600px) {
            margin-top: var(--size-pd-mb);
            margin-bottom: var(--size-pd-mb);
        }

        &--wrap {
            position: relative;
            padding: 2.4rem 4.5rem;
            display: flex;
            flex-direction: column;
            row-gap: 3.2rem;
            width: 100%;
            overflow: hidden;
            @media screen and (max-width: 1200px) {
                padding: 2rem 2.4rem;
                row-gap: 1.2rem;
            }

            @media screen and (max-width: 860px) {
                padding: 2rem 2rem;
                // row-gap: 1.8rem;
            }

            @media screen and (max-width: 600px) {
                padding: 2rem 1.8rem;
                // row-gap: 1.4rem;
            }

            @media screen and (max-width: 460px) {
                padding: 1.8rem 1.6rem;
                row-gap: 1.2rem;
            }

            &::before {
                content: "";
                position: absolute;
                z-index: -1;
                inset: 0;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                background-image: url(../assets/images/bgnb.png);
            }
        }
    }

    &_gt {
        padding-top: var(--size-pd);
        padding-bottom: var(--size-pd);
        width: 100%;
        @media screen and (max-width: 1200px) {
            padding-top: var(--size-pd-tl);
            padding-bottom: var(--size-pd-tl);
        }

        @media screen and (max-width: 600px) {
            padding-top: var(--size-pd-mb);
            padding-bottom: var(--size-pd-mb);
        }

        .title-main {
        }

        &--wrap {
            @media screen and (max-width: 460px) {
                flex-direction: column;
                row-gap: 2rem;
            }
        }

        &--lf {
            width: calc(100% * 9 / 12);

            @media screen and (max-width: 800px) {
                width: calc(100% * 8 / 12);
            }

            @media screen and (max-width: 460px) {
                width: 100%;
            }

            &-wrap {
                width: 100%;
                display: flex;
                column-gap: 2.4rem;

                @media screen and (max-width: 1400px) {
                    column-gap: 2rem;
                }

                @media screen and (max-width: 1200px) {
                    column-gap: 1.8rem;
                }

                @media screen and (max-width: 800px) {
                    // column-gap: 1.6rem;
                    flex-direction: column;
                    row-gap: 1.6rem;
                }

                @media screen and (max-width: 600px) {
                    column-gap: 1.4rem;
                }
            }

            &-lf {
                width: calc(100% * 5 / 9);
                flex-shrink: 0;

                @media screen and (max-width: 1200px) {
                    width: calc(100% * 4.5 / 9);
                }

                @media screen and (max-width: 800px) {
                    width: 100%;
                }
                &-wrap {
                    width: 100%;
                    .post_link--wrap {
                        flex-direction: column;
                        row-gap: 2rem;

                        @media screen and (max-width: 1200px) {
                            row-gap: 1.4rem;
                        }

                        @media screen and (max-width: 600px) {
                            row-gap: 1.3rem;
                        }
                    }

                    .post_link--lf {
                        width: 100%;
                    }

                    .post_link--lf-img {
                        padding-top: calc(100% * 307 / 520);
                    }
                }
            }

            &-rt {
                width: 100%;
                flex: 1;

                &-list {
                    display: flex;
                    flex-direction: column;
                    row-gap: 2.4rem;
                    width: 100%;

                    @media screen and (max-width: 1200px) {
                        row-gap: 1.8rem;
                    }

                    @media screen and (max-width: 860px) {
                        row-gap: 1.6rem;
                    }

                    @media screen and (max-width: 600px) {
                        row-gap: 1.4rem;
                    }
                }

                &-item {
                    .post_link--wrap {
                        flex-direction: row-reverse;
                    }

                    .post_link--rt-wrap {
                        .title-mn {
                            @include fz-16;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                        }
                    }

                    .post_link--lf {
                        width: calc(100% / 3);
                    }
                    .post_link--lf-img {
                        padding-top: calc(100% * 98 / 148);
                    }
                }
            }
        }

        &--rt {
            width: 100%;
            flex: 1;

            &-wrap {
                width: 100%;

                position: sticky;
                top: 2rem;

                @media screen and (max-width: 800px) {
                    top: 10rem;
                }
            }
        }
    }

    &_poster {
        margin-top: var(--size-pd);
        margin-bottom: var(--size-pd);

        @media screen and (max-width: 1200px) {
            margin-top: var(--size-pd-tl);
            margin-bottom: var(--size-pd-tl);
        }

        @media screen and (max-width: 600px) {
            margin-top: var(--size-pd-mb);
            margin-bottom: var(--size-pd-mb);
        }

        &--link {
            width: 100%;

            border-radius: 1.2rem;
            overflow: hidden;
            display: block;

            @media screen and (max-width:1200px) {
                border-radius:0.6rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 1.2rem;
                display: block;

                @media screen and (max-width:1200px) {
                    border-radius:0.6rem;
                }
    
            }
        }
    }

    &_news {
        padding-top: var(--size-pd);
        padding-bottom: var(--size-pd);

        @media screen and (max-width: 1200px) {
            padding-top: var(--size-pd-tl);
            padding-bottom: var(--size-pd-tl);
        }

        @media screen and (max-width: 600px) {
            padding-top: var(--size-pd-mb);
            padding-bottom: var(--size-pd-mb);
        }

        &--wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;

            @media screen and (max-width: 1200px) {
                row-gap: 1.8rem;
            }

            @media screen and (max-width: 860px) {
                row-gap: 1.6rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 1.4rem;
            }
        }

        &--top {
            width: 100%;
            &-wrap {
                display: flex;
                flex-direction: column;
                .post_link--lf {
                    width: calc(100% * 6 / 12);
                    @media screen and (max-width: 1200px) {
                        width: 100%;
                    }
                }

                .post_link--lf-img {
                    padding-top: calc(100% * 383 / 635);
                }

                .post_link--ic {
                    transform: translateY(0.2rem);
                }

                .post_link--wrap {
                    @media screen and (max-width: 1200px) {
                        flex-direction: column;
                        row-gap: 1.6rem;
                    }
                }
            }
        }

        &--bottom {
            width: 100%;
            &-list {
                row-gap: 1.2rem;
                // width: 100%;
            }

            &-item {
                @media screen and (max-width: 1200px) {
                    width: calc(100% / 2);
                }

                .post_link--wrap {
                    display: flex;
                    flex-direction: column;
                    row-gap: 1.6rem;
                }

                .post_link--lf {
                    width: 100%;
                }

                .post_link--rt-wrap {
                    .title-mn {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        @include fz-20;
                    }
                }
            }
        }
    }

    &_more {
        padding-top: var(--size-pd);
        // padding-bottom: var(--size-pd);
        width: 100%;
        @media screen and (max-width: 1200px) {
            padding-top: var(--size-pd-tl);
        }

        @media screen and (max-width: 600px) {
            padding-top: var(--size-pd-mb);
        }

        &--wrap {
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;
            width: 100%;
            .btn {
                margin: auto;
            }
        }

        &--list {
            row-gap: 2.4rem;

            @media screen and (max-width: 1200px) {
                row-gap: 1.8rem;
            }

            @media screen and (max-width: 860px) {
                row-gap: 1.6rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 1.4rem;
            }
        }

        &--item {
            @media screen and (max-width: 1200px) {
                width: 50%;
            }

            @media screen and (max-width: 360px) {
                width: 100%;
            }

            .post_link--lf {
                @media screen and (max-width: 600px) {
                    width: 100%;
                }
            }

            .post_link--wrap {
                @media screen and (max-width: 600px) {
                    flex-direction: column;
                    row-gap: 1.2rem;
                }
            }

            .post_link--rt-wrap {
                .title-mn {
                    @include fz-18;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                }
            }
        }
    }
}
