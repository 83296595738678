.banner-main {
    height: calc(100vw * 483 / 1620);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 100%, 60% 85%, 0 85%, 0 100%);
    opacity: 0;
    position: relative;
    animation: hinhChay 1s linear forwards 1s;
    @keyframes hinhChay {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }

    @media screen and (max-width: 1200px) {
        margin-bottom: var(--size-pdl-tl);
        height: calc(100vw * 483 / 1200);
        clip-path: none;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: var(--size-pdl-mb);
    }

    &-bg {
        width: 100%;
        height: 100%;
    }

    &-img {
        // position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 140%;
            object-fit: cover;
            object-position: 50% 50%;

            @media screen and (max-width: 1200px) {
                height: 100%;
            }
        }
    }
}

.banner-slide {
    width: 100%;
    height: 100%;
    // min-height: 82rem;
    position: relative;

    &.sp {
        .banner-slide-item {
            height: 100%;
            min-height: 58rem;

            @media screen and (max-width: 1200px) {
                min-height: 40rem;
            }

            @media screen and (max-width: 800px) {
                min-height: 35rem;
            }

            @media screen and (max-width: 600px) {
                min-height: 30rem;
            }
        }

        .banner-slide-content {
            max-width: 64rem;
        }

        .banner-slide-wrap {
            padding-top: 14rem;
            padding-bottom: 14rem;

            @media screen and (max-width: 1200px) {
                padding-top: 12rem;
                padding-bottom: 12rem;
            }

            @media screen and (max-width: 800px) {
                padding-top: 10rem;
                padding-bottom: 10rem;
            }

            @media screen and (max-width: 600px) {
                padding-top: 8rem;
                padding-bottom: 8rem;
            }
        }
    }

    .breadcrumbs {
        position: absolute;
        top: 2.4rem;
        z-index: 2;
        width: 100%;
    }

    .swiper-pagination {
        position: absolute;
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0;

        @media screen and (max-width: 1200px) {
            bottom: 2.5rem;
        }

        @media screen and (max-width: 600px) {
            bottom: 2rem;
        }
    }

    .swiper-slide {
        height: auto;
    }

    .swiper-slide-active {
        .banner-slide-wrap {
            clip-path: inset(0 0 0 0);
        }
    }

    &-item {
        height: 100%;
        min-height: 82rem;

        @media screen and (max-width: 1200px) {
            min-height: 60rem;
        }

        @media screen and (max-width: 800px) {
            min-height: 40rem;
        }

        @media screen and (max-width: 600px) {
            min-height: 30rem;
        }
    }

    &-img {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 59.42%, rgba(0, 0, 0, 0.2) 71.55%),
                linear-gradient(90deg, rgba(0, 0, 0, 0.6) 20.82%, rgba(0, 0, 0, 0) 64.84%);
            z-index: 1;

            @media screen and (max-width: 1200px) {
                background: rgba($color: #000000, $alpha: 0.5);
            }
        }
        img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
        }
    }

    &-wrap {
        padding-top: 19rem;
        padding-bottom: 27.5rem;
        position: relative;
        z-index: 2;
        clip-path: inset(0 100% 0 0);
        transition: all 3s ease;

        @media screen and (max-width: 1200px) {
            padding-top: 15rem;
            padding-bottom: 20rem;
        }

        @media screen and (max-width: 800px) {
            padding-top: 15rem;
            padding-bottom: 15rem;
        }

        @media screen and (max-width: 600px) {
            padding-top: 12rem;
            padding-bottom: 12rem;
        }
    }

    &-content {
        max-width: 54rem;
        width: 100%;
        .title-lg {
            font-family: "Red Rose";
            line-height: 1.2;
            margin-bottom: 1.2rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .note-text {
            color: #f7f5f5;
            margin-bottom: 3rem;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.banner_home {
    overflow: hidden;
    padding-top: var(--size-pdl);
    padding-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pdl-tl);
        padding-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 1024px) {
        padding-top: 2rem;
    }

    @media screen and (max-width: 800px) {
        padding-top: 2rem;
    }

    @media screen and (max-width: 600px) {
        padding-top: 1rem;
        padding-bottom: var(--size-pd-mb);
    }

    &--wrap {
        display: flex;
        flex-direction: column;
        row-gap: 4rem;
        position: relative;

        @media screen and (max-width: 1024px) {
            row-gap: 2rem;
        }

        .swiper-pagination {
            position: absolute;
            top: 10rem;
            left: 8%;
            width: 4rem;
            flex-direction: column;
            align-items: center;
            row-gap: 3rem;
            margin-top: 0;
            @media screen and (max-width: 1600px) {
                left: 4%;
            }
            @media screen and (max-width: 1500px) {
                left: 3%;
            }

            @media screen and (max-width: 1400px) {
                left: 2%;
            }

            @media screen and (max-width: 1350px) {
                left: 1%;
            }

            @media screen and (max-width: 1024px) {
                left: 1%;
                top: 12rem;
            }

            @media screen and (max-width: 600px) {
                position: relative;
                flex-direction: row;
                top: unset;
                column-gap: 2rem;
                padding-top: 1rem;
                padding-left: 1.2rem;
                padding-right: 1.2rem;
                width: fit-content;
                display: none;
            }

            &-bullet {
                width: fit-content;
                background: transparent;
                font-family: "Big Shoulders Display";
                font-weight: 700;
                color: $color-second;
                text-align: center;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.8s ease-in-out;
                &:not(:last-child) {
                    &::before {
                        content: " ";
                        position: absolute;
                        top: 34.5%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0.1rem;
                        height: 0%;
                        background: $color-second;
                        opacity: 0;
                        transition: all 0.5s linear;

                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    &::before {
                        content: " ";
                        position: absolute;
                        bottom: 34.5%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0.1rem;
                        height: 0%;
                        background: $color-second;
                        opacity: 0;
                        transition: all 0.5s linear;

                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                }
            }

            &-bullet-active {
                @include fz-36;
                color: $color-pri;
                padding-bottom: 6rem;

                @media screen and (max-width: 600px) {
                    padding: 0 !important;
                }

                &:last-child {
                    padding-top: 6rem;
                    padding-bottom: 0;
                }

                &::before {
                    height: 100% !important;
                    opacity: 1 !important;
                }
            }
        }
    }

    &--top {
        &-wrap {
            width: calc(100% * 8 / 12);
            position: relative;
            z-index: 5;

            @media screen and (max-width: 1350px) {
                padding-left: 6rem;
            }

            @media screen and (max-width: 1024px) {
                width: 100%;
                padding-left: 5rem;
            }

            @media screen and (max-width: 800px) {
                padding-left: 4rem;
            }

            @media screen and (max-width: 600px) {
                padding-left: 0rem;
            }
        }

        &-list {
            width: 100%;
            height: 100%;

            .swiper {
                padding: 1rem 0;
            }

            .swiper-slide {
                transition: none !important;
                height: auto;
                user-select: none;
            }

            .swiper-slide-active {
                .banner_home--top-item {
                    animation: luotLenBh 1s ease-in-out forwards;

                    .title-lg {
                        animation: luotLenBh 1s ease-in-out forwards;
                    }

                    .note-lg {
                        animation: luotLenBh 0.8s ease-in-out forwards;
                    }

                    .note-text {
                        animation: luotLenBh 0.6s ease-in-out forwards;
                    }

                    .btn {
                        animation: luotLenBh 0.4s ease-in-out forwards;
                    }
                }
            }
        }

        &-item {
            height: 100%;

            @keyframes luotLenBh {
                0% {
                    transform: translateY(30%);
                    opacity: 0;
                }

                0% {
                    transform: translateY(15%);
                }

                100% {
                    transform: translateY(0%);
                    opacity: 1;
                }
            }

            @keyframes truotText {
                0% {
                    clip-path: inset(0 100% 0 0);
                    opacity: 0;
                }

                0% {
                    clip-path: inset(0 50% 0 0);
                    opacity: 0.5;
                }

                100% {
                    clip-path: inset(0 0 0 0);

                    opacity: 1;
                }
            }

            .title-lg {
                margin-bottom: 0.3rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .note-lg {
                margin-bottom: 1.6rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;

                @media screen and (max-width: 1200px) {
                    margin-bottom: 1.4rem;
                }
            }

            .note-text {
                max-width: 40rem;
                display: -webkit-box;
                margin-bottom: 4rem;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

                @media screen and (max-width: 1200px) {
                    margin-bottom: 3rem;
                }

                @media screen and (max-width: 800px) {
                    margin-bottom: 2rem;
                }
            }

            .btn {
            }
        }
    }

    &--bottom {
        position: relative;
        width: 100%;

        &::before {
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: 100% 100%;
            background-image: url(../assets/images/bhbg.png);
            background-repeat: no-repeat;
            position: absolute;
            z-index: -1;
        }

        &-wrap {
            // width: 100%;
            height: 100%;

            @media screen and (max-width: 460px) {
                flex-direction: column;
            }
        }

        &-lf {
            width: calc(3 * 100% / 12);

            @media screen and (max-width: 800px) {
                width: calc(2 * 100% / 12);
            }

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            &-wrap {
                padding-top: 22rem;
                padding-bottom: 7rem;

                @media screen and (max-width: 1200px) {
                    padding-top: 16rem;
                    padding-bottom: 4rem;
                }

                @media screen and (max-width: 1024px) {
                    padding-top: 12rem;
                    padding-bottom: 4rem;
                }

                @media screen and (max-width: 900px) {
                    padding-top: 4rem;
                    padding-bottom: 4rem;
                }

                @media screen and (max-width: 800px) {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }

                @media screen and (max-width: 460px) {
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                }

                .box-navi {
                    justify-content: start;
                }
            }
        }

        &-rt {
            width: 100%;
            flex: 1;

            &-wrap {
                width: 100%;
                height: 100%;
            }

            &-list {
                width: 100%;
                height: 100%;
                .swiper {
                    overflow: visible;
                }

                .swiper-slide {
                    height: auto;
                    // opacity: 0;
                }

                .swiper-slide-active {
                    opacity: 1;
                }
            }

            &-item {
                height: 100%;
                position: relative;
                width: 100%;
            }

            &-box {
                width: calc((100vw - 120rem) / 2 + 100%);
                // height: 100%;
                position: absolute;
                bottom: 0;
                right: calc((100vw - 120rem) / -2 - 6.4rem);

                @media screen and (max-width: 1200px) {
                    right: -6.4rem;
                }

                @media screen and (max-width: 768px) {
                    position: relative;
                    bottom: 0;
                    // right: unset;
                    width: 100%;
                }

                @media screen and (max-width: 460px) {
                    right: -3.2rem;
                }
            }

            &-img {
                position: relative;
                padding-top: calc(100% * 640 / 1361);
                width: 100%;
                overflow: hidden;

                // @media screen and (max-width:460px) {
                //     padding-top: 60%;
                // }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                    object-fit: cover;
                }
            }
        }
    }
}
