.footer {
  padding-top: var(--size-pdl);
  padding-bottom: var(--size-pdl);
  background: $color-white;
  border-top: 0.1rem solid $color-gray2;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    padding-top: var(--size-pd-tl);
    padding-bottom: var(--size-pd-tl);
    padding-left: 10.4rem;
  }

  @media screen and (max-width:800px) {
    padding-left: 0;
  }

  @media screen and (max-width: 600px) {
    padding-top: var(--size-pd-mb);
    padding-bottom: var(--size-pd-mb);
  }

  &-top {
    &-wrap {
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        row-gap: 1.2rem;
      }
    }

    &-lf {
      width: calc(100% * 5 / 12);

      @media screen and (max-width: 1200px) {
        width: 100%;
      }
      &-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        max-width: 42.6rem;

        @media screen and (max-width: 1200px) {
          width: 100%;
          max-width: none;
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        @media screen and (max-width: 1200px) {
          row-gap: 1.6rem;
        }
      }

      &-item {
      }
    }

    &-rt {
      flex: 1;
      width: 100%;
      &-list {
        // @media screen and (max-width:1200px) {
        //   justify-content: space-around;
        // }
      }

      &-item {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        width: 25%;

        @media screen and (max-width: 1200px) {
          width: 50%;
          row-gap: 0.6rem;
        }

        .menu-list {
          display: flex;
          flex-direction: column;
          row-gap: 0.8rem;
          margin: 0;

          @media screen and (max-width: 1200px) {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 1.2rem;
          }

          @media screen and (max-width: 600px) {
            flex-direction: column;
            // flex-wrap: wrap;
            row-gap: 0.6rem;
          }
        }

        .menu-item {
          height: auto;
        }

        .menu-link {
          @include fz-14;
          color: $color-text2;
          border-left: none !important;
          height: auto;
          white-space: wrap;
          @include hover {
            color: $color-five;
          }
        }
      }
    }
  }

  &-logo {
    max-width: 21rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:1200px) {
      max-width: 18rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-bottom {
    margin-top: 2rem;
    padding-top: 2.4rem;
    border-top: 0.1rem solid rgba($color: #fff, $alpha: 0.3);

    @media screen and (max-width:1200px) {
      margin-top: 1rem;
      padding-top: 1.2rem;
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
  }

  &-mona {
    display: flex;
    align-items: center;
    column-gap: 0.6rem;
    justify-content: space-between;
    @media screen and (max-width: 360px) {
      flex-direction: column;
      row-gap: 0.5rem;
    }

    &-img {
      max-width: 18rem;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .note-sm {
    }
  }

  &-mxh {
    margin: 0 auto 3.2rem;
    width: fit-content;

    @media screen and (max-width: 1024px) {
      margin-left: 0;
      margin-bottom: 2rem;
    }

    @media screen and (max-width: 500px) {
      margin: 0 auto 2.4rem;
    }

    &-list {
      display: flex;
      column-gap: 1.6rem;
    }

    &-item {
    }

    &-link {
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      transition: all 0.3s ease;

      @include hover {
        transform: scale(1.3);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
