@import url("../assets/font/reddit-sans-cufonfonts/stylesheet.css");
@import url("../assets/font/Playfair_Display/stylesheet.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: "Reddit Sans Fudge";
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    color: $color-text;
    background-color: #fff;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }

    // @media screen and (max-width: 460px) {
    //     font-size: 14px;
    //   }
    &.actived {
        overflow: hidden !important;
    }
}

a {
    text-decoration: none;
    color: inherit;

    @include transition(0.3s);

    @include min(1200px) {
        &:hover {
            color: $color-six;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: transparent !important;
    color: $color-text !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-text-fill-color: black !important;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;
}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: var(--font-pri);
}

button,
select,
input,
textarea {
    border: none;
    outline: none;
    box-shadow: none;
    font-family: inherit;
    color: black;
    background: transparent;

    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }

    &:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

::-webkit-scrollbar {
    width: 0.3rem;
}

::-webkit-scrollbar-thumb {
    background: $color-pri;
}

::-webkit-scrollbar-track {
    background: white !important;
    border-radius: 0.4rem !important;
}

@supports (-webkit-touch-action: none) {
    input,
    select,
    textarea {
        font-size: max(16px);
    }
}

.main {
    // min-height: 100vh;
    &.pt {
        padding-top: 0rem;

        @media screen and (max-width: 800px) {
            padding-top: 7rem;
        }
    }

    &.pt-2 {
        padding-top: 18rem;

        @media screen and (max-width: 1200px) {
            padding-top: 11rem;
        }

        @media screen and (max-width: 600px) {
            padding-top: 10rem;
        }
    }
}

.wrap {
    display: flex;
    width: 100%;

    &.no-pd {
        .content {
            padding-top: 0;
        }
    }

    &-content {
    }

    &-lf {
        width: 9.6rem;
        flex-shrink: 0;
        transition: width 1s ease;
        @media screen and (max-width: 1400px) {
            width: 7.2rem;
        }
        @media screen and (max-width: 800px) {
            width: 0rem;
        }
        &.actived {
            width: 30rem;

            @media screen and (max-width: 1400px) {
                width: 26rem;
            }

            @media screen and (max-width: 1200px) {
                width: 7.2rem;
            }

            @media screen and (max-width: 800px) {
                width: 0rem;
            }
        }
    }

    &-rt {
        flex: 1;
        width: 100%;
        // height: 500vh;
        // overflow: hidden;
        transition: width 1s ease;
    }

    &-content {
        width: 95%;
        margin: auto;

        @media screen and (max-width: 1400px) {
            width: 100%;
        }

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }
}

.container {
    width: 100%;
    max-width: 132rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;

    // @include max(800px) {
    //     padding: 0 0.8rem;
    // }

    // @include max(600px) {
    //     padding: 0 0.6rem;
    // }
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
}

.t-center {
    text-align: center;
}

.fw-b {
    font-weight: bold;
}

.fsi {
    font-style: italic;
}

.t-up {
    text-transform: uppercase;
}

.tabBtn {
    cursor: pointer;
}

.gItem {
    cursor: pointer;
}

.global-title {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}

.title {
    &-lg {
        @include fz-96;
        line-height: 1.4;

        @media screen and (max-width: 800px) {
            line-height: 1.5;
        }
    }

    &-md {
        @include fz-64;
        line-height: 1.4;

        @media screen and (max-width: 800px) {
            line-height: 1.5;
        }

        strong {
            color: $color-pri;
        }
    }

    &-sm {
        @include fz-32;
    }

    &-sm2 {
        @include fz-36;
    }

    &-sm3 {
        @include fz-40;
        line-height: 1.3;

        @media screen and (max-width: 800px) {
            line-height: 1.5;
        }
    }

    &-sm4 {
        @include fz-48;
    }

    &-mn {
        @include fz-24;
    }

    &-mn2 {
        @include fz-28;
    }
}

.note {
    &-title {
        font-weight: 700;
    }

    &-text {
        @include fz-16;
        strong {
            font-weight: 700;
        }

        @media screen and (max-width: 460px) {
        }
    }

    &-lg {
        @include fz-20;
        font-weight: 600;

        strong {
            color: $color-pri;
        }
    }

    &-md {
        @include fz-18;
    }

    &-sm {
        @include fz-14;
    }

    &-mn {
        @include fz-12;
    }

    &-ic {
        width: 1.2rem;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        i {
            color: $color-pri;
            font-size: 1.4rem;
        }
    }
}

.fl {
    &-Reddit {
        font-family: var(--font-pri);
    }

    &-Play {
        font-family: var(--font-sec);
    }
}

.cl {
    &-pri {
        color: $color-pri;
    }

    &-white {
        color: $color-white;
    }

    &-white2 {
        color: $color-white2;
    }

    &-yellow {
        color: #f5d461;
    }

    &-second {
        color: $color-second;
    }

    &-text {
        color: $color-text;
    }

    &-text2 {
        color: $color-text2;
    }

    &-text3 {
        color: $color-text3;
    }

    &-gray {
        color: $color-gray;
    }

    &-gray2 {
        color: $color-gray2;
    }

    &-gray3 {
        color: $color-gray3;
    }

    &-gray4 {
        color: $color-gray4;
    }

    &-black2 {
        color: $color-black-2;
    }

    &-black3 {
        color: $color-black-3;
    }

    &-black {
        color: $color-black;
    }
}

.link-text {
    color: $color-second;
    @include fz-20;
    font-weight: 600;

    @include hover {
        color: $color-pri;
    }
}

.content {
    padding-top: var(--size-pdl);
    padding-bottom: var(--size-pdl);
    width: 100%;

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pdl-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 860px) {
        padding-top: var(--size-pdl-tlv);
        padding-bottom: var(--size-pdl-tlv);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pdl-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    &-title {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        margin-bottom: 3.2rem;

        @media screen and (max-width: 1400px) {
            row-gap: 1.4rem;
            margin-bottom: 2.4rem;
        }

        @media screen and (max-width: 1200px) {
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 800px) {
            margin-bottom: 2rem;
            row-gap: 0.8rem;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 1.2rem;
            row-gap: 0.8rem;
        }
    }

    &-date {
        &-list {
            display: flex;
            align-items: center;
            column-gap: 2.5rem;
        }

        &-item {
            &:not(:first-child) {
                position: relative;
                &::before {
                    content: "|";
                    position: absolute;
                    color: $color-gray;
                    left: -1.5rem;
                }
            }

            &.actived {
                .note-sm {
                    color: $color-pri;
                    font-weight: 600;
                }
            }
        }
    }

    &-wrap {
        &.cus {
            display: flex;
            flex-direction: column;
            row-gap: 6rem;

            @media screen and (max-width: 1200px) {
                row-gap: 4rem;
            }

            @media screen and (max-width: 860px) {
                row-gap: 3rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 2rem;
            }

            @media screen and (max-width: 460px) {
                row-gap: 1.4rem;
            }
        }

        @media screen and (max-width: 860px) {
            flex-direction: column;
            row-gap: 2rem;
        }

        @media screen and (max-width: 600px) {
            flex-direction: column;
            row-gap: 1.6rem;
        }
    }

    &-top {
        &.cus {
            width: 100%;
            .content-top-lf {
                width: calc(3 * 100% / 12);

                @media screen and (max-width: 1400px) {
                    width: calc(3.5 * 100% / 12);
                }
                @media screen and (max-width: 860px) {
                    width: calc(4 * 100% / 12);
                }

                @media screen and (max-width: 800px) {
                    width: 100%;
                }
            }

            .content-top-wrap {
                @media screen and (max-width: 860px) {
                    flex-direction: row;
                }

                @media screen and (max-width: 800px) {
                    flex-direction: column;
                    row-gap: 1.6rem;
                }

                .news_post--img {
                    @media screen and (max-width: 800px) {
                        padding-top: calc(100% * 600 / 800);
                    }

                    @media screen and (max-width: 460px) {
                        padding-top: 100%;
                    }
                }
            }

            .content-top-rt {
                // width: 100%;
                // flex: 1;
            }

            .content-top-rt-wrap {
                position: relative;
                top: unset;
            }

            .content-top-lf-wrap {
                .news_post--title {
                    min-height: unset;
                }
            }

            .news_item {
                @media screen and (max-width: 800px) {
                    width: calc(100% / 3);
                }

                @media screen and (max-width: 460px) {
                    width: calc(100% / 2);
                }
            }
        }

        &-wrap {
            @media screen and (max-width: 860px) {
                flex-direction: column;
                row-gap: 1.2rem;
            }
        }

        &-lf {
            width: calc(9 * 100% / 12);

            @media screen and (max-width: 1600px) {
                width: calc(100% * 8.5 / 12);
            }

            @media screen and (max-width: 1400px) {
                width: calc(100% * 8.5 / 12);
            }

            @media screen and (max-width: 860px) {
                width: 100%;
            }
            &-wrap {
                height: 100%;
            }
        }

        &-rt {
            width: 100%;
            flex: 1;

            &-wrap {
                // height: 100%;
                position: sticky;
                top: 2rem;
            }
        }
    }

    &-bottom {
        &-wrap {
            @media screen and (max-width: 860px) {
                flex-direction: column;
                row-gap: 4rem;
            }
        }

        &-lf {
            width: calc(9 * 100% / 12);
            @media screen and (max-width: 1600px) {
                width: calc(100% * 8.5 / 12);
            }

            @media screen and (max-width: 1400px) {
                width: calc(100% * 8.5 / 12);
            }

            @media screen and (max-width: 860px) {
                width: 100%;
            }
            &-wrap {
                height: 100%;
            }
        }

        &-rt {
            width: 100%;
            flex: 1;

            &-wrap {
                // height: 100%;
                position: sticky;
                top: 2rem;
            }
        }
    }

    &-lf {
        width: calc(100% * 9 / 12);

        // @media screen and (max-width: 1600px) {
        //     width: calc(100% * 8 / 12);
        // }

        @media screen and (max-width: 1600px) {
            width: calc(100% * 8.5 / 12);
        }

        @media screen and (max-width: 1400px) {
            width: calc(100% * 8 / 12);
        }

        @media screen and (max-width: 860px) {
            width: 100%;
        }
    }

    &-rt {
        flex: 1;
        width: 100%;
        position: relative;

        &-wrap {
            height: 100%;
            width: 100%;
        }

        &-box {
            position: sticky;
            top: 2rem;
        }
    }
}

.box-info {
    display: flex;
    column-gap: 0.8rem;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width:1200px) {
        column-gap: 0.4rem;
    }

    &-author {
        padding-right: 0.8rem;
        border-right: 0.1rem solid $color-gray2;

        @media screen and (max-width:1200px) {
            padding-right: 0.4rem;
        }

        .note-sm {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: nowrap;

            @media screen and (max-width:860px) {
                @include fz-12;
            }
        }
    }

    &-date {
        display: flex;
        column-gap: 0.8rem;
        row-gap: 0.2rem;
        align-items: center;
        // flex-wrap: wrap;
        .note-sm {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            // white-space: nowrap;

            @media screen and (max-width:860px) {
                @include fz-12;
            }
        }
    }
}

.title-main {
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    margin-bottom: 2.4rem;

    @media screen and (max-width: 1200px) {
        margin-bottom: 2rem;
        column-gap: 0.8rem;
    }

    @media screen and (max-width: 800px) {
        margin-bottom: 1.6rem;
        column-gap: 0.6rem;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 1.4rem;
    }

    @media screen and (max-width: 460px) {
        margin-bottom: 1.2rem;
    }
    .title-sm {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &.no-color {
        .title-main-ic {
            img {
                filter: brightness(0) saturate(100%) invert(18%) sepia(16%) saturate(693%) hue-rotate(186deg)
                    brightness(93%) contrast(80%) !important;
            }
        }
    }

    &-ic {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
            width: 2rem;
            height: 2rem;
        }

        @media screen and (max-width: 800px) {
            width: 1.6rem;
            height: 1.6rem;
        }

        @media screen and (max-width: 600px) {
            width: 1.4rem;
            height: 1.4rem;
        }

        @media screen and (max-width: 460px) {
            width: 1.2rem;
            height: 1.2rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.title-main2 {
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    // margin-bottom: 2.6rem;

    @media screen and (max-width: 1200px) {
        column-gap: 0.8rem;
    }

    @media screen and (max-width: 800px) {
        column-gap: 0.6rem;
    }

    .title-sm {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &-ic {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
            width: 2rem;
            height: 2rem;
        }

        @media screen and (max-width: 800px) {
            width: 1.6rem;
            height: 1.6rem;
        }

        @media screen and (max-width: 600px) {
            width: 1.4rem;
            height: 1.4rem;
        }

        @media screen and (max-width: 460px) {
            width: 1.2rem;
            height: 1.2rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.red {
        strong {
            color: $color-red;
        }
    }

    &.blue {
        strong {
            color: $color-blue;
        }
    }

    &.yellow {
        strong {
            color: $color-yellow;
        }
    }

    &.organ {
        strong {
            color: $color-organ;
        }
    }

    &.green {
        strong {
            color: $color-green;
        }
    }

    &.green2 {
        strong {
            color: $color-green2;
        }
    }

    &.red {
        strong {
            color: $color-red;
        }
    }
}

.poster {
    max-width: 30rem;
    width: 100%;

    @media screen and (max-width: 860px) {
        margin: auto;
    }

    &-img {
        padding-top: calc(100% * 600 / 300);
        width: 100%;
        position: relative;
        overflow: hidden;
        display: block;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }
    }
}
