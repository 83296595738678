.popup_main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 70rem;
  background: $color-white2;
  padding: 3.2rem;
  border-radius: 1.2rem;
  z-index: 4000;
  transition: all 0.5s linear;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.showed {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;

    & ~ .popup_main--modal {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
  }

  @media screen and (max-width: 1200px) {
    max-width: 60rem;
  }

  @media screen and (max-width: 800px) {
    max-width: 75%;
    padding: 2.8rem 2.4rem;
  }

  @media screen and (max-width: 600px) {
    max-width: 85%;
    padding: 2.8rem 1.2rem;
  }

  @media screen and (max-width: 460px) {
    max-width: 90%;
  }

  &--exits {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 2rem;
    }

    @media screen and (max-width: 460px) {
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  &--wrap {
    .title-mn2 {
      text-align: center;
      margin-bottom: 3.2rem;

      @media screen and (max-width: 1200px) {
        margin-bottom: 1.2rem;
      }
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    align-items: center;

    @media screen and (max-width: 1200px) {
      row-gap: 2rem;
    }

    @media screen and (max-width: 800px) {
      row-gap: 1.6rem;
    }

    @media screen and (max-width: 600px) {
      row-gap: 1.2rem;
    }

    .note-lg {
      text-align: center;
    }

    .note-text {
      text-align: center;
    }

    .btn {
      width: 100%;
      min-height: 6rem;
      justify-content: unset;
      position: relative;
      padding: 1.6rem;
      @media screen and (max-width: 600px) {
        min-height: 4.8rem;
        padding: 1.2rem;
      }

      @media screen and (max-width: 360px) {
        justify-content: center;
      }

      &-text {
        max-width: 80%;
        font-size: 1.8rem;
        margin: auto;

        @media screen and (max-width: 600px) {
          font-size: 1.6rem;
          max-width: 70%;
        }

        @media screen and (max-width: 360px) {
          max-width: none;
          width: fit-content;
          font-size: 1.4rem;
          margin: unset;
        }
      }

      &-ic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.6rem;
        flex-shrink: 0;
        @media screen and (max-width: 360px) {
          position: static !important;
          transform: unset;
        }

        img {
          @media screen and (max-width: 600px) {
            width: 3.6rem;
            height: 3.6rem;
          }

          @media screen and (max-width: 360px) {
            width: 3rem;
            height: 3rem;
          }
        }
      }
    }
  }

  &--modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 3999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear;
  }
}

.popup-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  max-width: 70rem;
  width: 100%;
  background: $color-white2;
  padding: 3.2rem;
  border-radius: 1.2rem;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.5s linear;

  @media screen and (max-width: 1200px) {
    max-width: 60rem;
  }

  @media screen and (max-width: 800px) {
    max-width: 75%;
    padding: 2.8rem 2.4rem;
  }

  @media screen and (max-width: 600px) {
    max-width: 85%;
    padding: 2.8rem 1.2rem;
  }

  @media screen and (max-width: 460px) {
    max-width: 90%;
  }

  &.showed {
    opacity: 1;
    pointer-events: visible;
    visibility: visible;

    & ~ .popup-form-modal {
      visibility: visible;
      pointer-events: visible;
      opacity: 1;
    }
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    @media screen and (max-width: 1200px) {
      row-gap: 1.6rem;
    }

    @media screen and (max-width: 800px) {
      row-gap: 1.4rem;
    }

    @media screen and (max-width: 600px) {
      row-gap: 1.2rem;
    }
  }

  &-title {
    .title-mn2 {
      text-align: center;

      @media screen and (max-width: 800px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 460px) {
        font-size: 1.8rem;
      }
    }
  }

  &-list {
    display: flex;
    // flex-direction: column;
    row-gap: 2rem;
    margin-bottom: 1.6rem;
    max-height: 60vh;
    overflow-y: auto;

    @media screen and (max-width: 1200px) {
      row-gap: 1.6rem;
    }

    @media screen and (max-width: 800px) {
      row-gap: 1.4rem;
    }

    @media screen and (max-width: 600px) {
      row-gap: 1.2rem;
    }

    @media screen and (max-width: 460px) {
      row-gap: 0.8rem;
    }
  }

  &-item {
    width: calc(100% / 2);

    &:last-child {
      width: 100%;
    }

    @media screen and (max-width: 460px) {
      width: 100% !important;
    }
  }

  &-ex {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 2rem;
    }

    @media screen and (max-width: 460px) {
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  &-box {
    .btn {
      margin: auto;
    }
  }

  &-modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 3999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear;
  }
}

.popup-search {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  max-width: 70rem;
  width: 100%;
  background: $color-white2;
  padding: 3.2rem;
  border-radius: 1.2rem;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.5s linear;

  &.showed {
    opacity: 1;
    pointer-events: visible;
    visibility: visible;

    & ~ .popup-search-modal {
      visibility: visible;
      pointer-events: visible;
      opacity: 1;
    }
  }

  @media screen and (max-width: 1200px) {
    max-width: 60rem;
  }

  @media screen and (max-width: 800px) {
    max-width: 75%;
    padding: 2.8rem 2.4rem;
  }

  @media screen and (max-width: 600px) {
    max-width: 85%;
    padding: 2.8rem 1.2rem;
  }

  @media screen and (max-width: 460px) {
    max-width: 90%;
  }

  &-modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 3999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear;
  }

  &-tabs {
    position: relative;
  }

  &-ex {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 2rem;
    }

    @media screen and (max-width: 460px) {
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    @media screen and (max-width: 1200px) {
      row-gap: 1.6rem;
    }

    @media screen and (max-width: 800px) {
      row-gap: 1.4rem;
    }

    @media screen and (max-width: 600px) {
      row-gap: 1.2rem;
    }
  }

  &-title {
    .title-mn2 {
      text-align: center;

      @media screen and (max-width: 800px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 460px) {
        font-size: 1.8rem;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    @media screen and (max-width: 1200px) {
      row-gap: 2.4rem;
    }

    @media screen and (max-width: 860px) {
      row-gap: 2rem;
    }

    @media screen and (max-width: 600px) {
      row-gap: 1.6rem;
    }

    @media screen and (max-width: 460px) {
      row-gap: 1.2rem;
    }
  }

  &-ip {
    &-wrap {
      display: flex;
      align-items: center;
      column-gap: 1.2rem;
      padding: 1.6rem 2.4rem;
      border-radius: 0.6rem;
      border: 0.1rem solid $color-gray;
      transition: all 0.3s linear;

      @media screen and (max-width: 1200px) {
        padding: 1.2rem 2rem;
        column-gap: 0.8rem;
      }

      @media screen and (max-width: 860px) {
        padding: 1.2rem 1.6rem;
        column-gap: 0.6rem;
      }

      @media screen and (max-width: 600px) {
        padding: 1.2rem 1.2rem;
        column-gap: 0.4rem;
      }
    }

    &-btn {
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;

      @include hover {
        img {
          filter: brightness(0) saturate(100%) invert(32%) sepia(17%) saturate(2381%) hue-rotate(235deg)
            brightness(100%) contrast(89%);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-ip {
      width: 100%;
      flex: 1;
      cursor: pointer;

      input {
        width: 100%;
        height: 100%;
        transform: translateY(0.1rem);
        @include fz-18;

        &::placeholder {
          @include fz-18;
        }
      }
    }

    &-clear {
      width: fit-content;
      flex-shrink: 0;
      cursor: pointer;

      &.showed {
        .note-md {
          opacity: 1;
          visibility: visible;
          pointer-events: visible;
        }
      }

      .note-md {
        transition: all 0.3s linear;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      @include hover {
        .note-md {
          color: $color-pri;
        }
      }
    }
  }

  &-result {
    &-list {
      max-height: 50vh;
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      row-gap: 3.2rem;

      @media screen and (max-width: 1200px) {
        row-gap: 2.4rem;
      }

      @media screen and (max-width: 860px) {
        row-gap: 2rem;
      }

      @media screen and (max-width: 600px) {
        row-gap: 1.6rem;
      }

      @media screen and (max-width: 460px) {
        row-gap: 1.2rem;
      }
    }

    &-item {
      .post_link--rt-wrap .title-mn {
        @include fz-18;
      }

      .post_link--rt-wrap .note-text {
        @include fz-14;
      }

      // .post_link--lf {
      //   width: calc(100% / 3.5);
      // }

      .post_link--lf-img {
        padding-top: calc(100% * 138 / 211);
      }
    }
  }
}
