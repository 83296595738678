.menu {
    &-list {
        display: flex;
        flex-direction: column;
        // margin-left: -0.8rem;
        // margin-right: -0.8rem;
        height: 100%;
        row-gap: 1.2rem;

        @media screen and (max-width: 1400px) {
            row-gap: 0.8rem;
        }

        @media screen and (max-width: 1200px) {
            // margin-left: -1.2rem;
            // margin-right: -1.2rem;
        }
    }

    &-img {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media screen and (max-width: 1400px) {
            width: 2rem;
            height: 2rem;
        }
    }

    &-ic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.8rem;
        width: 2.4rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.actived {
            i {
                transform: rotate(-180deg);
            }
        }

        i {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 1.5;
            color: $color-second;
            transition: all 0.3s linear;
        }
    }

    &-item {
        // padding: 0 1.5rem;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-direction: column;
        row-gap: 1.2rem;
        &.current-menu-item {
            & > .menu-link {
                border-color: $color-four !important;
                color: $color-four !important;
                .note-text {
                    color: $color-four !important;
                }

                .menu-ic {
                    i {
                        color: $color-four !important;
                    }
                }

                &::before {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 1400px) {
            row-gap: 0.8rem;
        }

        &.dropdown {
        }

        & > .menu-list {
            width: 100%;
            height: unset;
            z-index: 2;
            display: none;
            transition: none !important;
            margin: 0;
            .menu-item {
                height: unset;
                &:not(:last-child) {
                    margin-bottom: 1.2rem;
                }
                &.current-menu-item {
                    & > .menu-link {
                        border-color: $color-four !important;
                        color: $color-four !important;
                        .note-text {
                            color: $color-four !important;
                        }

                        .menu-ic {
                            i {
                                color: $color-four !important;
                            }
                        }

                        &::before {
                            width: 100%;
                        }
                    }
                }
            }

            .menu-link {
                width: 100%;
                padding: 0.8rem 0.8rem 0.8rem 4.8rem;
                font-weight: 400;
                // display: -webkit-box;
                // -webkit-line-clamp: 1;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // display: flex;
                // &::before{
                //     display: none;
                // }

                // &::after{
                //     display: none;
                // }

                font-size: 1.5rem;

                @media screen and (max-width: 1400px) {
                    padding: 0.8rem 0.8rem 0.8rem 4.2rem;
                    font-size: 1.4rem;
                }

                @media screen and (max-width: 800px) {
                    padding: 0.8rem 0.8rem 0.8rem 3.3rem;
                    // font-size: 1.4rem;
                }
            }
        }

        @include hover {
            & > .menu-link {
                border-color: $color-four !important;
                color: $color-four !important;
                .note-text {
                    color: $color-four !important;
                }

                .menu-ic {
                    i {
                        color: $color-four !important;
                    }
                }

                &::before {
                    width: 100%;
                }
            }
        }
    }

    &-link {
        column-gap: 1.6rem;

        color: $color-second;
        font-weight: 600;
        display: flex;
        align-items: center;
        // white-space: nowrap;
        position: relative;
        width: fit-content;
        height: 100%;
        width: 100%;
        border-radius: 0.4rem;
        overflow: hidden;
        border-left: 0.2rem solid transparent !important;
        // padding: 0.8rem 4.8rem 0.8rem 0.8rem;
        // transform-origin: left;

        &::before {
            content: "";
            position: absolute;
            bottom: 0%;
            left: 0rem;
            width: 0;
            height: 100%;
            border-radius: 0.4rem;
            background: $color-gray2;
            transition: all 0.3s linear;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0%;
            left: 0rem;
            width: 100%;
            height: 100%;
            border-radius: 0.4rem;
            background: $color-gray2;
            transition: all 0.3s linear;
            z-index: -1;
            opacity: 0;
        }

        .note-text {
            transform: translateY(0.1rem);
        }
    }
}

.menu-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1999;

    &.actived {
        opacity: 1;
        visibility: visible;
    }
}

.social-mb {
    &-list {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;

        column-gap: 1.2rem;
        padding: 0 0.6rem;
    }

    &-item {
        // width: 100%;
        display: flex;
        width: fit-content;
        justify-content: center;

        // &:not(:last-child) {
        //     border-right: 0.1rem solid $color-text;
        // }
    }

    &-link {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        display: block;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

.menu-lh {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .note-title {
        padding: 0 0.6rem;
        font-weight: 700;
    }

    &-title {
        padding: 0 0.6rem;
        @include fz-18;
        font-weight: 700;
        color: $color-pri;
        line-height: 1.5;
        // margin-bottom: 1rem;
        text-transform: uppercase;
    }

    margin-bottom: 1rem;
    padding: 0 0.6rem;
}

.menu-mxh {
    padding: 2rem 0.6rem 1rem;
    margin-bottom: 2rem;
    border-bottom: 0.1rem solid rgba($color: #000000, $alpha: 0.3);

    border-top: 0.1rem solid rgba($color: #000000, $alpha: 0.3);
}

.menu-nav {
    position: sticky;
    top: 0;

    width: 9.6rem;
    // max-width: 30rem;
    left: 0;
    height: 100vh;
    background: $color-white;
    z-index: 1000;
    box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);
    padding: 0 2.4rem;
    // overflow: hidden;

    // justify-content: space-between;
    padding-bottom: 1.2rem;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

    @media screen and (max-width: 1400px) {
        padding: 0 1.2rem;
        width: 7.2rem;
    }

    @media screen and (max-width: 1200px) {
        position: fixed;
    }

    @media screen and (max-width: 800px) {
        left: -100%;
        pointer-events: none;
        visibility: hidden;
        // opacity: 0;
        transition: all 0.5s linear;
        height: 100%;
    }

    &-overflow {
        width: 100%;
        height: calc(100% - 1.2rem);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @keyframes anHienSl {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }

    @include hover {
        width: 30rem;

        @media screen and (max-width: 1400px) {
            width: 26rem;
        }

        .menu-nav-top {
            .title-sm {
                display: block;
                animation: anHienSl 1s linear forwards;
            }
        }

        .menu-nav-center {
            .menu-link {
                .note-text {
                    display: -webkit-box;
                    animation: anHienSl 1s linear forwards;
                }

                .menu-ic {
                    display: flex;
                    animation: anHienSl 1s linear forwards;
                }
            }
        }

        .menu-nav-sign-info {
            .note-text {
                display: block;
                animation: anHienSl 1s linear forwards;
            }
        }

        .menu-nav-sign {
            .menu-img {
                display: none;
            }

            &-btn {
                display: flex;
                animation: anHienSl 1s linear 0.2s forwards;
            }
        }

        .menu-nav-bottom {
            .menu-img {
                display: none;
            }

            &-wrap {
                display: flex;
                animation: anHienSl 1s linear 0.2s forwards;
            }
        }
    }

    &.actived {
        width: 30rem;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        @media screen and (max-width: 800px) {
            pointer-events: visible;
            visibility: visible;
            left: 0;
            width: 45%;
            transition: all 0.5s linear;
        }

        @media screen and (max-width: 600px) {
            width: 60%;
        }

        @media screen and (max-width: 460px) {
            width: 80%;
        }

        @media screen and (max-width: 360px) {
            width: 90%;
        }

        & ~ .menu-nav-modal {
            opacity: 1;
            visibility: visible;
            pointer-events: visible;
        }

        .menu-nav-top {
            .title-sm {
                display: block;
                animation: anHienSl 1s linear forwards;
            }
        }

        .menu-nav-center {
            .menu-link {
                .note-text {
                    display: -webkit-box;
                    animation: anHienSl 1s linear forwards;
                }

                .menu-ic {
                    display: flex;
                    animation: anHienSl 1s linear forwards;
                }
            }
        }

        .menu-nav-sign-info {
            .note-text {
                display: block;
                animation: anHienSl 1s linear forwards;
            }
        }

        .menu-nav-sign {
            .menu-img {
                display: none;
            }

            &-btn {
                display: flex;
                animation: anHienSl 1s linear 0.2s forwards;
            }
        }

        .menu-nav-bottom {
            .menu-img {
                display: none;
            }

            &-wrap {
                display: flex;
                animation: anHienSl 1s linear 0.2s forwards;
            }
        }
    }

    &-modal {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.3s linear;
    }

    &-control {
        display: none;
        position: absolute;
        top: 50%;
        right: -2.8rem;
        transform: translateY(-50%);
        z-index: 5;
        @media screen and (max-width: 1200px) {
            display: block;
        }
        &-btn {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-pri;

            z-index: 5;
            cursor: pointer;
            transition: all 0.3s linear;
            i {
                font-size: 1.6rem;
                color: $color-white;
            }

            &.actived {
                transform: rotate(-180deg);
            }

            @media screen and (max-width: 800px) {
                transform: rotate(-180deg) !important;
            }
        }
    }

    &-top {
        display: flex;
        align-items: center;
        column-gap: 1.6rem;
        width: 100%;
        position: relative;
        // justify-content: center;
        transition: all 0.3s linear;
        height: 8.4rem;
        flex-shrink: 0;
        overflow: hidden;

        @media screen and (max-width: 600px) {
            height: 6rem;
        }

        @media screen and (max-width: 1400px) {
            column-gap: 0.8rem;
        }

        .title-sm {
            transition: all 0.3s linear;
            line-height: 100%;
            display: none;
            opacity: 0;

            @media screen and (max-width: 1400px) {
                font-size: 3rem;
            }
        }

        @include hover {
            .menu-nav-logo {
                &::before {
                    left: 180%;
                    opacity: 1;
                    transition:
                        left 0.5s linear,
                        top 0.5s linear,
                        opacity 0.3s ease;
                }

                img {
                    transform: scale(1.2);
                }
            }

            .title-sm {
                color: $color-second;
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0.1rem;
            background: rgba($color: #000000, $alpha: 0.1);
            bottom: 0;
            left: 0rem;
        }
    }

    &-logo {
        display: flex;
        width: 4.8rem;
        height: 4.8rem;
        // width: 100%;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        overflow: hidden;
        // margin: auto;
        position: relative;
        transition: all 0.3s linear;

        @media screen and (max-width: 600px) {
            width: 4rem;
            height: 4rem;
        }

        &::before {
            content: "";
            top: 50%;
            left: -120%;
            position: absolute;
            transform: translate(-50%, -50%) rotate(-55deg);
            width: 250%;
            height: 50%;
            background: rgba($color: #fff, $alpha: 0.2);
            z-index: 1;
            opacity: 0.3;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease;
        }
    }

    &-lh {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;

        @media screen and (max-width:1200px) {
            row-gap:1rem;
        }

        @media screen and (max-width:860px) {
            row-gap:0.8rem;
        }

        .btn {
            width: 100%;
        }
    }

    &-center {
        position: relative;
        max-height: 40vh;
        overflow-y: auto;
        overflow-x: hidden;
        left: 0;
        // height: 100%;
        flex-shrink: 0;
        width: 100%;

        @media screen and (max-width: 1600px) {
            max-height: 36vh;
        }

        @media screen and (max-width: 1200px) {
            max-height: 45vh;
        }

        @media screen and (max-width: 600px) {
            max-height: 40vh;
        }

        @media screen and (max-width: 460px) {
            max-height: 40vh;
        }

        .menu-list {
            height: 100%;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .menu-item {
            height: unset;
            justify-content: unset;

            align-items: unset;
        }

        .menu-link {
            height: unset;
            flex-shrink: 0;
            width: 100%;
            min-height: 4.2rem;
            padding-left: 1rem;
            align-items: center;
            border-left: 0;
            position: relative;
            left: 0;
            transition: all 0.3s ease 0.1s;

            @media screen and (max-width: 1400px) {
                column-gap: 0.8rem;
            }
            @media screen and (max-width: 1200px) {
                min-height: 3.8rem;
                padding-left: 1.2rem;
            }

            @media screen and (max-width: 800px) {
                min-height: 3.8rem;
                padding-left: 0.5rem;
            }

            .menu-ic {
                display: none;
            }

            .menu-img {
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);

                transition: all 0.3s linear;
            }

            .note-text {
                // line-height: 100%;
                width: 100%;
                display: none;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                opacity: 0;

                @media screen and (max-width: 1400px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
    &-bottom {
        padding: 1.6rem 0;
        position: relative;
        flex-shrink: 0;
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0.1rem;
            background: rgba($color: #000000, $alpha: 0.1);
            top: 0;
            left: 0;
        }
        &-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 2rem;
            width: 100%;
            display: none;
            opacity: 0;

            @media screen and (max-width: 1200px) {
                row-gap: 1.6rem;
            }

            @media screen and (max-width: 860px) {
                row-gap: 1.4rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 1.2rem;
            }

            @media screen and (max-width: 460px) {
                row-gap: 1rem;
            }
        }

        .menu-img {
            // margin: auto;
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 2.4rem;
                height: 2.4rem;

                @media screen and (max-width: 1400px) {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }

    &-social {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;

        
        @media screen and (max-width:1200px) {
            row-gap:1rem;
        }

        @media screen and (max-width:860px) {
            row-gap:0.8rem;
        }
    }

    &-sign {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1.2rem;
        width: 100%;
      
        

        // position: absolute;
        // bottom: 1.2rem;
        // left: 50%;
        // transform: translateX(-50%);
        // width: calc(100% - 4.8rem);

        // @media screen and (max-width: 1460px) {
        //     width: calc(100% - 2.4rem);
        // }

        .menu-img {
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 2.4rem;
                height: 2.4rem;

                @media screen and (max-width: 1400px) {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }

        &-btn {
            width: 100%;
            display: none;
            opacity: 0;
            .btn {
                width: 100%;

                &.showed {
                    display: flex !important;
                }

                &.hiddend {
                    display: none !important;
                }

                &.trans {
                }

                &.red {
                    display: none;
                }
            }
        }

        &-img {
            width: 4.8rem;
            height: 4.8rem;
            flex-shrink: 0;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s linear;

            @media screen and (max-width: 1200px) {
                width: 4rem;
                height: 4rem;
            }

            @media screen and (max-width: 800px) {
                width: 3.2rem;
                height: 3.2rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-info {
            display: flex;
            align-items: center;
            column-gap: 1.6rem;
            position: relative;
            flex-shrink: 0;
            // justify-content: center;
            height: 4.8rem;
            width: 100%;
            display: none;
           
            @media screen and (max-width: 1200px) {
                height: 4rem;
                column-gap: 1.4rem;
            }

            @media screen and (max-width: 800px) {
                height: 3.2rem;
                column-gap: 1.2rem;
            }

            @media screen and (max-width: 600px) {
                column-gap: 1rem;
            }

            @media screen and (max-width: 460px) {
                column-gap: 0.8rem;
            }

            &.showed {
                display: flex;
            }

            .note-text {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: none;
                width: 100%;
                transition: all 0.3s linear;
                opacity: 0;
                white-space: nowrap;
            }
        }
    }

    &-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        width: 100%;
        flex-shrink: 0;
        align-items: center;

        @media screen and (max-width: 1200px) {
            row-gap: 1.4rem;
        }

        @media screen and (max-width: 860px) {
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1rem;
        }
    }
}

.social {
    &-list {
        display: flex;
        gap: 0.8rem;

        @media screen and (max-width: 800px) {
            flex-wrap: wrap;
        }
    }

    &-item {
    }

    &-link {
        display: flex;
        width: 3.8rem;
        height: 3.8rem;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media screen and (max-width: 1200px) {
            width: 3.2rem;
            height: 3.2rem;
        }

        @include hover {
            box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.5);
            .social-img {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }

    &-img {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s linear;
        }
    }
}
