:root {
    --size-hd: 10rem;
    --size-pdl: 6rem;
    --size-pdl-tl: 4rem;
    --size-pdl-tlv: 3rem;
    --size-pdl-mb: 2rem;
    --size-pd: 1.6rem;
    --size-pd-tl: 1.2rem;
    --size-pd-mb: 0.8rem;
    --font-pri: "Reddit Sans Fudge";
    --font-sec: "'Playfair Display', serif";

}

// dit me m nho chua
$color-pri: #8146A3;
$color-second: #393D48;
$color-third: #D22522;

$color-four: #3C214C;
$color-five: #4D2A62;
$color-six: #9E1C1A;

$color-text: #393d48;
$color-text2: #5f626b;
$color-text3: #222;

$color-black-2: #444;
$color-black-3: #696061;
$color-black: #000000;

$color-white: #ffffff;
$color-white2: #fefefe;
$color-gray: #999;
$color-gray2: #D9D9D9;
$color-gray3: #94a3b8;
$color-gray4: #C7C9D9;

$color-blue: #1d4ed8;
$color-green: #047857;
$color-green2: #0FB93C;
$color-black-4: #2f343f; 
$color-organ: #d97706;
$color-purple: #673882;
$color-red:#ED1D24;
$color-yellow:#F7CE46;


//Responsive
$res-mobile: 1200px;
$res-mobile-min: 1201px;
$mobile: 460px;
